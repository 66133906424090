import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AS4QueryParams } from '../_model/as4-query-params';
import { OotsService } from '../_service/oots.service';
import { AS4Field } from '../_model/as4field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import xmlFormat from 'xml-formatter';

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, CommonModule],
})

export class RegistryComponent implements AfterViewInit {
  displayedColumns: string[] = ['file', 'type', 'date', 'requestId', 'conversationId', 'partyFrom', 'partyTo'];
  dataSource: MatTableDataSource<AS4Field>;
  myDate: Date = new Date();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  params: AS4QueryParams = new AS4QueryParams();
  fields: AS4Field[];

  constructor(
    private ootsService: OotsService,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.search();
  }

  search() {
    this.ootsService.as4Search(this.params).subscribe(
      {
        next: result => {
          this.fields = result;
          if (this.fields != null) {
            this.dataSource = new MatTableDataSource(this.fields);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadXML(id: string, type: string) {
    this.ootsService.as4Download(id, type).subscribe(
      {
        next: (response) => {
          this.downloadXmlFile(response.xml, id + "_" + type);
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  downloadXmlFile(xmlString: string, fileName: string) {

  const formattedXmlString = xmlFormat(xmlString, { indentation: '  ', collapseContent: true }); // Opcional: ajusta la indentación

    const blob = new Blob([formattedXmlString], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName + '.xml';
    a.click();
    window.URL.revokeObjectURL(url);
  }

}
