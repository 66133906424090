<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>OOTS Intermediary App</title>
</head>

<body style="height: 100%;">

  <!-- Barra de navegación -->
  <header class="header" style="background-color: white;">
    <div class="container">
      <div class="logo-min">

        <table style="width: 100%; border:0px solid black;">
          <tr>
            <div class="right-align" style="border-width: 0px;">
              <select style="border-width: 0px;" [(ngModel)]="selectedLanguage" #mySelect
                (change)='translateLanguageTo(mySelect.value)'>
                <option value="en">English</option>
                <option value="es">Español</option>
              </select>
            </div>
          </tr>
          <tr>
            <div class="right-align"
              style="border-width: 0px; width: 100%; background-color:#FAFAFB; padding: 5px 350px 5px 0px;">
              <img height="60" alt="" src="assets/logo_min.jpg" />
              <img height="60" style="margin-left: 10px;" alt="" src="assets/your_europe.png" />
              <span style="margin-left:2px; font-size:1.25em;">{{'HOME.GATEWAY' | translate}}</span>
            </div>
          </tr>
        </table>

        
      </div>
    </div>
  </header>
  
  <main>

    <!-- Breadcrum with timer and buttons -->
    <app-header></app-header>

    <!-- Contenido principal -->
    <section class="content">
      <div class="container" *ngIf="innerHtml === ''">
        <router-outlet></router-outlet>
      </div>
      <div *ngIf="innerHtml !== ''" class="container">
        <div class="d-flex justify-content-end">
          <button (click)="innerHtml=''">X</button>
        </div>
        <div [innerHtml]="innerHtml">
        </div>
      </div>
    </section>
  </main>

  <!-- Pie de página -->
  <footer class="footer" style="background-color: rgba(247, 247, 248, 1);">
    <div class="container" style="max-width: 100%; padding: 0px; text-align: center;">
      <div style="background-color:  rgba(17, 77, 166, 1); padding: 1rem 1rem; ">
        <span style="padding: 24px 16px; margin-right: 70px;">
          <a tabindex="0" (click)="openComponent('/aviso_legal')" href="javascript:void(0);" style="color: white"><u>{{'FOOTER.LEGAL_WARNING' | translate}}</u></a>
        </span>
        <span style="padding: 24px 16px; margin-right: 70px;">
          <a tabindex="0" (click)="openComponent('/politica_privacidad')" href="javascript:void(0);" style="color: white"><u>{{'FOOTER.PRIVACY_POLICY' | translate}}</u></a>
        </span>
        <span style="padding: 24px 16px; margin-right: 70px;">
          <a tabindex="0" (click)="openComponent('/politica_cookies')" href="javascript:void(0);" style="color: white"><u>{{'FOOTER.COOKIES_POLICY' | translate}}</u></a>
        </span>
      </div>

      <img src="assets/europa.svg" alt="" style="padding: 24px 16px; margin-right: 70px;">
      <img src="assets/plan_recuperacion.svg" alt="" style="padding: 24px 16px; margin-right: 70px;">
      <img src="assets/espana_digital.svg" alt="" style="padding: 24px 16px; margin-right: 70px;">
    </div>

  </footer>

  <button id="open-server-session-timeout-dialog" (click)="openServerSessionTimeoutDialog()"
    style="display: none;">server-session-timeout</button>
  <button id="open-client-session-timeout-dialog" (click)="openClientSessionTimeoutDialog()"
    style="display: none;">client-session-timeout</button>

  <!-- Incluir los enlaces a los archivos JavaScript de Bootstrap -->
  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js"></script>
  <!-- Incluir los enlaces a los archivos CSS de Bootstrap -->
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
</body>

</html>