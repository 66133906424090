<div class="municipality-container">
    <div><label for="person-form">1. {{ 'PREVIEW_SPACE.PERSONAL_DATA' | translate }}</label></div>
    <form id="person-form" [formGroup]="personForm">
        <div class="form-container">
            <div class="form-field">
                <label for="identifier">{{ 'PREVIEW_SPACE.IDENTIFIER' | translate }}</label>
                <input id="identifier" type="text" formControlName="identifier">
            </div>
            <div class="form-field">
                <label for="nombre">{{ 'PREVIEW_SPACE.NAME' | translate }}</label>
                <input id="nombre" type="text" formControlName="nombre">
            </div>
            <div class="form-field">
                <label for="apellido1">{{ 'PREVIEW_SPACE.SURNAME1' | translate }}</label>
                <input id="apellido1" type="text" formControlName="apellido1">
            </div>
            <div class="form-field">
                <label for="apellido2">{{ 'PREVIEW_SPACE.SURNAME2' | translate }}</label>
                <input id="apellido2" type="text" formControlName="apellido2">
            </div>
            <div class="form-field">
                <label for="birthDate">{{ 'PREVIEW_SPACE.REGISTRY_DATE_SELECT' | translate }}</label>
                <input id="birthDate" type="date" data-date-format="YYYY-MM-DD" formControlName="birthDate">
            </div>
        </div>
    </form>
</div>

<div class="municipality-container">
    <div><label for="municipality">2. {{ 'PREVIEW_SPACE.MUNICIPALITY_SELECT' | translate }}</label></div>
    <div><app-cpm [comunidad]=true [provincia]=true [municipio]=true></app-cpm></div>
</div>