import { Component, Input, OnInit, QueryList, Type, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { StateService } from '../_service/state.service';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { EidasUser } from '../_model/eidasUser';
import { Utils } from '../_common/utils';
import { MultiLanguageStringPipe } from '../_model/multi-language-string';
import { HttpClient } from '@angular/common/http';
import { QueryRequestData, QueryRequestDataDto } from '../_model/queryRequestData';
import { AccessPointService } from '../_service/access-point.service';
import { BreadcrumbService } from '../_service/breadcrumb.service';
import { LoaderButtonComponent } from '../_common/loader-button/loader-button.component';
import { COUNTRIES, CountryAsset } from '../contants';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogService } from '../_service/modal-dialog.service';
import { ModalDialogComponent, ModalResult } from '../_common/modal-dialog/modal-dialog.component';
import { PreviewService } from '../_service/preview.service';
import { SpecificDataComponent } from '../_common/specific-data.component';
import { PidRequest } from '../_model/pid-request';
import { PreviewSpaceResponse, ResponseErrorType } from '../_model/preview-space-response';
import { SessionTimerComponent } from '../_common/session-timer/session-timer.component';
import { Subscription } from 'rxjs';
import { SessionTimerService } from '../_service/session-timer.service';
import { environment } from 'src/environments/environment';
import { NacimientoFormComponent } from '../_common/forms/nacimiento-form/nacimiento-form.component';
import { ResidenciaFormComponent } from '../_common/forms/residencia-form/residencia-form.component';
import { DiscapacidadFormComponent } from '../_common/forms/discapacidad-form/discapacidad-form.component';
import { MatrimonioFormComponent } from '../_common/forms/matrimonio-form/matrimonio-form.component';
import { ConvivenciaFormComponent } from '../_common/forms/convivencia-form/convivencia-form.component';
import { DisolucionMatrimonioFormComponent } from '../_common/forms/disolucion-matrimonio-form/disolucion-matrimonio-form.component';
import { OOTSRequirements } from '../_model/oots-requirements';
import { DefuncionFormComponent } from '../_common/forms/defuncion-form/defuncion-form.component';
import { EnrolmentFormComponent } from '../_common/forms/enrolment-form/enrolment-form.component';
import { DeportistaAltoNivelFormComponent } from '../_common/deportista-alto-nivel-form/deportista-alto-nivel-form.component';

declare const require: any;
const i18nIsoCountries = require("i18n-iso-countries");
@Component({
  selector: 'app-locate-evidence-preview',
  templateUrl: './locate-evidence-preview.component.html',
  styleUrls: ['./locate-evidence-preview.component.css']
})
export class LocateEvidencePreviewComponent implements OnInit {
  @Input() requestInfo: QueryRequestDataDto;
  @Input() session: string;
  @Input() returnUrl: string;

  @ViewChild(LoaderButtonComponent) sendButton: LoaderButtonComponent;
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChildren(SpecificDataComponent) specificDataComponents!: QueryList<SpecificDataComponent>;

  private eventsSubscription = new Subscription();
  dynamicComponents: SpecificDataComponent[] = [];
  isUserLogged: boolean;
  currentUser: EidasUser;
  continuationToken: string;
  requirement: OOTSRequirements;
  countryAsset: CountryAsset;
  document: string;
  step: number;
  pidRequest: PidRequest;
  specificDataNeeded = false;
  redirecting = false;
  previewSending = false;

  selectedCommunity: any = "null";
  communitySelect = false;

  regex = /^[A-Za-z]{2}\/[A-Za-z]{2}\//;

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private jwtUtilService: JwtUtilsService,
    private multiLanguagePipe: MultiLanguageStringPipe,
    private apService: AccessPointService,
    private breadcrumbService: BreadcrumbService,
    public translate: TranslateService,
    private dialogService: ModalDialogService,
    private previewService: PreviewService,
    private sessionTimer: SessionTimerComponent,
    private sessionTimerService: SessionTimerService
  ) { }

  ngOnInit(): void {
    console.log(this.requestInfo)
    this.stateService.ootsPreview = true;
    if (this.jwtUtilService.isLogged()) {
      this.isUserLogged = true;
      this.currentUser = this.jwtUtilService.getCurrentUser();
      console.log(this.currentUser.personIdentifier.split(this.regex))
      // check for a continuation token
      if (Utils.hasValue(this.stateService.continuationToken)) {
        this.continuationToken = this.stateService.continuationToken;
        this.stateService.clearContinuationToken();
      }
      this.getPIDRequirement();
      this.countryAsset = COUNTRIES[this.requestInfo.country.value] ?? COUNTRIES['EU'];
      this.eventsSubscription.add(
        this.breadcrumbService.getStep.subscribe(step => this.step = step));
      this.configureClientSessionTimer();
    }
  }

  configureClientSessionTimer() : void {
    this.sessionTimer.initSimpleTimer(environment.preview_space_session);
    this.eventsSubscription.add(
      this.dialogService.dialogObserver.subscribe((result: ModalResult) => {
          if(result.action === 'accept') window.location.href = this.returnUrl;
      }));
      // listener for session timeout
      this.sessionTimerService.isTimerExpired$.subscribe((sessionData) => {
        if(sessionData.expired && sessionData.timerType === "client")
          {
            if(!this.previewSending) this.sendTimeoutError();
          }
      });
  }

  allComponentsValid(): boolean {
    if (this.dynamicComponents.length > 0) {
      return this.dynamicComponents.every(component => component.isValid());
    } else return true;
  }

  loadComponent(component: Type<SpecificDataComponent>): void {
    const componentRef = this.container.createComponent(component);
    this.dynamicComponents.push(componentRef.instance);
    componentRef.instance.valueChanged.subscribe(value => {
      console.log('Valor seleccionado:', value);
    });
  }

  showSpecificDataComponents() {
      switch(this.requirement){
        case OOTSRequirements.PROOF_OF_BIRTH:
          this.loadComponent(NacimientoFormComponent);
          break;

        case OOTSRequirements.PROOF_OF_RESIDENCE_DOMICILE:
        case OOTSRequirements.PROOF_OF_RESIDENCE_ALL_ADDRESSES:
          this.loadComponent(ResidenciaFormComponent);
          break;

        case OOTSRequirements.PROOF_OF_DISABILITY:
          this.loadComponent(DiscapacidadFormComponent);
          break;
        
        case OOTSRequirements.PROOF_OF_DISABILITY_DEGREE_50:
          this.loadComponent(DiscapacidadFormComponent);
          break;

        case OOTSRequirements.PROOF_OF_MARRIAGE:
          this.loadComponent(MatrimonioFormComponent);
          break;
        
        case OOTSRequirements.PROOF_OF_HOUSEHOLD_COMPOSITION:
          this.loadComponent(ConvivenciaFormComponent);
          break;

        case OOTSRequirements.PROOF_OF_DISSOLUTION_OF_MARRIAGE:
        case OOTSRequirements.PROOF_OF_MARITAL_STATUS:
          this.loadComponent(DisolucionMatrimonioFormComponent);
          break;

        case OOTSRequirements.PROOF_OF_DEATH_PARENT:
        case OOTSRequirements.PROOF_OF_DEATH_CHILD:
        case OOTSRequirements.PROOF_OF_DEATH_SIBLING:
            this.loadComponent(DefuncionFormComponent);
            break;
        case OOTSRequirements.PROOF_ENROLMENT_TERTIARY_EDUCATION_INSTUTION:
          this.loadComponent(EnrolmentFormComponent);
          break;
        case OOTSRequirements.PROOF_OF_HIGH_PERFORMANCE_ATHLETE:
          this.loadComponent(DeportistaAltoNivelFormComponent);
          break;
      }

    this.specificDataNeeded = this.dynamicComponents.length > 0;
  }

  //Gets requirement informatión for request to PID 
  getPIDRequirement() {
    return this.previewService.getRequirement(this.requestInfo.listRequirementIdentifiers[0]).subscribe(
      response => {
        this.requirement = response;
        this.showSpecificDataComponents();
      }
    );
  }

  private extractUserIdentifierFromEidasUser(ediasUser: string): string {
    const lastIndex = ediasUser.lastIndexOf('/');
    if (lastIndex !== -1) return ediasUser.substring(lastIndex + 1);
    else return ediasUser;
  }

  transformTexts(collection: any[], lang: string) {
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json"));
    const translatedStr = this.multiLanguagePipe.transform(collection, lang) ?? "";
    return translatedStr;
  }

  //This mocks the document input from pid to get to further steps, not a final method
  searchDocumentMock() {
    this.apService.obtainEvidence(this.session).subscribe(data => {
      console.log(data);
      if (data) {
        this.document = data;
        this.updateStep(2);
        this.sendButton.requestFinished();
      }
      else {
        this.sendButton.requestFinished();
      }
    });
  }

  searchDocument() {
    this.pidRequest = new PidRequest();
    this.pidRequest.session = this.session;
    if (this.dynamicComponents.length > 0) {
      this.pidRequest.specificData =  this.dynamicComponents[0].getValue();
      //this.pidRequest.specificData.data = this.dynamicComponents[0].getValue();
    }
    console.log(this.pidRequest);

    this.previewService.getEvidence(this.pidRequest).subscribe({
      next: data => {
        console.log(data);
        const response = JSON.parse(data);
        if(response.success){
          this.document = response.body;
          this.updateStep(2);
          this.sendButton.requestFinished();
        }
        else {
          switch(response.status) {
            case 204:
              this.dialogErrorRegionOpen(false);
              break;

            case 400:
              break;
            
            case 404:
              break;

            case 406:
              this.dialogErrorRegionOpen(true);
              break;
              

          }
          this.sendButton.requestFinished();
        }
      },
      error: err => {
        console.log(err);
        this.sendButton.requestFinished();
        switch(err.status) {
          case 204:
            this.dialogErrorRegionOpen(false);
            break;

          case 400:
            break;
          
          case 404:
            break;

          case 406:
            this.dialogErrorRegionOpen(true);
            break;
            

        }
      },
    });
  }

  retrySearch() {
    this.destroyDynamicComponents();
  }

  destroyDynamicComponents() {
    this.dynamicComponents = [];
    this.container.clear();
    this.showSpecificDataComponents();
  }

  updateStep(newStep: number) {
    this.breadcrumbService.setStep(newStep)
  }

  //OOTSResponse
  sendEvidence() {
    this.redirecting = true
    this.previewService.sendResponse(this.session).subscribe(
      {
        next: result => {
          console.log(result);
          this.navigateBack();
        },
        error: (e) => {
          console.error(e)
          this.navigateBack();
        },
        complete: () => {
          console.log("completed")
        }
      }
    );
  }

  openEvidenceSend() {
    this.updateStep(4);
    const data = {
      message: this.translate.instant("PREVIEW_SPACE.SENDING_PROOF"),
      type: 'preview-send'
    }
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendEvidence();
        }
        else if (result?.action === 'cancel') {
          this.updateStep(3);
        }
      });
  }

  openCancelSend() {
    const data = {
      message: this.translate.instant("PREVIEW_SPACE.CANCEL_SUBMISSION_MESSAGE"),
      type: 'preview-send'
    }
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendCancelReject();
        }
/*         else if (result?.action === 'cancel') {
          this.updateStep(3);
        } */
      });
  }

  dialogErrorOpen() {
    const data = {
      message: this.translate.instant("PREVIEW_SPACE.PROOF_NOT_FOUND"),
      type: 'error-preview'
    }
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendCancelReject();
        }
      });
  }

  dialogErrorRegionOpen(technicalError: boolean) {
    let data = undefined;
    if(technicalError){
      data = {
        message: this.translate.instant("PREVIEW_SPACE.TECHNICAL_ERROR"),
        type: 'error-preview-region'
      }
    }
    else{
      data = {
        message: this.translate.instant("PREVIEW_SPACE.PROOF_NOT_FOUND_REGION"),
        type: 'error-preview-region'
      }
    }
    
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendCancelReject();
        }
        else if (result?.action === 'cancel') {
          this.destroyDynamicComponents();
        }
      });
  }

  sendCancelReject() {
    const response = new PreviewSpaceResponse();
    response.errorMessage = "The user reject the evidence exchange."
    response.errorType = ResponseErrorType.USER_REJECT_OR_CANCEL;
    response.session = this.session;
    this.redirecting = true;
    this.previewService.returnReject(response).subscribe({next: () => {
        this.navigateBack();
        this.redirecting = false;
      },
      error: errorContent => {
        this.redirecting = false;
        console.log(errorContent);
      }
    });
  }

  sendTimeoutError() {
    const response = new PreviewSpaceResponse();
    response.errorMessage = "The time to complete the evidence preview has exceeded."
    response.errorType = ResponseErrorType.SESSION_TIMEOUT;
    response.session = this.session;

    this.redirecting = true;
    this.previewService.returnError(response).subscribe({next: () => {
        this.navigateBack();
        this.redirecting = false;
      },
      error: errorContent => {
        this.redirecting = false;
        console.log(errorContent);
      }
    });
  }

  //Return to requesters portal
  navigateBack() {
    this.previewSending = true
    window.location.href = this.returnUrl;
    this.sessionTimerService.endSessionOnClose();
  }

  //Open proof inn other tab
  viewProof() {
    const byteArray = new Uint8Array(atob(this.document).split('').map(char => char.charCodeAt(0)));
    console.log(this.document)
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

}
