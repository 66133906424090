<div>
    <div class="documents-required">
        <p style="font-size: x-large; font-weight: bold;">{{ 'PREVIEW_SPACE.REQUEST_DATA' | translate }}</p>
        <div class="expandable-container">
            <div class="mt-12" style="width: 100%;">
                <table style="float: left; width: 100%;">
                    <tr>
                        <th style="text-align: left; width:30%; font-weight: bold;">
                            {{'EV_REQUESTED.NAME' |
                            translate}}</th>
                        <th
                            style="text-align: left; font-weight: bold; width: 70%;">
                            {{'EV_REQUESTED.FAMILY_NAME' |
                            translate}}</th>
                    </tr>
                    <tr>
                        <td>{{ currentUser.firstName }}</td>
                        <td>{{ currentUser.familyName }}</td>
                        
                    </tr>
                </table>
            </div>
            <div class="mt-12">
                <table style="float: left; width: 100%;">
                    <tr>
                        <th style="text-align: left; width:30%; font-weight: bold;">
                            {{'EV_REQUESTED.EURO_USER_ID' | translate}}</th>
                        <th style="text-align: left; width:40%; font-weight: bold;">
                            {{'EV_REQUESTED.USER_ID' |
                            translate}}</th>
                        <th style="text-align: left; width:30%; font-weight: bold;">
                            {{'EV_REQUESTED.BIRTH_DATE'
                            | translate}}</th>
                    </tr>
                    <tr>
                        <td>{{ currentUser.personIdentifier }}</td>
                        <td>{{ currentUser.personIdentifier.split(regex).pop() }}</td>
                        <td>{{ currentUser.dateOfBirth.toString().split('T')[0] }}</td>
                    </tr>
                </table>
            </div>
            <div class="mt-12" *ngIf="step === 1 || step === 2">
                <table style="float: left; width: 100%; margin-top: 15px;">
                    <tr>
                        <td style="width: 30%"><b>{{'PREVIEW_SPACE.PROCEDURE' | translate}}</b></td>
                        <td>{{'PREVIEW_SPACE.NAME' | translate}}: {{requestInfo.requirements[0].name.value}}</td>
                    </tr>
                    <tr>
                        <td style="width: 30%"><b>{{'PREVIEW_SPACE.TYPE' | translate}}</b></td>
                        <td>{{transformTexts(requestInfo.procedure.localizedString, "EN")}}</td>
                    </tr>
                    <tr>
                        <td style="width: 30%"><b>{{'PREVIEW_SPACE.REQUESTER' | translate}}</b></td>
                        <td>{{transformTexts(requestInfo.evidenceRequester.name, 'EN')}}</td>
                    </tr>
                    <tr>
                        <td style="width: 30%"><b>{{'PREVIEW_SPACE.REQUESTED_PROOF' | translate}}</b></td>
                        <td>{{transformTexts(requestInfo.evidenceRequest.description ,"EN")}}</td>
                    </tr>
                    <tr *ngIf="selectedCommunity && step === 2">
                        <td style="width: 30%"><b>{{'PREVIEW_SPACE.REGION_RESIDENCE' | translate}}</b></td>
                        <td>{{selectedCommunity}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="step === 1">

        <div [ngClass]="specificDataNeeded ? '' : 'hidden'">
            
            <div class="row specific-data-header" >
                <div class="col-md-12 mt-2 pt-2">
                    <table>
                        <tr>
                            <td><i class="fa fa-info-circle icon-background fa-lg" aria-hidden="true"></i></td>
                            <td><b>{{'PREVIEW_SPACE.ADDITIONAL_DATA' | translate}}</b></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <p>{{'PREVIEW_SPACE.CONTINUE_REQUEST' | translate}}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="documents-required">
                <ng-container #dynamicComponentContainer></ng-container>
            </div>

        </div>

        <div class="mt-3 d-flex justify-content-center">
            <app-loader-button text="{{'PREVIEW_SPACE.SEARCH_PROOF' | translate}}" 
                (buttonClicked)="searchDocument()" 
                [disabled]="!allComponentsValid()" 
                class="retrieve-button">
            </app-loader-button>
            <button class="cancel-button" (click)="openCancelSend()">{{'MODAL.CANCEL' | translate}}</button>
        </div>
    </div>    

    <div *ngIf="step === 2" class="mt-3">
        <div>
            <ngx-extended-pdf-viewer height="80vh" [base64Src]="document">
            </ngx-extended-pdf-viewer>
        </div>
        <div *ngIf="document" class="mt-3 d-flex justify-content-center">
            <button (click)="updateStep(3)">{{'MODAL.CONTINUE' | translate}}</button><button class="cancel-button" (click)="openCancelSend()">{{'MODAL.CANCEL' | translate}}</button>
        </div>
    </div>

    <div *ngIf="step === 3 || step === 4" class="mt-3">
        <div class="evidence-info">
            <div class="row-container">
                <div class="col-5">
                    <div class="d-flex justify-content-center align-items-center">
                        <img height="70" src="assets/country-flags/es.png" alt="">
                    </div>
                    <div class="mt-3">
                        <ul class="list-parent">
                            <li>
                                <b>{{'PREVIEW_SPACE.REQUESTED_PROOF' | translate}}</b>
                                <ul>{{transformTexts(requestInfo.evidenceRequest.description ,"EN")}}</ul>
                            </li>
                        </ul>
                    </div>
                    
                    
                </div>
                <div class="col-2">
                    <h1 class="align-self-center">➔</h1>
                </div>
                <div class="col-5">
                    <div class="d-flex justify-content-center align-items-center">
                        <img height="70" [src]="countryAsset.flag" alt="">
                    </div>
                    <div class="mt-3">
                        <ul class="list-parent">
                            <li><b>{{'PREVIEW_SPACE.PROCEDURE' | translate}}</b>
                                <ul>{{'PREVIEW_SPACE.NAME' | translate}}: {{requestInfo.requirements[0].name.value}}</ul>
                            </li>
                            <li><b>{{'PREVIEW_SPACE.TYPE' | translate}}</b>
                                <ul>{{transformTexts(requestInfo.procedure.localizedString, "EN")}}</ul>
                            </li>
                            <li><b>{{'PREVIEW_SPACE.REQUESTER' | translate}}</b>
                                <ul>{{transformTexts(requestInfo.evidenceRequester.name, 'EN')}}</ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mt-3 d-flex justify-content-center">
                <button (click)="openEvidenceSend()" class="retrieve-button">{{'PREVIEW_SPACE.CONTINUE_SUBMISSION' | translate}}</button>
                <button class="cancel-button" (click)="openCancelSend()">{{'PREVIEW_SPACE.CANCEL_SUBMISSION' | translate}}</button>
                <button (click)="viewProof()" class="view-button">{{'PREVIEW_SPACE.VIEW_PROOF' | translate}}</button>
            </div>
        </div>
        
    </div>

    <app-loading-screen [isLoading]="redirecting" [message]="'You will be redirected in a few seconds...'"></app-loading-screen>
    
</div>
