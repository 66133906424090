import { Component, ViewChild } from '@angular/core';
import { SpecificDataComponent } from '../../specific-data.component';
import { Select2Module } from 'ng-select2-component';
import { TranslateModule } from '@ngx-translate/core';
import { CpmComponent } from '../../cpm/cpm.component';

@Component({
  selector: 'app-discapacidad-form',
  standalone: true,
  imports: [Select2Module, TranslateModule, CpmComponent],
  templateUrl: './discapacidad-form.component.html',
  styleUrl: './discapacidad-form.component.css'
})

export class DiscapacidadFormComponent extends SpecificDataComponent{

  @ViewChild(CpmComponent) cpmComponent!: CpmComponent;

  override getValue(): any {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    return this.specificData;
  }

  override isValid(): boolean {
      if(this.cpmComponent && !this.cpmComponent.isValid()) return false;
      return true;
  }

}
