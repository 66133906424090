import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PidRequest } from '../_model/pid-request';
import { PreviewSpaceResponse } from '../_model/preview-space-response';
import { RequestStatusEnum } from '../_model/request-status-enum';

const PREVIEW_API_URL = environment.api_url + "/preview";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  constructor(private http: HttpClient) { }

  isValidSession(session: string): Observable<RequestStatusEnum> {
    return this.http.get<RequestStatusEnum>(PREVIEW_API_URL + "/validate", { params: { session: session } });
  }

  getEvidence(pidRequest: PidRequest): Observable<any> {
    return this.http.post<any>(PREVIEW_API_URL + "/evidence/pdf", pidRequest, { headers, observe: 'response', responseType: 'text' as 'json' })
      .pipe(
        map(this.handleResponseCodes),
        catchError(this.handleError)
      )
  }

  getServicesInformation(requirementId: string): Observable<any> {
    const httpParams = new HttpParams().append("requirementId", requirementId);
    const httpOptions = { headers: headers, params: httpParams };
    return this.http.get<any>(PREVIEW_API_URL + "/services", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  returnError(response: PreviewSpaceResponse): Observable<any>{
    return this.http.post<any>(PREVIEW_API_URL + "/evidence/error", response)
      .pipe(
        catchError(this.handleError)
      )
  }

  returnReject(response: PreviewSpaceResponse): Observable<any>{
    return this.http.post<any>(PREVIEW_API_URL + "/evidence/reject", response)
      .pipe(
        catchError(this.handleError)
      )
  }

  sendResponse(session: string) {
    return this.http.post<any>(PREVIEW_API_URL + "/evidence/accept?session=" + session, { headers: headers, responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    )
  }
  
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    return throwError(() => {
      return error;
    });
  }

  handleResponseCodes(response: any) {
    let errorMessage = '';
    if (response.error instanceof ErrorEvent) {
      errorMessage = response.error.message;
    } else {
      if(response.status == 200){
        response.success = true;
        return JSON.stringify(response);
      }
      errorMessage = `Error Code: ${response.status}, Message: ${response.message}`;
      console.log(errorMessage);
      return '{ "success": false, "status": ' + response.status + ' }';
    }
    return throwError(() => {
      return response;
    });
  }
}
