import { Component, OnInit, ViewChild } from '@angular/core';
import { SpecificDataComponent } from '../../specific-data.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select2Module } from 'ng-select2-component';
import { TranslateModule } from '@ngx-translate/core';
import { EidasUser } from 'src/app/_model/eidasUser';
import { CpmComponent } from '../../cpm/cpm.component';

@Component({
  selector: 'app-nacimiento-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, Select2Module, TranslateModule, CpmComponent],
  templateUrl: './nacimiento-form.component.html',
  styleUrl: './nacimiento-form.component.css'
})

export class NacimientoFormComponent extends SpecificDataComponent implements OnInit {

  @ViewChild(CpmComponent) cpmComponent!: CpmComponent;

  constructor() {
    super();
  }

  birthData: NacimientoData;
  jsonData: any;
  birthDate: string | null = null;
  identifier: string | null | undefined = null;
  identityType = 'DNI';
  supportNumber: string | null;

  eidasUser: EidasUser;
  personForm: FormGroup;

  regex = /^[A-Za-z]{2}\/[A-Za-z]{2}\//;

  ngOnInit(): void {
    this.specificData.birthDate = new Date().toISOString().split('T')[0];
    /*
    if (this.autofill) {
      this.eidasUser = this.jwtUtils.getCurrentUser();
      this.birthData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0];
      this.birthData.identifier = this.eidasUser.personIdentifier.split(this.regex).pop();
      this.birthData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0];

      this.personForm.controls['nombre'].setValue(this.eidasUser.firstName);
      this.personForm.controls['nombre'].disable();
      const apellidos: string[] = this.eidasUser.familyName.split(/(?<=^\S+)\s/);
      this.personForm.controls["apellido1"].setValue(apellidos[0]);
      this.personForm.controls['apellido1'].disable();
      if (apellidos[1]) {
        this.personForm.controls["apellido2"].setValue(apellidos[1]);
        this.personForm.controls['apellido2'].disable();
      }
    }*/
  }

  override getValue() {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    this.specificData.codeProvincia = this.cpmComponent.selectedProvincia?.codigo;
    this.specificData.codeMunicipio = this.cpmComponent.selectedMunicipio?.codigo;
    return this.specificData;
  }

  override isValid(): boolean {
    if (this.specificData && this.specificData.birthDate === null) return false;
    return true;
  }

  onNombreChange(event: any) {
    this.birthData.nombre = event.target.innerText;
    this.emitValueChange(this.birthData);
  }

  onApellido1Change(event: any) {
    this.birthData.apellido1 = event.target.innerText;
    this.emitValueChange(this.birthData);
  }

  onApellido2Change(event: any) {
    this.birthData.apellido2 = event.target.innerText;
    this.emitValueChange(this.birthData);
  }

  identifierTypeChange() {
    if (this.identityType == "DNI") {
      this.birthData.identifier = this.eidasUser.personIdentifier;
      this.birthData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0]
    }
    else if (this.identityType == "NIE") {
      this.birthData.identifier = this.eidasUser.personIdentifier;
      this.birthData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0]
    }
    this.emitValueChange(this.birthData.identifier);
  }

}

export class NacimientoData {
  codeComunidad: string | null = null;
  codeProvincia: string | null = null;
  codeMunicipio: string | null = null;
  nombre: string | null = null;
  apellido1: string | null = null;
  apellido2: string | null = null;
  birthDate: string | null = null;
  identifier: string | null | undefined = null;
  identityType: string | null = 'DNI';
  isSpanishCitizen: boolean | null = null;
}
