<div id="modal-container" class="container" [ngClass]="(data.type.includes('error'))? 'error-container' : 'default-container'">
    <div class="row">
        <div class="col-md-1">
            <div [ngSwitch]="data.type">
                <div *ngSwitchCase="'timeout'"><i class="fa-solid fa-triangle-exclamation"></i></div>
                <div *ngSwitchCase="'session-timeout'"><i class="fa-solid fa-hourglass"></i></div>
                <div *ngSwitchCase="'contact'"><i class="fa-regular fa-user"></i></div>
                <div *ngSwitchCase="'error'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#C44727"
                        class="bi bi-exclamation-octagon-fill fa-lg" viewBox="0 0 16 16">
                        <path
                            d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                    </svg>
                </div>
                <div *ngSwitchCase="'error-translate'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#C44727"
                        class="bi bi-exclamation-octagon-fill fa-lg" viewBox="0 0 16 16">
                        <path
                            d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                    </svg>
                </div>
                <div *ngSwitchCase="'secCode'"><i class="fa-solid fa-lock"></i></div>
                <div *ngSwitchCase="'confirmCode'"><i class="fa-solid fa-lock"></i></div>
                <div *ngSwitchCase="'saveSuccess'"><i class="fa-solid fa-circle-check"></i></div>
                <div *ngSwitchCase="'saveError'"><i class="fa-solid fa-circle-xmark"></i></div>
                <div *ngSwitchCase="'cancel'"><i class="fa-solid fa-trash-can"></i></div>
                <div *ngSwitchDefault><i class="fa-solid fa-arrow-up-right-from-square"></i></div>
            </div>
        </div>
        <div class="col-md-11">
            <div *ngIf="data.type === 'error-translate'" class="title">{{ data.title | translate}}</div>
            <div *ngIf="data.type !== 'error-translate'" class="title">{{ data.title }}</div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-1"></div>
        <div class="col-md-11">
            <p *ngIf="data.type === 'error-translate'" [innerHTML]="data.message | translate "></p>
            <p *ngIf="data.type !== 'error-translate'" [innerHTML]="data.message | translate "></p>

            <div *ngIf="data.type === 'preview' || data.type === 'preview-error-redirect'" class="d-flex justify-content-around">
                <img height="50" src="assets/country-flags/es.png" alt="">
                <h1 class="align-self-center">➔</h1>
                <img height="50" [src]="data.toAsset.flag" alt="">
            </div>

            <div *ngIf="data.type === 'contact'">
                <div class="row">
                    <div class="col-md-12 ml-2">
                        <input type="radio" name="contactType" value="email" [(ngModel)]="selectedContactType"
                            (change)="onRadioButtonChange($event)" selected />
                        <span class="radio-label">Email</span>
                        <input type="radio" disabled name="contactType" value="phoneNumber"
                            [(ngModel)]="selectedContactType" (change)="onRadioButtonChange($event)" />
                        <span class="radio-label">Phone number</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-11 ml-2 mr-5 mt-2 mb-4">
                        <div *ngIf="selectedContactType === 'email'">
                            <input id="email" type="text" style="height: 30px;" [(ngModel)]="contactValue"
                                [placeholder]="placeholder" (input)="onInputChange($event)"
                                (focus)="onInputChange($event)">
                        </div>
                        <div *ngIf="selectedContactType === 'phoneNumber'">
                            <input id="phone" type="text" style="height: 30px;" [(ngModel)]="contactValue"
                                [placeholder]="placeholder" (input)="onInputChange($event)"
                                (focus)="onInputChange($event)">
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="data.type === 'secCode'" >
                <div class="row">
                    <div>
                        <div [ngSwitch]="data.isLoading">
                            <div *ngSwitchCase="false" class="col-md-11 mt-1 mb-4">
                                <input type="text" [placeholder]="placeholder" style="height: 30px;" [(ngModel)]="contactValue" (focus)="onEmailFocus($event)">
                                <div *ngIf="data.errorMessage" class="error-msg mt-1" [innerHTML]="data.errorMessage | translate"></div>
                            </div>
                            <div *ngSwitchCase="true" class="mt-2 mb-4 d-flex justify-content-around">
                                <div class="row mr-4 spinner"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="data.type === 'confirmCode'" class="d-flex justify-content-around">
                <div class="row mr-4">
                    <div class="codes d-flex flex-row justify-content-center col-md-8 mb-3 mx-auto">
                        <input #input0 type="text" class="m-1 text-center form-control rounded" (input)="moveNext(0, $event)" maxlength="1">
                        <input #input1 type="text" class="m-1 text-center form-control rounded" (input)="moveNext(1, $event)" maxlength="1">
                        <input #input2 type="text" class="m-1 text-center form-control rounded" (input)="moveNext(2, $event)" maxlength="1">
                        <input #input3 type="text" class="m-1 text-center form-control rounded" (input)="moveNext(3, $event)" maxlength="1">
                    </div>
                    <div *ngIf="data.errorMessage" class="error-msg mb-4" [innerHTML]="data.errorMessage | translate"></div>
                </div>
            </div>

        </div>
        <div *ngIf="data.type === 'server-session-timeout'">
            <p class="text-center align-items-start"><i class="fa-solid fa-hourglass side-icon"></i> {{time}}{{'MODAL.REMAINING_TIME' | translate}}</p>
        </div>
        <div *ngIf="data.type === 'client-session-timeout'"></div>

        <div *ngIf="data.type === 'loading-modal'" class="loading-container">
            <div class="big-spinner"></div>
        </div>
    </div>

    <div class="row button-row">
        <div class="col-md-6">
            <div *ngIf="data.type === 'preview' || data.type === 'contact' || data.type === 'secCode'">
                <button (click)="onCancel()" [hidden]="data.isLoading">{{'MODAL.CANCEL' | translate}}</button>
            </div>
        </div>
        <div *ngIf="data.type === 'preview-send'" class="d-flex justify-content-between">
            <button (click)="onAccept()">{{ 'MODAL.CONTINUE' | translate }}</button>
            <button (click)="onCancel()">{{ 'MODAL.CANCEL' | translate }}</button>
        </div>
        <div *ngIf="data.type === 'error-preview-region' || data.type === 'error-preview'" class="d-flex justify-content-between">
            <button (click)="onAccept()">{{ 'MODAL.FINALIZE' | translate }}</button>
            <button *ngIf="data.type === 'error-preview-region'" (click)="onCancel()">{{ 'MODAL.OTHER_DATA' | translate }}</button>
        </div>
        <div *ngIf="!(data.type === 'error' || data.type === 'error-translate' || data.type === 'cancel' || data.type === 'preview-send' || data.type.includes('error-preview'))" class="col-md-6 r-5">
            <button id="accept-button" (click)="onAccept()" [hidden]="data.isLoading">{{'MODAL.CONTINUE' | translate}}</button>
        </div>
        <div *ngIf="data.type === 'cancel'" class="col-md-6 r-5">
            <button (click)="onAccept()" style="width: 80px;">{{ 'MODAL.YES' | translate }}</button>
            <button (click)="onCancel()" style="width: 80px;">{{ 'MODAL.NO' | translate }}</button>
        </div>
    </div>
</div>