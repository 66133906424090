import { EventEmitter } from "@angular/core";
import { SpecificData } from "../_model/pid-request";

export abstract class SpecificDataComponent {
  service: string;
  autofill: boolean;
  askForNationality = false;
  specificData: SpecificData = new SpecificData();
  
  abstract isValid(): boolean;
  
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  protected emitValueChange(value: any): void {
    this.valueChanged.emit(value);
  }
  
  abstract getValue(): any;
}