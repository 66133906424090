import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Data, Select2Module } from 'ng-select2-component';
import { PreviewService } from 'src/app/_service/preview.service';
import { ResponseUniversidad } from 'src/app/_model/preview-space-response';
import { SpecificDataComponent } from '../../specific-data.component';

@Component({
  selector: 'app-enrolment-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, Select2Module, TranslateModule],
  templateUrl: './enrolment-form.component.html',
  styleUrl: './enrolment-form.component.css'
})
export class EnrolmentFormComponent extends SpecificDataComponent implements OnInit{

  constructor(private previewService: PreviewService) {
    super();
  }

  universidades: ResponseUniversidad[];
  universidadesData: Select2Data = []
  aniosAcademicosData: Select2Data = []
  selectedUniversidad: string;
  anioAcademico: string;

  ngOnInit(): void {
    this.getCodigosUniversidad();
    this.getAniosAcademicos();
  }

  private getAniosAcademicos(){
    this.aniosAcademicosData = [];
    for(let year = new Date().getFullYear(); year>=2000; year--){
      this.aniosAcademicosData.push(
        {
          value: year + " - " + ((year + 1) + "").substring(2),
          label: year + " - " + (year + 1),
        }
      )
    }
  }

  private getCodigosUniversidad(){
    this.previewService.getDataByService("ListadoUniversidades", undefined).subscribe( {next: data => {
      this.universidades = data.universidades;
      this.universidadesData = this.universidades.map((universidad:ResponseUniversidad) => ({
        value: universidad.codigoUniversidad,
        label: universidad.nombreUniversidad,
      }));
  }})
  }

  override isValid(): boolean {
    return this.specificData.universidades !== undefined && this.specificData.anioAcademico !== undefined;
  }
  override getValue() {
    return this.specificData;
  }
}
