
<div *ngIf="askForNationality; else cpm">
    <div class="step radio-container">
        <div>
            <div [ngClass]="spanishCitizen === null ? 'fa-solid fa fa-circle-chevron-right fa-lg' : 'fa-solid fa fa-circle-check fa-lg'"></div>
        </div>
        <label for="nationality">Es usted ciudadano nacido en España?</label>
        <div class="radio-group">
            <input type="radio" id="spanishYes" name="nationality" [(ngModel)]="spanishCitizen" [value]="true" (change)="spanishCitizenChange()">
            <label for="spanishYes">Si</label>
        </div>
        <div class="radio-group">
            <input type="radio" id="spanishNo" name="nationality" [(ngModel)]="spanishCitizen" [value]="false" (change)="spanishCitizenChange()">
            <label for="spanishNo">No</label>
        </div>
    </div>

    <div *ngIf="spanishCitizen !== null">
        <div class="step cpm-step" *ngIf="spanishCitizen !== null">
            <div [ngClass]="isValid() ? 'fa-solid fa-circle-check fa-lg' : 'fa-solid fa-circle-chevron-right fa-lg'"></div>
            <label *ngIf="spanishCitizen" for="ambito">Seleccione su municipio de nacimiento</label>
            <label *ngIf="!spanishCitizen" for="ambito">Seleccione su municipio de residencia</label>
        </div>
        <ng-container *ngTemplateOutlet="cpm"></ng-container>
    </div>
</div>

<ng-template #cpm>
    <div class="cpm-container">
        <div class="select-group">
            <label for="comunidad">{{ 'PREVIEW_SPACE.COMMUNITY' | translate }}</label>
            <select id="comunidad" [(ngModel)]="selectedComunidad" (change)="onComunidadChange(selectedComunidad!)">
                <option value=null disabled selected>{{ 'PREVIEW_SPACE.COMMUNITY_SELECT' | translate }}</option>
                <option *ngFor="let comunidad of comunidades" [ngValue]="comunidad">{{ comunidad.nombre }}</option>
            </select>
        </div>
    
        <div class="select-group" [ngClass]="{'hidden': type === 'ccaa'}">
            <label for="provincia">{{ 'PREVIEW_SPACE.PROVINCE' | translate }}</label>
            <select id="provincia" [(ngModel)]="selectedProvincia" (change)="onProvinciaChange(selectedProvincia!)"
                [disabled]="!selectedComunidad">
                <option value=null disabled selected>{{ 'PREVIEW_SPACE.PROVINCE_SELECT' | translate }}</option>
                <option *ngFor="let provincia of provincias" [ngValue]="provincia">{{ provincia.nombre }}</option>
            </select>
        </div>
    
        <div class="select-group" [ngClass]="{'hidden': type === 'ccaa'}">
            <label for="municipio">{{ 'PREVIEW_SPACE.MUNICIPALITY' | translate }}</label>
            <select id="municipio" [(ngModel)]="selectedMunicipio" (change)="onMunicipioChange(selectedMunicipio!)"
                [disabled]="!selectedProvincia">
                <option value=null disabled selected>{{ 'PREVIEW_SPACE.MUNICIPALITY_SELECT' | translate }}</option>
                <option *ngFor="let municipio of municipios" [ngValue]="municipio">{{ municipio.nombre }}</option>
            </select>
        </div>
    </div>
</ng-template>


