import { Component, OnInit } from '@angular/core';
import { SpecificDataComponent } from '../../specific-data.component';
import { FormsModule } from '@angular/forms';
import { Select2Module } from 'ng-select2-component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-disolucion-matrimonio-form',
  standalone: true,
  imports: [FormsModule, Select2Module, TranslateModule],
  templateUrl: './disolucion-matrimonio-form.component.html',
  styleUrl: './disolucion-matrimonio-form.component.css'
})
export class DisolucionMatrimonioFormComponent extends SpecificDataComponent implements OnInit {

    constructor() {
      super();
    }
  
    ngOnInit(): void {
      this.specificData.fechaHechoRegistral = new Date().toISOString().split('T')[0];
    }
  
    override isValid(): boolean {
      if (this.specificData.fechaHechoRegistral === null) return false;
      return true;
    }
  
    override getValue() {
      return this.specificData;
    }

}
