<div class="table-container" *ngIf="loggedUser">
    <div class="bluebox">
        <span class="title" >{{capitalize(section) + ' management'}} </span>
    </div>

    <div class="hamburger-menu">
        <button [ngClass]="menuShow ? 'menu__show': 'menu__btn'" (click)="menuShow = !menuShow">
                <span></span>
        </button>
        

        <ul class="menu__box" [ngClass]="{'open__box': menuShow}">
            <li *ngIf="loggedUser !== undefined">
                <button class="menu__item" (click)="initWhitelist()">Whitelist</button>
            </li>
            <li *ngIf="loggedUser !== undefined && loggedUser.role === 'ROLE_ADMIN'">
                <button class="menu__item" (click)="initProperties()">Properties</button>
            </li>
<!--             <li *ngIf="loggedUser !== undefined">
                <button class="menu__item" (click)="initProperties()">Properties</button>
            </li> -->
            <li *ngIf="loggedUser !== undefined">
                <button class="menu__item" (click)="section='requirements'; menuShow=false; loadedData=false">Requirements</button>
            </li>
        </ul>
    </div>

    <div class="but-holder">
        <button (click)="logout()">Logout</button>
    </div>
    <div *ngIf="section === 'whitelist' && loadedData"  class="mat-elevation-z8">
        <mat-form-field class="filter">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row"> 
                    <div>{{row.id}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Url </th>
                <td mat-cell *matCellDef="let row"> 
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">{{row.url}}</div>
                    <div *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')"><input type="text" [(ngModel)]="urlInput"></div>
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Is active </th>
                <td mat-cell *matCellDef="let row"> 
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">{{row.active}}</div> 
                    <div *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')"> 
                        <select [(ngModel)]="isActive">
                            <option value=true>True</option>
                            <option value=false>False</option>
                        </select> 
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                <td mat-cell *matCellDef="let row"> 
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))"><button [disabled]="visibleInputs" (click)="row.actions = 'edit'; visibleInputs = true; urlInput=row.url; isActive=row.active;">Edit</button> <button [disabled]="visibleInputs" (click)="delete(row.id)">Delete</button></div> 
                    <div *ngIf="row.actions && row.actions === 'edit'"><button (click)="saveEdit(row.id)">Save</button> <button (click)="row.actions = undefined; visibleInputs = false;">Cancel</button></div>
                    <div *ngIf="row.actions && row.actions === 'new'"><button (click)="save()">Save</button> <button (click)="cancelSave()">Cancel</button></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [disabled]="visibleInputs" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"></mat-paginator>
        <button (click)="setSaving()">Add</button>
    </div>
    <!-- <div *ngIf="section === 'properties'" class="mat-elevation-z8"> -->
    <div *ngIf="section === 'properties' && loggedUser.role === 'ROLE_ADMIN'" class="mat-elevation-z8">
        <div class="pt-4 pb-2 admin-tools">
            <table>
                <tr>
                    <th><p class="mb-2">Scheduler (ms)</p></th>
                </tr>
                <tr>
                    <td><input type="text" [(ngModel)]="scheduler"></td>
                    <td><button (click)="changeScheduler()">Change</button></td>
                </tr>
            </table>
        </div>
        <div class="pt-4 pb-2 admin-tools" *ngIf="levelsForm">
            
            <form [formGroup]="levelsForm" class="m-0 p-0">
                <table>
                    <tr>
                        <th><p class="mb-2">Logging level</p></th>
                    </tr>
                    <tr>
                        <select *ngIf="levelsForm.controls['levelControl']" formControlName="levelControl" (change)="logLevelMethods(levelsForm.controls['levelControl'].value)">
                            <option [value]="level" *ngFor="let level of levels">{{level}}</option>
                        </select>                            
                    </tr>
                </table>
                
            </form>
        </div>
        
        <mat-form-field class="filter">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilterProperties($event)" placeholder="" #input>
        </mat-form-field>

        <div class="tabs">
            <button class="tab" *ngFor="let type of types; let i = index;" [ngClass]="{'selected-tab': type.selected}" (click)="changeType(i)">
                <span>{{capitalize(type.name)}}</span>
            </button>
        </div>
        <table mat-table [dataSource]="dataSourceProperties" matSort class="mt-3">

            <ng-container matColumnDef="propertyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Property name </th>
                <td mat-cell *matCellDef="let row"> 
                    <div>{{row.propertyName}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Property value </th>
                <td mat-cell *matCellDef="let row"> 
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">{{row.value}}</div>
                    <div *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')"><input type="text" [(ngModel)]="propertyValue"></div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                <td mat-cell *matCellDef="let row ; let i = index"> 
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))"><button [disabled]="visibleInputsProperties" (click)="row.actions = 'edit'; visibleInputsProperties = true; urlInput=row.url; isActive=row.active;">Edit</button></div> 
                    <div *ngIf="row.actions && row.actions === 'edit'"><button (click)="saveEditedProperty(i)">Save</button> <button (click)="row.actions = undefined; visibleInputsProperties = false;">Cancel</button></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsProperties"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsProperties;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
        <mat-paginator [disabled]="visibleInputsProperties" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"></mat-paginator>
    </div>
</div>
<div class="custom-alert" *ngIf="popUp.show">
    <p class="title-alert">{{popUp.title}}</p>
    <p class="message-alert">{{popUp.message}}</p>
</div>
