<div class="step radio-container">
    <label for="identity">1. {{ 'PREVIEW_SPACE.SPANISH_CITIZEN' | translate }}</label>
    <div class="radio-group">
        <input type="radio" id="spanishYes" name="spanishCitizen" [(ngModel)]="specificData.spanishCitizen" [value]="true" (change)="onChange()">
        <label for="spanishYes">Si</label>
    </div>
    <div class="radio-group">
        <input type="radio" id="spanishNo" name="spanishCitizen" [(ngModel)]="specificData.spanishCitizen" [value]="false" (change)="onChange()">
        <label for="spanishNo">No</label>
    </div>
</div>

<div class="municipality-container">
    <div><label *ngIf="specificData.spanishCitizen === true" for="ambito">2. {{ 'PREVIEW_SPACE.MUNICIPALITY_BORN_SELECT' | translate }}</label></div>
    <div><label *ngIf="specificData.spanishCitizen === false" for="ambito">2. {{ 'PREVIEW_SPACE.MUNICIPALITY_RESIDENCE_SELECT' | translate }}</label></div>
    <div><app-cpm [comunidad]=true [provincia]=true [municipio]=true></app-cpm></div>
</div>