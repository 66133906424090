import { AfterViewInit, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ManagementService } from '../_service/management.service';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule,ReactiveFormsModule} from '@angular/forms';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class ManagementComponent implements AfterViewInit{
  levelsForm: FormGroup;
  displayedColumns: string[] = ['id', 'url', 'active', 'actions'];
  columnsProperties: string[] = ['propertyName', 'value', 'actions'];
  loggedUser:any = undefined;
  dataSource: MatTableDataSource<any>;
  dataSourceProperties: MatTableDataSource<any>;
  myDate: Date = new Date();
  limits = [0,5];
  propertiesLimits = [0,5]
  baseData: any[] = [];
  propertyData: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  scheduler: 0;
  propertyValue: "";
  classLog = "es.oots";
  levels = ["INFO", "DEBUG", "ERROR"];
  types = [{name: "all", selected: true}]
  popUp = {show: false, title: "", message: ""}
  previousScheduler = 0;
  section = "whitelist"

  visibleInputs = false;
  visibleInputsProperties = false;
  urlInput:string;
  isActive = true;
  menuShow = false;
  loadedData = false;


  constructor(private managementService: ManagementService, private router: Router, private jwtUtilsService: JwtUtilsService, private fb: FormBuilder){}
  ngAfterViewInit() {
    this.managementService.getLoggedUser().subscribe({
      next: result => {
        //Delete or comment for deployment and pushing to repo, uncomment for development purposes only
        /* if(result){
          this.loggedUser = result;
        }
        else{
          this.loggedUser = "A user";
        } */
        
        this.loggedUser = result;
        this.getTypes();
        this.initWhitelist();
      }
    })
  }

  initWhitelist(){
    this.section='whitelist'; 
    this.menuShow=false; 
    this.loadedData=false;
    this.search();
  }

  initProperties(){
    this.section='properties'; 
    this.menuShow=false; 
    this.loadedData=false;
    this.previousScheduler = 0;
    this.searchProperties();
    this.getScheduler();
    this.logLevelMethods();
  }

  search() {
    this.loadedData = true;
    this.managementService.getWhitelist(this.limits).subscribe(
      {
         next: result => {
          this.baseData = result;
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
         }
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  nextPage(event: PageEvent) {
    this.limits[0] = event.pageIndex * event.pageSize;
    this.limits[1] = event.pageSize;
    this.search();
  }

  nextPageProperties(event: PageEvent) {
    this.propertiesLimits[0] = event.pageIndex * event.pageSize;
    this.propertiesLimits[1] = event.pageSize;
    this.searchProperties();
  }

  delete(id:number){
    this.managementService.deleteWhitelistEntry(id).subscribe({next: response => {
      this.search()
    }})
  }

  saveEdit(id: number){
    const whitelistEntry =
    {
      "id": id,
      "url": this.urlInput,
      "active": this.isActive 
    }
    this.managementService.saveWhitelistEntry(whitelistEntry).subscribe({next: response => {
      this.visibleInputs = false;
      this.search();
    }})
  }

  save(){
    const whitelistEntry =
    {
      "url": this.urlInput,
      "active": this.isActive 
    }
    this.managementService.saveWhitelistEntry(whitelistEntry).subscribe({next: response => {
      this.visibleInputs = false;
      this.limits[0] = 0;
      this.search();
    }})
  }

  setSaving(){
    const math = Math;
    this.visibleInputs = true;
    this.managementService.getWhitelistLength().subscribe(
      {
         next: result => {
          let limit = math.trunc(result/this.limits[1]);
          limit ++;
          this.limits[0] = limit * this.limits[1];
          this.managementService.getWhitelist(this.limits).subscribe(
            {
               next: result => {
                this.baseData = result;
                this.dataSource = new MatTableDataSource(result);
                const extendedData = this.baseData;
                extendedData.push({id: "", url: "", active:false, actions: "new"});
                this.dataSource = new MatTableDataSource(extendedData);
               }
            }
          )
         }
      }
    )
  }

  applyFilterProperties(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceProperties.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceProperties.paginator) {
      this.dataSourceProperties.paginator.firstPage();
    }
  }

  cancelSave(){
    this.dataSource = new MatTableDataSource(this.baseData);
    this.visibleInputs = false;
    this.limits[0] = 0;
    this.search();
  }
  
  changeScheduler(){
    this.managementService.changeScheduler(this.scheduler).subscribe({next: response => {
      this.getScheduler();
      this.visibleInputs = false;
      this.limits[0] = 0;
    }})
  }
  searchProperties() {
    const selType = this.types.filter(type => type.selected == true).at(0)?.name
    this.loadedData=true;
    this.managementService.getProperties(selType).subscribe(
      {
         next: result => {
          this.propertyData = result;
          this.dataSourceProperties = new MatTableDataSource(result);
          this.dataSourceProperties.paginator = this.paginator;
          this.dataSourceProperties.sort = this.sort;
         }
      }
    )
  }
  saveEditedProperty(index: number){

    const property = this.propertyData[index]
    property.value = this.propertyValue
    this.managementService.savePropertiesEntry(property).subscribe({next: response => {
      this.visibleInputsProperties = false;
      this.propertyValue = "";
      this.searchProperties();
    }})
  }
  logout(){
    this.managementService.logoutUser().subscribe({next: response => {
        this.router.navigate(['/login-maintenance']);
      },
      error: (err) =>{
        this.router.navigate(['/login-maintenance'])
      }
    }
    
    )
  }
  logLevelMethods(loglevel?:string){
    this.managementService.logLevelServices(this.classLog, loglevel).subscribe({next: response => {
      if(loglevel){
        this.popUp = {show: true, title: "Log level changed", message: response + ""}
        setTimeout(() => this.popUp.show = false, 4000);
      }
      else{
        this.levelsForm = this.fb.group({
          levelControl: [response]
        })
      }
    }})
  }

  getTypes(){
    this.managementService.getPropertyTypes().subscribe(
      {
         next: result => {
          result.forEach((type: string) => {
            this.types.push({name: type, selected: false})
          })
         }
      }
    )
  }
  getScheduler(){
    this.managementService.getScheduler().subscribe({ next: result => {
      this.scheduler = result;
      if(this.previousScheduler != 0){
        this.popUp = {show: true, title: "Scheduler changed", message: "Scheduler time changed to " + this.scheduler + "ms, the change will be executed in " + this.previousScheduler + "ms at most."}
        setTimeout(() => this.popUp.show = false, 4000);
      }
      this.previousScheduler = result;
    }

    })
  }

  changeType(index: number){
    this.types.forEach(type => {type.selected = false})
    this.types[index].selected = true;
    this.searchProperties();
    
  }

  capitalize(str: string){
    return String(str).charAt(0).toUpperCase() + String(str).slice(1)
  }
}
