import { Component, OnInit, ViewChild } from '@angular/core';
import { SpecificDataComponent } from '../../specific-data.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Module } from 'ng-select2-component';
import { CpmComponent } from '../../cpm/cpm.component';

@Component({
  selector: 'app-matrimonio-form',
  standalone: true,
  imports: [FormsModule, Select2Module, TranslateModule, CpmComponent],
  templateUrl: './matrimonio-form.component.html',
  styleUrl: './matrimonio-form.component.css'
})
export class MatrimonioFormComponent extends SpecificDataComponent implements OnInit {

  @ViewChild(CpmComponent) cpmComponent!: CpmComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.specificData.fechaHechoRegistral = new Date().toISOString().split('T')[0];
  }

  override isValid(): boolean {
    if (this.specificData.fechaHechoRegistral === null) return false;
    if(this.cpmComponent && !this.cpmComponent.isValid()) return false;
    return true;
  }

  override getValue() {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    this.specificData.codeProvincia = this.cpmComponent.selectedProvincia?.codigo;
    return this.specificData;
  }

}

