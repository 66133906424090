import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EidasUser } from '../_model/eidasUser';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { AccessPointService } from '../_service/access-point.service';
import { EidasService } from '../_service/eidas-service.service';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { AgreementComponent } from '../agreement/agreement.component';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../_service/state.service';
import { OotsService } from '../_service/oots.service';
import { MultiLanguageStringPipe } from '../_model/multi-language-string';
import { QueryRequestData } from '../_model/queryRequestData';
import { ModalDialogService } from '../_service/modal-dialog.service';
import { ModalDialogComponent } from '../_common/modal-dialog/modal-dialog.component';
import { COUNTRIES } from '../contants';
import { PreviewSpaceResponse, ResponseErrorType } from '../_model/preview-space-response';
import { PreviewService } from '../_service/preview.service';
import { Utils } from '../_common/utils';
import { RequestStatusEnum } from '../_model/request-status-enum';

declare const require: any;
const i18nIsoCountries = require("i18n-iso-countries");
@Component({
  selector: 'app-preview-space',
  templateUrl: './preview-space.component.html',
  styleUrls: ['./preview-space.component.css']
})
export class PreviewSpaceComponent implements OnInit {

  isUserLogged: boolean;
  isValidSession: boolean;
  currentUser: EidasUser;
  pdfLink = ""
  requestId: string;
  returnUrl: string;
  session: string;
  selectedLanguage: string;
  isChecked = false;
  requestInfo: QueryRequestData;
  countries: any;
  sessionStatus: string;

  constructor(
    private route: ActivatedRoute,
    private jwtUtilService: JwtUtilsService,
    private eidasService: EidasService,
    private apService: AccessPointService,
    private dialog: Dialog,
    public translate: TranslateService,
    private multiLanguagePipe: MultiLanguageStringPipe,
    private stateService: StateService,
    private ootsService: OotsService,
    private dialogService: ModalDialogService,
    private previewService: PreviewService) {
  }

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    this.session = params['session'];
    this.returnUrl = params['returnurl'];
    if (this.session != undefined) {
      window.localStorage.setItem('session', this.session);
      this.requestId = this.session;
      window.localStorage.setItem('returnurl', this.returnUrl);
    } else {
      this.session = window.localStorage.getItem('session') ?? "";
      this.requestId = window.localStorage.getItem('session') ?? "";
      this.returnUrl = window.localStorage.getItem('returnurl') ?? "";
    }
    this.isUserLogged = this.jwtUtilService.getCurrentUser() !== undefined;
    this.validateSession(this.session);
  }

  agreementChange(event: any) {
    this.stateService.agreementAccepted = this.isChecked;
  }

  acceptOrDenyEvidence(accept: boolean) {
    console.log("peticionId: " + this.requestId + " accept: " + accept);
    this.previewService.sendResponse(this.session).subscribe(
      {
        next: result => {
          console.log(result);
        },
        error: (e) => {
          console.error(e)
        },
        complete: () => {
          console.log("completed")
        }
      }
    );
    this.navigateBack();
  }

  validateSession(session: string) {
    console.log("validating session: " + session);
    if (this.isUserLogged) {
      this.previewService.isValidSession(session).subscribe(requestSessionStatus => {
        console.log(requestSessionStatus);
        this.sessionStatus = requestSessionStatus.toString();

        switch (requestSessionStatus.toString()) {

          case 'NOT_FOUND':
            this.retrySearch(session);
            break;

          case 'PENDING': 
            this.eidasService.getRequestDataInfo(session).subscribe(
              {
                next: result => {
                  console.log(result);
                  this.requestInfo = result;
                },
                error: (e) => {
                  console.error("Error" + e)
                },
                complete: () => {
                  console.log("completed")
                }
              });

            this.isValidSession = true;
            break;

          case 'EXPIRED':
          case 'REQUESTED':
          case 'OBTAINED':
          case 'PROCESSED':
            this.isValidSession = true;
            break;
        }
      });
    }
    else this.dialogService.closeDialog();
  }

  retrySearch(session: string) {
    let reintentos = 10;
    const interval = setInterval(() => {
      const remainingTime = reintentos;
      if (remainingTime > 0) {
        reintentos = reintentos - 1
        this.previewService.isValidSession(session).subscribe(requestSessionStatus => {
          console.log(requestSessionStatus);

          switch (requestSessionStatus) {
            case RequestStatusEnum.NOT_FOUND:
              this.retrySearch(session);
              break;

            case RequestStatusEnum.EXPIRED:
              this.isValidSession = true;
              break;

            case RequestStatusEnum.PENDING:
              this.isValidSession = true;
              this.apService.obtainEvidence(session).subscribe(data => {
                console.log(data);
                this.pdfLink = data
                reintentos = 0;
              })
              break;

            case RequestStatusEnum.REQUESTED:
            case RequestStatusEnum.OBTAINED:
            case RequestStatusEnum.PROCESSED:
              this.isValidSession = true;
              break;
          }

        });
      } else {
        this.dialogService.closeDialog();
        if (this.isValidSession) {
          clearInterval(interval);
          this.openPreviewDialog();
        }

      }
    }, 1000);
  }

  openLoadingDialog() {
    const data = {
      title: this.translate.instant('PREVIEW_SPACE.LOADING'),
      message: this.translate.instant('PREVIEW_SPACE.REQ_LOAD'),
      type: 'loading-modal'
    }
    this.dialogService.openDialog(ModalDialogComponent, data).subscribe();
  }

  openPreviewDialog() {
    const data = {
      title: this.translate.instant('PREVIEW_SPACE.REQ2_ERR'),
      message: this.translate.instant('PREVIEW_SPACE.REQ2_MESS', { document_name: this.requestInfo.requirements[0].name.value ?? "", provider: this.transformTexts(this.requestInfo.evidenceRequester.name, 'EN') }),
      toAsset: COUNTRIES[this.requestInfo.evidenceRequester.address?.adminUnitLevel1?.value] ?? COUNTRIES['EU'],
      type: 'preview-error-redirect'
    };

    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendRequest2Error();
        } else if (result?.action === 'cancel') {
          this.validateSession(this.session);
        }
      });
  }

  navigateBack() {
    window.location.href = this.returnUrl;
  }

  openAgreementDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { top: '50%', left: '50%' };
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.translate.instant('PREVIEW_SPACE.TERMS_AND_CONDITIONS_1'),
      message: this.translate.instant('PREVIEW_SPACE.TERMS_AND_CONDITIONS_2'),
    };
    const dialogRef = this.dialog.open(AgreementComponent, dialogConfig);
    dialogRef.closed.subscribe(result => {
      window.sessionStorage.setItem('agreementAccepted', 'true');
    });
  }
  transformTexts(collection: any[], lang: string) {
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json"));
    const translatedStr = this.multiLanguagePipe.transform(collection, lang) ?? "";
    return translatedStr;
  }

  changeCountryLanguage(countryCode: string | null, lang: string) {
    if (!Utils.hasValue(countryCode)) return "";
    const countryName = i18nIsoCountries.getName(countryCode, lang);
    if (countryName === undefined) {
      return countryCode;
    }
    else {
      return countryName;
    }
  }

  goToLink(link: string) {
    window.open(link, "_blank");
  }

  handleResponseEidas(isRedirect: boolean) {
    if (isRedirect) {
      this.sendUserMismatchError()
    }
    else {
      this.isUserLogged = false;
    }
  }

  sendRequest2Error() {
    const response = new PreviewSpaceResponse();
    response.errorMessage = "The second request was not received'"
    response.errorType = ResponseErrorType.INVALID_REQUEST;
    response.session = this.session;
    this.returnError(response);
  }

  sendUserMismatchError() {
    const response = new PreviewSpaceResponse();
    response.errorMessage = "Unable to match logged in user with QueryRequest natural person"
    response.errorType = ResponseErrorType.AUTHENTICATION;
    response.session = this.session;
    this.returnError(response);
  }

  returnError(response: PreviewSpaceResponse) {
    this.previewService.returnError(response).subscribe({
      next: data => {
        this.navigateBack();
      },
      error: errorContent => {
        console.log(errorContent);
      }

    },);
  }
}