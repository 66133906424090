/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccessPointQueryParams } from '../_model/access-point-query-params';
import { EvidenceResponseParams } from '../_model/evidence-response-params';

const AP_API_URL = environment.api_url + "/ap";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root'
})
export class AccessPointService {


  constructor(private http: HttpClient) { }

  sendPlainXML(xml: string): Observable<any> {
    return this.http.post<any>(AP_API_URL + "/expert-mode/request", xml, { headers, responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError)
      )
  }

  sendGuidedModeRequest(params: AccessPointQueryParams): Observable<any> {
    return this.http.post<any>(AP_API_URL + "/guided-mode/request", params, { headers })
      .pipe(
        catchError(this.handleError)
      )
  }

  sendRequest(params: AccessPointQueryParams): Observable<any> {
    return this.http.post<any>(AP_API_URL + "/evidence/request", params, { headers, responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError)
      )
  }

  retrievePendingMessages(): Observable<any> {
    return this.http.get<any>(AP_API_URL + "/messages/pending", { headers })
      .pipe(
        catchError(this.handleError)
      )
  }

  obtainEvidence(session:string): Observable<any> {
    return this.http.get<any>(AP_API_URL + "/evidence/pdf", { headers,params: { session: session }  , responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError)
      )
  }

  getRequestDataInfo(session: string) {
    return this.http.get<any>(AP_API_URL + "/evidence/requestData", { params: { session: session } });
  }

  getPIDInfo(identifier: string) {
    return this.http.get<any>(environment.api_url + "/ap/evidence/getPID",  { params: { requirementId: identifier} })
  }

  retrieveMessageById(messageId: any): Observable<any> {

    const httpParams = new HttpParams().append("messageId", messageId);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams,
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(AP_API_URL + "/message/envelope", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    console.log(errorMessage);
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

}
