import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtUtilsService } from './jwt-utils.service';
import { ManagementService } from './management.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private jwtUtils: JwtUtilsService, private router: Router, private managementService: ManagementService) { }

  canActivate(next:ActivatedRouteSnapshot,state: RouterStateSnapshot ): boolean {
    if(next.url[0].path==="locate-and-request-evidence"){
      window.localStorage.setItem('uuid', next.queryParams['uuid']);
    }
    if(next.url[0].path==="management"){
      this.managementService.getLoggedUser().subscribe({
        next: result => {
          if(result.username && (result.role="ROLE_ADMIN" || result.role=="ROLE_MAINTAINER")){
            return true;
          } else {
            this.router.navigate(['/login-maintenance']);
            return false;
          }
        },
        error: err => {
          this.router.navigate(['/login-maintenance']);
          return true;
        }
      })
      return true;
    }
    else{
      if (this.jwtUtils.isLogged()) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }
    
  }
}
