import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Module } from 'ng-select2-component';
import { CpmComponent } from '../../cpm/cpm.component';
import { SpecificDataComponent } from '../../specific-data.component';

@Component({
  selector: 'app-defuncion-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, Select2Module, TranslateModule, CpmComponent],
  templateUrl: './defuncion-form.component.html',
  styleUrl: './defuncion-form.component.css'
})
export class DefuncionFormComponent extends SpecificDataComponent{
  @ViewChild(CpmComponent) cpmComponent!: CpmComponent;

  constructor() {
    super();
  }
  
  personForm: FormGroup = new FormGroup({
    identifier: new FormControl(null, Validators.required),
    birthDate: new FormControl(null, Validators.required),
    nombre: new FormControl(null, Validators.required),
    apellido1: new FormControl(null, Validators.required),
    apellido2: new FormControl
  });

  regex = /^[A-Za-z]{2}\/[A-Za-z]{2}\//;

  override getValue() {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    this.specificData.codeProvincia = this.cpmComponent.selectedProvincia?.codigo;
    this.specificData.codeMunicipio = this.cpmComponent.selectedMunicipio?.codigo;
    this.specificData.nombre = this.personForm.controls['nombre'].value;
    this.specificData.apellido1 = this.personForm.controls['apellido1'].value;
    this.specificData.apellido2 = this.personForm.controls['apellido2'].value;
    this.specificData.identifier = this.personForm.controls['identifier'].value;
    this.specificData.birthDate = this.personForm.controls['birthDate'].value;
    return this.specificData;
  }

  override isValid(): boolean {
    if ((this.cpmComponent && this.cpmComponent.isValid()) && this.personForm.valid) return true;
    return false;
  }
}
