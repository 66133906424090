import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StateService } from '../_service/state.service';
import { environment } from 'src/environments/environment';
import { Utils } from '../_common/utils';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { HttpClient } from '@angular/common/http';

const OOTS_API_URL = environment.api_url + "/oots";

@Component({
  selector: 'app-projectathon-requester',
  templateUrl: './projectathon-requester.component.html',
  styleUrls: ['./projectathon-requester.component.css']
})
export class ProjectathonRequesterComponent implements OnInit {

  private eventsSubscription = new Subscription();
  requirementsDataset: RequirementData[];
  selectedRequirement: RequirementData;
  dob: string = "1999-03-01";
  eidasID: string = "DE/DE/123456789";
  name: string = "Jack";
  familyName: string = "Smith";
  lang = "";
  isPreview = true;
  validationMessage = "";
  redirecting = false;

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private jwtUtilsService: JwtUtilsService) {

      this.eventsSubscription.add(
        this.stateService.getLanguage.subscribe(lang => {
          if (this.lang != lang) {
            this.lang = lang;
          }
        }));
  }

  ngOnInit(): void {
   this.clearPreviousData();
   this.loadRequirements();
  }

  createOOTSRequest(): string {
    const request = this.ootsRequestXML
    .replaceAll("#REQUIREMENT_ID#", this.selectedRequirement.requirement)
    .replaceAll("#NAME#", this.name)
    .replaceAll("#FAMILY_NAME#", this.familyName)
    .replaceAll("#DOB#", this.dob)
    .replaceAll("#EIDAS_ID#", this.eidasID)
    .replaceAll("#EVIDENDE_REQUESTED#", this.selectedRequirement.description);

    let formatted = '', indent= '';
    const tab = '\t';
    request.split(/>\s*</).forEach((node: string) => {
        if (node.match( /^\/\w/ )) indent = indent.substring(tab.length);
        formatted += indent + '<' + node + '>\r\n';
        if (node.match( /^<?\w[^>]*[^/]$/ )) indent += tab;
    });
    const resultXml = formatted.substring(1, formatted.length-3);
    return resultXml;
  }

  getDate(): string {
    const currentDate = Date.now();
    return this.datePipe.transform(currentDate, 'yyyy-MM-ddThh:mm:ss') ?? "";
  }

  translateText(className: string, key: string) {
    const translateKey = className + '.' + key;
    return this.translate.instant(translateKey);
  }

  redirectToIntermediaryApp(ootsRequestXML: string) {
    this.stateService.setPreview(JSON.stringify(this.isPreview));
    this.jwtUtilsService.saveRedirect('true');
    this.postRedirect(OOTS_API_URL + "/request", ootsRequestXML);
  }

  postRedirect(path: string, xml: any): void {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = path;
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'data';
    hiddenField.value = xml;
    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
  }

  continue() {
    if(!this.isValidDate()) this.validationMessage = "Invalid date of birth.";
    if(!this.isValidEidas()) this.validationMessage = "Invalid eIDAS identifier.";
    if(this.isValidForm()) {
      this.redirecting = true;
      const ootsRequest = this.createOOTSRequest();
      this.redirectToIntermediaryApp(ootsRequest);
    }
  }

  isValidUserData() : boolean {
    return this.isValidDate() && this.isValidEidas() && Utils.hasValue(this.name) && Utils.hasValue(this.familyName);
  }

  isValidForm() : boolean {
    return this.isValidUserData() && Utils.hasValue(this.selectedRequirement) && this.isValidRequirement();
  }

  isValidRequirement() : boolean{
    const regex = /https:\/\/sr\.oots\.tech\.ec\.europa\.eu\/requirements\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/;

    return Utils.hasValue(this.selectedRequirement.requirement) && regex.test(this.selectedRequirement.requirement);
  }

  isValidDate() {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    return datePattern.test(this.dob);
  }

  isValidEidas() {
    const idPattern = /^[A-Za-z]{2}\/[A-Za-z]{2}\/[A-Za-z0-9-]+$/;
    return idPattern.test(this.eidasID);
  }

  clearMessage() {
    this.validationMessage = "";
  }

  clearPreviousData(){
    if(this.jwtUtilsService.checkCookie('token')){this.jwtUtilsService.setExpired('token')}
    if(this.jwtUtilsService.checkCookie('oots-request-status')){this.jwtUtilsService.setExpired('oots-request-status')}
    if(this.jwtUtilsService.checkCookie('currentUser')){this.jwtUtilsService.setExpired('currentUser')}
    if(this.jwtUtilsService.checkCookie('errorType')){this.jwtUtilsService.setExpired('errorType')}
    if(this.jwtUtilsService.checkCookie('isPreview')){this.jwtUtilsService.setExpired('isPreview')}
    window.localStorage.removeItem('uuid')
  }

  loadRequirements() {
    this.http.get<any[]>('mocks/requester-requirements.json').subscribe(
      data => {
        this.requirementsDataset = data;
      },
      error => console.error('Error al cargar el JSON:', error)
    );
  }

  ootsRequestXML = `<OOTSPeticion xmlns="http://www.example.org/oots/">
  <IDPeticionBloque>
    <PeticionUUID>` + uuid() + `</PeticionUUID>
    <PeticionFechaHora>` + this.getDate() + `</PeticionFechaHora>
    <ProcedimientoCodigoDIR3>E05078801</ProcedimientoCodigoDIR3>
    <ProcedimientoCategoriaPDU>T2</ProcedimientoCategoriaPDU>
    <ProcedimientoCodigoSIA>2300114</ProcedimientoCodigoSIA>
    <NumReferenciaPostSolicitud>` + uuid() + `</NumReferenciaPostSolicitud>
    <DatosIdentidadUsuario>
      <IdentidadPersonaEidas>
        <Nombre>#NAME#</Nombre>
        <Apellido>#FAMILY_NAME#</Apellido>
        <FechaNacimiento>#DOB#</FechaNacimiento>
        <IdentEidas>#EIDAS_ID#</IdentEidas>
        <NivelSeguridad>high</NivelSeguridad>
      </IdentidadPersonaEidas>
      <IdentidadPersonaNacional>
        <Identificador>#EIDAS_ID#</Identificador>
        <TipoIdentificador>NIF</TipoIdentificador>
        <Nombre>#NAME#</Nombre>
        <Apellido1>#FAMILY_NAME#</Apellido1>
        <Apellido2>#FAMILY_NAME#</Apellido2>
        <TelefonoMovil>666555444</TelefonoMovil>
        <CorreoElectronico>test@sgad.es</CorreoElectronico>
      </IdentidadPersonaNacional>
    </DatosIdentidadUsuario>
  </IDPeticionBloque>
  <DatosRetornoBloque>
    <URLRetornoUsuario>https://oots.simplegob.com/intermediaryapp/test/share-with-sede</URLRetornoUsuario>
    <MinutosTimeOutSesion>50</MinutosTimeOutSesion>
  </DatosRetornoBloque>
  <RequisitosOOTSBloque>
    <IdRequisitoPruebasOOTS>#REQUIREMENT_ID#</IdRequisitoPruebasOOTS>
    <TituloCondicionAAcreditar>#EVIDENDE_REQUESTED#</TituloCondicionAAcreditar>
    <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
    <ConTraduccionJurada>true</ConTraduccionJurada>
  </RequisitosOOTSBloque>
  </OOTSPeticion>`;

}

export interface RequirementData {
  description: string;
  requirement: string;
}

