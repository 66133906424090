export class AccessPointQueryParams {
    ootsRequestId: string;
    evidenceRequirementId?: string;
    evidenceUUID?: string;
    evidenceIdentifier: string;
    evidenceTitle: string;
    evidenceRequesterId?: string;
    evidenceProviderId?: string;
    accessServiceIdentifier?: string;
	publisherName?: string;
    evidenceClassificationId?: string;
    countryCode?: string;
    payload?: string;
    skipPreview: boolean;
    specificNaturalPerson: SpecificNaturalPerson;
    //ADDITIONAL FIELDS FOR DSD
    jurisdictionAdminL2?: string;
    jurisdictionAdminL3?: string;
    jurisdictionContextId?: string;
    evidenceTypeId?: string;
    requirementNumber?: number;
    requestType: string;
}

export class SpecificNaturalPerson {
    givenName: string;
    familyName: string;
    userIdentifier: string;
    birthDate: string;
  }