import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EidasUser } from '../_model/eidasUser';
import { OotsRequest } from '../_model/oots-request';
import { CookieService } from 'ngx-cookie-service';
import { RequirementInfo } from '../_model/requirement-info';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private cookieService: CookieService) {
  }

  public respondSede = false;

  private currentUserNameStore = new BehaviorSubject<string>('');
  private currentUserStore: BehaviorSubject<EidasUser> = new BehaviorSubject<EidasUser>(
    {
      dateOfBirth: new Date(2000,1,1),
      familyName: '',
      firstName: '',
      personIdentifier: '',
      schemeId: '',
      loa: ''
    });

  public currentUserName = this.currentUserNameStore.asObservable();
  public currentUser = this.currentUserStore.asObservable();

  private ootsRequestSubject: BehaviorSubject<OotsRequest> = new BehaviorSubject<OotsRequest>(new OotsRequest());
  public ootsRequestObserver = this.ootsRequestSubject.asObservable();

  private ootsPreviewSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ootsPreviewObserver = this.ootsPreviewSubject.asObservable();

  private agreementSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public agreementObserver = this.agreementSubject.asObservable();

  private actionSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public actionObserver = this.actionSubject.asObservable();

  private requirementStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public requirementStatusObserver = this.requirementStatusSubject.asObservable();

  private continuationTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public continuationTokenObserver = this.continuationTokenSubject.asObservable();

  private language: BehaviorSubject<string> = new BehaviorSubject<string>("es");
  getLanguage = this.language.asObservable();

  private ootsRequestModelSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public ootsRequestModelObserver = this.ootsRequestModelSubject.asObservable();

  public validities:string[] = [];

  setLanguage(language: string){
    this.language.next(language)
  }
  
  set action(action: string) {
    this.actionSubject.next(action);
  }

  set ootsRequest(ootsRequest: OotsRequest) {
    this.ootsRequestSubject.next(ootsRequest);
  }

  get ootsRequest() {
    return this.ootsRequestSubject.getValue();
  }

  set ootsPreview(ootsPreview: boolean) {
    this.ootsPreviewSubject.next(ootsPreview);
  }

  get ootsPreview() {
    return this.ootsPreviewSubject.getValue();
  }

  set agreementAccepted(accepted: boolean) {
    this.agreementSubject.next(accepted);
  }

  setCurrentUserName(userName: string): void {
    this.currentUserNameStore.next(userName);
  }

  setCurrentUser(currentUser: EidasUser): void {
    this.currentUserStore.next(currentUser);
  }

  public getCurrentUser(): EidasUser {
    return this.currentUserStore.getValue();
  }

  set continuationToken(token: string) {
    this.cookieService.set("continuationToken", token, { expires: new Date(new Date().getTime() +  1000 * 60 * 60), path: '/'});
  }

  get continuationToken(): string {
    return this.cookieService.get("continuationToken");
  }

  public clearContinuationToken() {
    this.cookieService.delete("continuationToken", '/');
  }
  
  public retrieveRequestStatus(): RequirementInfo[] {
    let parsedObject: RequirementInfo[] = [];
    const cookieValue = this.cookieService.get("oots-request-status") || null;
    if (cookieValue !== null) {
      parsedObject = JSON.parse(cookieValue);
    }
    return parsedObject;
  }

  public saveRequestStatus(evidenceStatusMap: RequirementInfo[]): void {
    //const evidenceStatusObject = Object.fromEntries(evidenceStatusMap);
    const evidenceStatus = JSON.stringify(evidenceStatusMap);
    this.cookieService.set("oots-request-status", evidenceStatus, { expires: new Date(new Date().getTime() +  1000 * 60 * 60), path: '/'});
  }

  public clearRequestStatus(): void {
    this.cookieService.delete("oots-request-status", '/');
  }

  public setPreview(isPreview: string) {
    this.cookieService.set("isPreview", isPreview, {path: '/'});
  }

  public isPreview(): string {
    return JSON.parse(this.cookieService.get("isPreview"));
  }

  public requirementStatusChanged() {
    this.requirementStatusSubject.next('');
  }

  public ootsRequestModelChanged(uuid: string) {
    this.ootsRequestModelSubject.next(uuid);
  }

}
