import { Component, OnInit, ViewChild } from '@angular/core';
import { SpecificDataComponent } from '../../specific-data.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Select2Module } from 'ng-select2-component';
import { TranslateModule } from '@ngx-translate/core';
import { EidasUser } from 'src/app/_model/eidasUser';
import { JwtUtilsService } from 'src/app/_service/jwt-utils.service';
import { CpmComponent } from '../../cpm/cpm.component';

@Component({
  selector: 'app-discapacidad-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, Select2Module, TranslateModule, CpmComponent],
  templateUrl: './discapacidad-form.component.html',
  styleUrl: './discapacidad-form.component.css'
})

export class DiscapacidadFormComponent extends SpecificDataComponent implements OnInit{

  @ViewChild(CpmComponent) cpmComponent!: CpmComponent;
  
  constructor(private jwtUtils: JwtUtilsService){
    super();
  }

  discapacidadData : DiscapacidadData;
  jsonData: any;
  birthDate: string | null = null;
  identifier: string | null | undefined = null;
  identityType = "DNI";
  supportNumber: string | null;
  eidasUser: EidasUser;
  personForm: FormGroup;

  regex = /^[A-Za-z]{2}\/[A-Za-z]{2}\//;

  ngOnInit(): void {
    this.discapacidadData = new DiscapacidadData;
    this.personForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      apellido1: new FormControl(null, Validators.required),
      apellido2: new FormControl
    })
    if(this.autofill){
      this.eidasUser = this.jwtUtils.getCurrentUser();
      this.discapacidadData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0];
      this.discapacidadData.identifier = this.eidasUser.personIdentifier.split(this.regex).pop();
      this.discapacidadData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0];
      
      this.personForm.controls['nombre'].setValue(this.eidasUser.firstName);
      this.personForm.controls['nombre'].disable();
      const apellidos: string[] = this.eidasUser.familyName.split(/(?<=^\S+)\s/);
      this.personForm.controls["apellido1"].setValue(apellidos[0]);
      this.personForm.controls['apellido1'].disable();
      if(apellidos[1]) {
        this.personForm.controls["apellido2"].setValue(apellidos[1]);
        this.personForm.controls['apellido2'].disable();
      }
    }
  }

  override getValue(): any {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    return this.specificData;
  }

  override isValid(): boolean {
    /*
      if(this.discapacidadData){
        if(this.discapacidadData.identifier == null) return false;
        if((this.discapacidadData.supportNumber == null || this.discapacidadData.supportNumber == "") && this.discapacidadData.birthDate == null) return false
        if(!this.cpmComponent.isValid()) return false;

        if(this.autofill){
          if(this.eidasUser){
            if(this.personForm.controls["nombre"].value === this.eidasUser.firstName && this.personForm.controls["apellido1"].value + " " + this.personForm.controls["apellido2"].value === this.eidasUser.familyName){
              if(this.personForm.valid) return false;
            }
            else{
              return false;
            }
          }
        }
        else{
          if(this.personForm.valid) return false;
        }
        return true;
      }
      else return false;*/
      if(this.cpmComponent && !this.cpmComponent.isValid()) return false;
      return true;
  }

  onNombreChange(event: any){
    this.discapacidadData.nombre = event.target.innerText;
    this.emitValueChange(this.discapacidadData);
  }

  onApellido1Change(event: any){
    this.discapacidadData.apellido1 = event.target.innerText;
    this.emitValueChange(this.discapacidadData);
  }

  onApellido2Change(event: any){
    this.discapacidadData.apellido2 = event.target.innerText;
    this.emitValueChange(this.discapacidadData);
  }

  identifierTypeChange(){
    if(this.identityType == "DNI"){
      this.discapacidadData.identifier = this.eidasUser.personIdentifier;
      this.discapacidadData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0]
    }
    else if(this.identityType == "NIE"){
      this.discapacidadData.identifier = this.eidasUser.personIdentifier;
      this.discapacidadData.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0]
    }
    this.emitValueChange(this.discapacidadData.identifier);
  }

}

export class DiscapacidadData{
  codeComunidad: string | null = null;
  codeProvincia: string | null = null;
  codeMunicipio: string | null = null;
  nombre: string | null = null;
  apellido1: string | null = null;
  apellido2: string | null = null;
  birthDate: string | null = null;
  identifier: string | null | undefined = null;
  identityType: string | null = "DNI";
  supportNumber: string | null = null;
  spanishCitizen: boolean | null = null;
}
