<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>OOTS Intermediary App</title>
</head>

<body style="height: 100%;">

  <!-- Barra de navegación -->

  <header class="header" style="background-color: white;">

    <!-- Selector de idioma y versión de la aplicación -->
<!--     <div class="language-selector-container">
      <select class="form-select" [(ngModel)]="selectedLanguage" #mySelect
        (change)="translateLanguageTo(mySelect.value)">
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>
      <app-version [tdd]="!isPublic" class="tdd"></app-version>
    </div> -->
    <div class="header-container">
      <!-- Selector de idioma y versión de la aplicación -->
      <div class="language-selector-container">
        <select class="form-select" [(ngModel)]="selectedLanguage" #mySelect
          (change)="translateLanguageTo(mySelect.value)">
          <option value="en">English</option>
          <option value="es">Español</option>
        </select>
        <app-version [tdd]="!isPublic" class="tdd"></app-version>
      </div>


      <!-- Logos e información a la izquierda -->
      <div class="logos-container">
      <div class="logo-images">
        <img style="padding-bottom: 2px" height="42" alt="Logo" src="assets/logo-gobierno.svg" />
        <img style="margin-top: 2px" height="48" alt="Logo" src="assets/ye.png" />
      </div>
        <div class="vl"></div>
        <div class="oots-version">
          <span class="fs-2 fw-bold mt-1">OOTS</span>
          <span class="fs-5 text-muted mb-1 mt-2 ml-1 xl:mt-0 d-flex">
            {{'HOME.GATEWAY' | translate}}
            <app-version [app]="true"></app-version>
          </span>

        </div>

      </div>




    </div>

  </header>

  <main>

    <!-- Breadcrum with timer and buttons -->
    <app-header></app-header>

    <!-- Contenido principal -->
    <section class="content">
      <div class="root-container" *ngIf="innerHtml === ''">
        <router-outlet></router-outlet>
      </div>
      <div *ngIf="innerHtml !== ''" class="root-container">
        <div class="d-flex justify-content-end">
          <button (click)="innerHtml=''">X</button>
        </div>
        <div [innerHtml]="innerHtml">
        </div>
      </div>
    </section>
  </main>

  <!-- Pie de página -->
  <footer class="footer" style="display:flex; flex-direction: column; background-color: rgba(247, 247, 248, 1);">
    <div class="footer-container">
      <div class="footer-links">
        <span>
          <a tabindex="0" (click)="openComponent('/aviso_legal')" href="javascript:void(0);"
            style="color: white"><u>{{'FOOTER.LEGAL_WARNING' | translate}}</u></a>
        </span>
        <span>
          <a tabindex="0" (click)="openComponent('/politica_privacidad')" href="javascript:void(0);"
            style="color: white"><u>{{'FOOTER.PRIVACY_POLICY' | translate}}</u></a>
        </span>
        <span>
          <a tabindex="0" (click)="openComponent('/politica_cookies')" href="javascript:void(0);"
            style="color: white"><u>{{'FOOTER.COOKIES_POLICY' | translate}}</u></a>
        </span>
      </div>
      <div class="footer-images">
        <img src="assets/europa.svg" alt="" style="padding: 24px 16px; margin:auto;">
        <img src="assets/plan_recuperacion.svg" alt="" style="padding: 24px 16px; margin:auto;">
        <img src="assets/espana_digital.svg" alt="" style="padding: 24px 16px; margin:auto;">
      </div>
    </div>

  </footer>

  <button id="open-server-session-timeout-dialog" (click)="openServerSessionTimeoutDialog()"
    style="display: none;">server-session-timeout</button>
  <button id="open-client-session-timeout-dialog" (click)="openClientSessionTimeoutDialog()"
    style="display: none;">client-session-timeout</button>

  <!-- Incluir los enlaces a los archivos JavaScript de Bootstrap -->
  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js"></script>
  <!-- Incluir los enlaces a los archivos CSS de Bootstrap -->
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
</body>

</html>