export class PidRequest {
    session: string;
    specificData: SpecificData;
}

export class SpecificData {
    service: string;
    fechaHechoRegistral: string | undefined;
    codeComunidad: string | undefined;
    codeProvincia: string | undefined;
    codeMunicipio: string | undefined;
    identityType: string | undefined;
    identifier: string | undefined;
    birthDate: string | undefined;
    spanishCitizen: boolean;
}