import { Component, OnInit, ViewChild } from '@angular/core';
import { SpecificDataComponent } from '../../specific-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select2Module } from 'ng-select2-component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CpmComponent } from '../../cpm/cpm.component';
import { Utils } from '../../utils';

@Component({
  selector: 'app-residencia-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, Select2Module, TranslateModule, CommonModule, CpmComponent],
  templateUrl: './residencia-form.component.html',
  styleUrl: './residencia-form.component.css'
})

export class ResidenciaFormComponent extends SpecificDataComponent implements OnInit {

  @ViewChild(CpmComponent) cpmComponent!: CpmComponent;
  
  constructor() {
    super();
  }
  ngOnInit(): void {
    this.specificData.spanishCitizen = true;
  }

  override getValue() {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    this.specificData.codeProvincia = this.cpmComponent.selectedProvincia?.codigo;
    this.specificData.codeMunicipio = this.cpmComponent.selectedMunicipio?.codigo;
    return this.specificData;
  }

  override isValid(): boolean {
    if (!this.specificData || !Utils.hasValue(this.specificData.spanishCitizen)) return false;
    if(this.cpmComponent && !this.cpmComponent.isValid()) return false;
    return true;
  }

  onChange() {
    this.cpmComponent.reset();
  }

}