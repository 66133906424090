import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtUtilsService } from '../_service/jwt-utils.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private jwtUtil: JwtUtilsService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.jwtUtil.getTokenFromSessionStorage();
    const headers = req.headers.append('Authorization', `Bearer ${token}`);
    return next.handle(req.clone({ headers }));
  }
}