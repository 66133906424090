import { Component, OnInit, ViewChild } from '@angular/core';
import { SpecificDataComponent } from '../../specific-data.component';
import { Select2Module } from 'ng-select2-component';
import { TranslateModule } from '@ngx-translate/core';
import { CpmComponent } from '../../cpm/cpm.component';

@Component({
  selector: 'app-nacimiento-form',
  standalone: true,
  imports: [Select2Module, TranslateModule, CpmComponent],
  templateUrl: './nacimiento-form.component.html',
  styleUrl: './nacimiento-form.component.css'
})

export class NacimientoFormComponent extends SpecificDataComponent {

  @ViewChild(CpmComponent) cpmComponent!: CpmComponent;


  override getValue() {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    this.specificData.codeProvincia = this.cpmComponent.selectedProvincia?.codigo;
    this.specificData.codeMunicipio = this.cpmComponent.selectedMunicipio?.codigo;
    return this.specificData;
  }

  override isValid(): boolean {
    if (this.specificData.codeComunidad === null || this.specificData.codeProvincia === null || this.specificData.codeMunicipio === null) return false;
    return true;
  }
}
