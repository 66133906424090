import { AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import intlTelInput from 'intl-tel-input';
import { ModalDialogService } from 'src/app/_service/modal-dialog.service';
import { SessionTimerService } from 'src/app/_service/session-timer.service';
import { CountryAsset } from 'src/app/contants';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.css']
})
export class ModalDialogComponent implements AfterViewInit {

  selectedContactType = 'email';
  contactValue: string;
  email: string;
  phoneNumber: string;
  placeholder = 'your@email.com';
  code: string[] = Array(4).fill('');
  codeInputs: ElementRef[] = [];
  time = 0;
  @ViewChild('input0') input0!: ElementRef;
  @ViewChild('input1') input1!: ElementRef;
  @ViewChild('input2') input2!: ElementRef;
  @ViewChild('input3') input3!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: ModalDialogService,
    private translate: TranslateService,
    private sessionTimerService: SessionTimerService) { }

  @HostListener('document:paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    this.codeInputs.push(this.input0, this.input1, this.input2, this.input3);
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData?.getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      this.codeInputs[i].nativeElement.value = pastedText[i];
      this.codeInputs[i].nativeElement.blur();
    }
  }

  ngAfterViewInit(): void {
    if (this.data.type == 'server-session-timeout'){
      this.sessionTimerService.remainingTime$.subscribe((remaining) => {
        this.time = remaining;
      });
      this.countDown();
    }
    if (this.input0 && this.input1 && this.input2 && this.input3) {
      this.codeInputs.push(this.input0.nativeElement, this.input1.nativeElement, this.input2.nativeElement, this.input3.nativeElement);
    }
    const input = document.querySelector("#phone-number");
    if (input != null) intlTelInput(input, { separateDialCode: true, utilsScript: "path/to/utils.js" });
  }

  onCancel() {
    const result = new ModalResult();
    result.action = 'cancel';
    this.dialogRef.close(result);
  }

  onAccept() {
    const result = new ModalResult();
    result.action = 'accept';
    switch (this.data.type) {

      case ("contact"): {
        result.contactType = this.selectedContactType;
        result.email = this.selectedContactType === 'email' ? this.contactValue : '';
        result.phoneNumber = this.selectedContactType === 'phoneNumber' ? this.contactValue : '';
      }
      break;

      case ("secCode"): {
        result.type = this.data.type;
        result.email = this.contactValue;
        result.emailValid = true;
        if (!this.isValidEmail(this.contactValue)) {
          result.emailValid = false;
          result.errorMessage = this.translate.instant('LOCATE.INVALID_EMAIL_ERROR');
        }
        this.dialogService.nextStep = result;
        this.resetInputCodes();
      }
      break;

      case ("confirmCode"): {
        result.type = this.data.type;
        result.code = this.input0.nativeElement.value + this.input1.nativeElement.value + this.input2.nativeElement.value + this.input3.nativeElement.value;
        this.dialogService.nextStep = result;
      }
      break;

      case ("saveSuccess"):
      case ("saveError"): {
        result.type = this.data.type;
        this.dialogService.nextStep = result;
      }
      break;

      case ("cancel"): {
        result.type = this.data.type;
        this.dialogService.nextStep = result;
      }
      break;

      case("client-session-timeout"): {
        result.type = this.data.type;
        this.dialogService.nextStep = result;
      }
      break;

    }

    this.dialogRef.close(result);
  }

  isValidEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email !== undefined && email !== null && email.trim() !== '' && regex.test(email);
  }

  onRadioButtonChange(event: any) {
    if (this.selectedContactType === 'email') this.placeholder = 'your@email.com';
    else {
      this.placeholder = '+34 666555444';
    }
  }

  onInputChange(event: any) {
    //TODO validate input
  }

  onEmailFocus(event: any) {
    this.data.errorMessage = undefined;
  }

  moveNext(index: number, event: any): void {
    this.code[index] = event.target.value;
    if (event.target.value && index < 3) {
      this.focusNextInput(index + 1);
    }
  }
  
  clickAccept(){
    document.getElementById("accept-button")?.click()
  }

  focusNextInput(index: number): void {
    switch (index) {
      case 1:
        this.input1.nativeElement.focus();
        break;
      case 2:
        this.input2.nativeElement.focus();
        break;
      case 3:
        this.input3.nativeElement.focus();
        break;
      default:
        break;
    }
  }

  countDown(){
    const interval = setInterval(() => {
      const remainingTime = this.time;
      if (remainingTime > 0) {
        this.time = this.time - 1
      } else {
        clearInterval(interval);
        this.clickAccept();
      }
    }, 1000);
  }

  resetInputCodes() {
    this.input0.nativeElement.value = "";
    this.input1.nativeElement.value = "";
    this.input2.nativeElement.value = "";
    this.input3.nativeElement.value = "";
  }
}

export interface ModalContent {
  title: string;
  message: string;
  toAsset: CountryAsset;
  email: string;
  phoneNumber: string;
  type: string;
  isLoading: boolean;
}

export class ModalResult {
  type: string;
  action: string;
  contactType: string;
  email: string;
  phoneNumber: string;
  code: string;
  emailValid: boolean;
  errorMessage: string;
}

