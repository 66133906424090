<div class="form-gr">
    <form [formGroup]="cpmForm" class="m-0">
        <div class="cpm-container">
            <div class="select-group" [ngClass]="{'hidden': comunidad === false }">
                <label for="comunidad">{{ 'PREVIEW_SPACE.COMMUNITY' | translate }}</label>
                <select2 id="comunidad" [data]="comunidadesData" class="select2" (update)="onComunidadChange()"
                    listPosition="above" formControlName="comunidad">
                </select2>
            </div>

            <div class="select-group" [ngClass]="{'hidden': provincia === false }">
                <label for="provincia">{{ 'PREVIEW_SPACE.PROVINCE' | translate }}</label>
                <select2 id="provincia" [data]="provinciasData" class="select2" (update)="onProvinciaChange()"
                    listPosition="above" formControlName="provincia">
                </select2>
            </div>

            <div class="select-group" [ngClass]="{'hidden': municipio === false }">
                <label for="municipio">{{ 'PREVIEW_SPACE.MUNICIPALITY' | translate }}</label>
                <select2 id="municipio" [data]="municipiosData" class="select2" (update)="onMunicipioChange()"
                    listPosition="above" formControlName="municipio">
                </select2>
            </div>
        </div>
    </form>
</div>