<div class="root-container">
    <div *ngIf="oostRequestActive()" class="breadcrumb-timer-container">

        <div *ngIf="showBreadcrum()" class="breadcrumb-container">
            <ul class="breadcrumbs-list">
                <li class="breadcrumb"><span [ngClass]="step === 1 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">1.
                        &nbsp; {{ 'HEADER.SESSION_INIT' | translate }} &nbsp;</span></li>
                <li class="breadcrumb">
                    <span [ngClass]="step === 2 ? 'breadcrumb breadcrumb_selected ' : 'breadcrumb hidden'">2.
                        {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</span>
                    <span [ngClass]="step === 1 ? 'breadcrumb ' : 'breadcrumb hidden'">2.
                        {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</span>
                    <span [ngClass]="step === 4 ? 'breadcrumb ' : 'breadcrumb hidden'">2.
                        {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</span>


                    <span [ngClass]="step === 3 ? 'breadcrumb breadcrumb breadcrumb_return' : 'breadcrumb hidden'">
                        <u><button class="head-button" (click)="initializeComponent(2)">2.
                                {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</button></u></span>


                </li>
                <li class="breadcrumb"><span
                        [ngClass]="step === 3 ? 'breadcrumb breadcrumb_selected' : (step === 4 && !ootsRequest.inSession) ? 'breadcrumb hidden' : 'breadcrumb'">3.
                        {{ 'HEADER.LOCATE_DOCUMENTS' | translate }} &nbsp;</span>
                    <span
                        [ngClass]="(step === 4 && !ootsRequest.inSession) ? 'breadcrumb breadcrumb breadcrumb_return' : 'breadcrumb hidden'">
                        <u><button class="head-button" (click)="initializeComponent(3)">3.
                                {{ 'HEADER.LOCATE_DOCUMENTS' | translate }} &nbsp;</button></u></span>
                </li>
                <li class="breadcrumb"><span [ngClass]="step === 4 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">4.
                        {{ 'HEADER.FINISH_PROCESS' | translate }} &nbsp;</span></li>
            </ul>
        </div>


        <div *ngIf="showTimerAndButtons()" class="timer-and-actions">
            <div *ngIf="ootsRequest.inSession === true; then inSession else outOfSession"></div>
            <ng-template #inSession>
                <div *ngIf="showTimer()" class="timer">
                    <app-session-timer [timerType]="'server'"></app-session-timer>
                </div>
            </ng-template>

            <ng-template #outOfSession>
                <button class="action" (click)="save()">{{ 'HEADER.SAVE' | translate }}</button>
            </ng-template>

            <button class="action" (click)="cancel()">{{ 'HEADER.CANCEL' | translate }}</button>
        </div>


    </div>
    <div *ngIf="oostPreview()" class="breadcrumb-timer-container">
        
            <div *ngIf="showBreadcrum()" class="breadcrumb-container">
                <ul class="breadcrumbs-list">
                    <li class="breadcrumb"><span
                            [ngClass]="step === 1 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">1.
                            &nbsp; {{ 'HEADER.LOCATE_PROOF' | translate }} &nbsp;</span></li>
                    <li class="breadcrumb">
                        <span [ngClass]="step === 2 ? 'breadcrumb breadcrumb_selected ' : 'breadcrumb'">2.
                            {{ 'HEADER.DISPLAY_PROOF' | translate }} &nbsp;</span>

                    </li>
                    <li class="breadcrumb"><span
                            [ngClass]="step === 3 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">3.
                            {{ 'HEADER.CONFIRM_DELIVERY' | translate }} &nbsp;</span>
                    </li>
                    <li class="breadcrumb"><span
                            [ngClass]="step === 4 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">4.
                            {{ 'HEADER.END_AND_RETURN' | translate }} &nbsp;</span></li>
                </ul>
            </div>
        
        <div class="timer-and-actions">
            <div *ngIf="showTimer()" class="timer">
                <app-session-timer [timerType]="'client'"></app-session-timer>
            </div>
        </div>

    </div>
</div>