import { Component, OnDestroy } from '@angular/core';
import { OotsRequest } from '../_model/oots-request';
import { Router } from '@angular/router';
import { OotsService } from '../_service/oots.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AccessPointQueryParams } from '../_model/access-point-query-params';
import { AccessPointService } from '../_service/access-point.service';
import { Evidence, EvidenceSave } from '../_model/evidence';
import { COUNTRIES, CountryAsset } from '../contants';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../_service/state.service';
import { Subscription } from 'rxjs';
import { ModalDialogService } from '../_service/modal-dialog.service';
import { ModalDialogComponent, ModalResult } from '../_common/modal-dialog/modal-dialog.component';
import { SessionTimerService } from '../_service/session-timer.service';
import { Action } from '../_model/action-enum';
import { BreadcrumbService } from '../_service/breadcrumb.service';
import { DocumentCounterService } from '../_service/document-counter.service';
import { EvidenceStatus } from '../_model/evidence-status';
import { Utils } from '../_common/utils';
import { EvidenceProvider } from '../_model/evidence-provider';
import { MultiLanguageStringPipe } from '../_model/multi-language-string';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { RequirementInfo } from '../_model/requirement-info';
import { v4 as uuid } from 'uuid';

const REDIRECT_BACK_URL = environment.redirect_back_url;

@Component({
  selector: 'app-locate-and-request-evidence',
  templateUrl: './locate-and-request-evidence.component.html',
  styleUrls: ['./locate-and-request-evidence.component.css']
})
export class LocateAndRequestEvidenceComponent implements OnDestroy {

  private eventsSubscription = new Subscription();
  ootsRequest: OotsRequest;
  requirementList: string[] = [];
  requestFound?: boolean;
  isModalVisible = false;
  previewSpaceUrl = "";
  evidenceUUID: string;
  countryCodeSelected: string;
  countryAsset: CountryAsset;
  procedureDescription: string;
  selectedEvidence: Evidence;
  action: string;
  dialogConfig: MatDialogConfig;
  step: number;
  evidencesValidity: string;
  receivedDocuments: number;
  totalDocuments: number;
  secCodesTimes = 0;
  storedEvidenceStatus: RequirementInfo[];
  isStep3Available: boolean;
  lang: string;
  redirecting = false;

  constructor(
    private ootsService: OotsService,
    private router: Router,
    private apService: AccessPointService,
    public translate: TranslateService,
    private stateService: StateService,
    private dialogService: ModalDialogService,
    private sessionTimerService: SessionTimerService,
    private breadcrumbService: BreadcrumbService,
    private documentCounterService: DocumentCounterService,
    private multiLanguagePipe: MultiLanguageStringPipe,
    private jwtUtilsService: JwtUtilsService,
  ) {

    this.ootsRequest = history.state;

    this.eventsSubscription.add(
      this.stateService.getLanguage.subscribe(lang => {
        if (this.lang != lang) this.lang = lang;
      }));

    this.eventsSubscription.add(
      this.stateService.ootsRequestObserver.subscribe((ootsRequest) => {
        this.ootsRequest = ootsRequest;
        this.loadOOTSRequest();
      }));

    this.eventsSubscription.add(
      this.stateService.actionObserver.subscribe((action) => {
        if (action === Action.CANCEL) this.openCancelProcessDialog();
        else this.saveOrShareOutOfSession(action);
      }));

    this.eventsSubscription.add(
      this.documentCounterService.receivedDocumentsObserver.subscribe(receivedDocuments => this.receivedDocuments = receivedDocuments)
    );

    this.eventsSubscription.add(
      this.documentCounterService.totalDocumentsObserver.subscribe(totalDocuments => this.totalDocuments = totalDocuments)
    );

    this.eventsSubscription.add(
      this.breadcrumbService.getStep.subscribe(step => this.step = step));

    this.eventsSubscription.add(
      this.stateService.requirementStatusObserver.subscribe(status => this.validateRequirementsStatus())
    );
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  loadOOTSRequest() {
    if (this.ootsRequest != null) {
      this.requestFound = true;
      switch (this.ootsRequest.procedureCode) {
        case 'T1': this.procedureDescription = "Applying for a tertiary education study financing, such as study grants and loans from a public body or institution"; break;
        case 'T2': this.procedureDescription = "Submitting an initial application for admission to public tertiary education institution"; break;
        case 'T3': this.procedureDescription = "Requesting academic recognition of diplomas, certificates or other proof of studies or courses"; break;
        case 'V1': this.procedureDescription = "Registering a change of address"; break;
        case 'R1': this.procedureDescription = "Requesting proof of registration of birth"; break;
      }
      if (this.ootsRequest.requirements) {
        this.ootsRequest.requirements.forEach(requirement => {
          this.requirementList.push(requirement.title);
        });
      }
      this.checkForStoredEvidenceStatus();
    }
  }

  manageNotFound() {
    this.requestFound = false;
    const element = document.getElementById('notfound');
    if (element) element.classList.remove('hidden');
  }

  shareEvidences(action: string, email: string, phoneNumer: string) {
    const evidencesSave: EvidenceSave[] = [];
  
    this.ootsRequest.requirements.forEach(requirement => {
      requirement.evidences.forEach(evidence => {
        const evTitle = this.multiLanguagePipe.transform(evidence.evidenceTitle, this.lang);
        let evidenceTitle:string 
        if(evTitle){
          evidenceTitle = evTitle;
        }
        else{
          evidenceTitle = evidence.evidenceTitle[0].value
        }
        let evidenceUUID = evidence.evidenceUUID
        if(!evidenceUUID)  evidenceUUID = "" + uuid()

        const requirementEv: EvidenceSave = {
          evidenceUUID: evidenceUUID,
          accessServiceIdentifier: evidence.accessServiceIdentifier,
          base64File: evidence.base64File,
          evidenceIdentifier: evidence.evidenceIdentifier,
          evidenceNumber: evidence.evidenceNumber,
          evidenceTitle: evidenceTitle,
          fileName: evidence.fileName,
          mimeType: evidence.mimeType,
          previewUrl: evidence.previewUrl,
          publisherName: evidence.publisherName,
          requirementId: requirement.id,
          status: evidence.status,
        };
        evidencesSave.push(requirementEv)
      })
    })

      this.ootsService.saveEvidences(evidencesSave, this.ootsRequest.uuid).subscribe({next: response=> {
        this.ootsService.getDocuments(
          this.ootsRequest.uuid, action, email, phoneNumer).subscribe(
            {
              next: response => {
                const result = JSON.parse(response);
                if (result.success) {
                  if (this.ootsRequest.inSession) this.doPostWithEvidences(result.returnUrl, result.ootsResponseXML);
                  else this.doRedirect(result.returnUrl);
                }
              },
              error: (error) => {
                if (error.status == 404) this.manageNotFound();
              }
            });
      }})
      
    
  }

  saveOrShareOutOfSession(action: string) {
    this.action = action;
    document.getElementById("show-secCode-dialog")?.click();
  }

  finishAndNotify(email: string, phoneNumber: string) {
    if (this.action === Action.SAVE) this.shareEvidences(this.action, email, phoneNumber);
    else if (this.action === Action.FINISH) this.shareEvidences(this.action, email, phoneNumber);
  }

  finishSession() {
    this.shareEvidences(Action.FINISH, '', '');
  }

  doRedirect(returnUrl: string) {
    window.location.href = returnUrl;
  }

  doPostWithEvidences(returnUrl: string, xml: string) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = returnUrl;
    form.enctype = "multipart/form-data";
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'data';
    hiddenField.value = xml;
    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
  }

  evidenceListener(evidence: Evidence) {
    this.updateEvidenceData(evidence);
    this.selectedEvidence = evidence;
    if (evidence.isTimeout) document.getElementById("show-timeout-dialog")?.click();
    else if (evidence.status === 'ERROR') document.getElementById("show-error-dialog")?.click();
    else if (evidence.isPreview) {
      let delimiter = "?";
      if (evidence.previewUrl.indexOf("?") !== -1) delimiter = "&";
      const previewSpaceUrl = evidence.previewUrl + delimiter + "returnurl=" + encodeURIComponent(REDIRECT_BACK_URL + "?uuid=") + this.ootsRequest.uuid + "&returnmethod=GET";
      this.previewSpaceUrl = previewSpaceUrl;
      document.getElementById("show-preview-dialog")?.click();
    }
  }

  updateEvidenceData(evidence: Evidence) {
    this.ootsRequest.requirements.forEach(requirement => {
      if(requirement.id === evidence.requirementId) {
        requirement.evidences.forEach(ev => {
          if(ev.evidenceIdentifier === evidence.evidenceIdentifier) ev = evidence;
        })
      }
    })
  }

  countryListener($event: any) {
    this.countryCodeSelected = $event;
    this.countryAsset = COUNTRIES[this.countryCodeSelected] ?? COUNTRIES['EU'];
  }

  doSecondCall() {
    this.redirecting = true;
    const params = new AccessPointQueryParams();
    params.ootsRequestId = this.ootsRequest.uuid;
    params.evidenceUUID = this.selectedEvidence.evidenceUUID;
    params.evidenceRequirementId = this.selectedEvidence.requirementId;
    params.countryCode = this.selectedEvidence.countryCode;
    params.accessServiceIdentifier = this.selectedEvidence.selectedProvider.accessServiceIdentifier;
    params.evidenceClassificationId = this.selectedEvidence.evidenceTypeClassification;
    params.evidenceIdentifier = this.selectedEvidence.evidenceIdentifier;
    params.requirementNumber = this.selectedEvidence.requirementNumber;
    this.apService.sendRequest(params).subscribe(
      {
        next: response => {
          const result = JSON.parse(response);
          this.redirecting = false;
          if(result.success) this.redirectToPreviewSpace();
          else {
            this.selectedEvidence.errorMessage = result.message;
            this.openErrorDialog();
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {
          console.log("complete");
        }
      });
  }

  redirectToPreviewSpace() {
    this.jwtUtilsService.saveRedirect('true');
    window.location.href = this.previewSpaceUrl;
  }

  openPreviewDialog() {
    this.countryAsset = COUNTRIES[this.selectedEvidence.countryCode] ?? COUNTRIES['EU'];
    const data = {
      title: this.translate.instant('LOCATE.PREVIEW_SPACE_MODAL'),
      message: this.translate.instant('LOCATE.PREVIEW_SPACE_MODAL_DESC', { document_name:  this.multiLanguagePipe.transform(this.selectedEvidence.evidenceTitle, this.lang) ?? "", provider: this.selectedEvidence.selectedProvider.publisherName.toString() }),
      toAsset: this.countryAsset,
      type: 'preview'
    };

    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.doSecondCall();
        } else if (result?.action === 'cancel') {
          this.previewSpaceUrl = "";
        }
      });
  }

  openTimeoutDialog() {
    const data = {
      title: this.translate.instant('LOCATE.TIMEOUT_MODAL'),
      message: this.translate.instant('LOCATE.TIMEOUT_MODAL_DESC'),
      type: 'timeout'
    };
    this.dialogService.openDialog(ModalDialogComponent, data);
  }

  openErrorDialog() {
    const data = {
      title: this.translate.instant('LOCATE.ERROR_MODAL'),
      message: this.translate.instant('LOCATE.ERROR_MODAL_DESC_1') + this.selectedEvidence.errorMessage + this.translate.instant('LOCATE.ERROR_MODAL_DESC_2'),
      type: 'error'
    };
    this.dialogService.openErrorDialog(ModalDialogComponent, data);
  }

  openContactDialog() {
    const data = {
      title: this.translate.instant('LOCATE.CONTACT_INFORMATION_MODAL'),
      message: this.translate.instant('LOCATE.CONTACT_INFORMATION_MODAL_1'),
      type: 'contact'
    };
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.finishAndNotify(result.email, result.phoneNumber);
        }
      });
  }

  openSecurityCodeDialog() {
    this.secCodesTimes = 0;
    const codeData = {
      title: this.translate.instant('LOCATE.SECURITY_CODE_MODAL'),
      message: this.translate.instant('LOCATE.SECURITY_CODE_MODAL_DESC'),
      type: 'secCode',
      errorMessage: ''
    };

    const confirmData = {
      title: this.translate.instant('LOCATE.CONFIRM_SECURITY_CODE_MODAL'),
      message: this.translate.instant('LOCATE.CONFIRM_SECURITY_CODE_MODAL_DESC'),
      type: 'confirmCode',
      errorMessage: ''
    };

    const saveSuccessData = {
      title: this.translate.instant('LOCATE.SAVE_SUCCESS_MODAL'),
      message: this.translate.instant('LOCATE.SAVE_SUCCESS_MODAL_DESC'),
      type: 'saveSuccess'
    }

    const saveErrorData = {
      title: this.translate.instant('LOCATE.SAVE_ERROR_MODAL'),
      message: this.translate.instant('LOCATE.SAVE_ERROR_MODAL_DESC'),
      type: 'saveError'
    }

    this.eventsSubscription.add(
      // listener for dialog
      this.dialogService.dialogObserver.subscribe((result: ModalResult) => {
        this.dialogService.loading(true);
        let lang = window.localStorage.getItem('lang')
        if (lang == null) {
          lang = "es";
        }
        if (result.type === 'secCode') {
          if (!result.emailValid) {
            codeData.errorMessage = result.errorMessage;
            this.dialogService.updateDialog(codeData);
            this.dialogService.loading(false);
          }
          else {
            this.ootsService.getSecurityCode(this.ootsRequest.uuid, this.action, result.email, lang).subscribe(
              {
                next: response => {
                  this.dialogService.loading(false);
                  const result = JSON.parse(response);
                  if (result.success) {
                    this.dialogService.updateDialog(confirmData);
                  }
                },
                error: (error) => {
                  // Error enviado codigo de seguridad
                }
              });
          }

        }
        if (result.type === 'confirmCode') {
          this.dialogService.loading(true);
          this.ootsService.validateSecurityCode(this.ootsRequest.uuid, result.code, lang).subscribe(
            {
              next: response => {
                this.dialogService.loading(false);
                const result = JSON.parse(response);
                if (result.success) {
                  this.dialogService.updateDialog(saveSuccessData);
                }
                else {
                  this.secCodesTimes++;
                  if (this.secCodesTimes < 3) {
                    confirmData.errorMessage = this.translate.instant('LOCATE.CONFIRM_SECURITY_CODE_ERROR_MSG').replace("[X]", 3 - this.secCodesTimes);
                    this.dialogService.updateDialog(confirmData);
                  }
                  if (this.secCodesTimes == 3) this.dialogService.updateDialog(saveErrorData);
                }
              },
              error: (error) => {
                // Error validando codigo de seguridad
              }
            });
        }

        if (result.type === 'saveSuccess' || result.type === 'saveError') {
          this.doRedirect(this.sessionTimerService.sessionData.returnUrl);
        }

      }));

    this.dialogService.openDialog(ModalDialogComponent, codeData);
  }

  openCancelProcessDialog() {
    const data = {
      title: this.translate.instant('LOCATE.CANCEL_PROCESS_MODAL'),
      message: this.translate.instant('LOCATE.CANCEL_PROCESS_MODAL_DESC'),
      type: 'cancel'
    };
    this.dialogService.openDialog(ModalDialogComponent, data).subscribe(result => {
      if (result?.action === 'accept') {
        this.doRedirect(this.sessionTimerService.sessionData.returnUrl);
      } else if (result?.action === 'cancel') {
        this.dialogService.closeDialog();
      }
    });
  }

  locateDocuments() {
    let count = 0;
    this.storedEvidenceStatus = [];
    this.ootsRequest.requirements.forEach(requirement => {
      const evidenceStatus: EvidenceStatus[] = [];
      requirement.evidences.forEach(evidence => {
        if (!Utils.isNullOrUndefined(evidence.providers))
          evidence.providers.forEach(provider => {
            if (provider.isSelected) {
              count++;
              const evStatus = new EvidenceStatus();
              evStatus.status = evidence.status;
              evStatus.evidenceIdentifier = evidence.evidenceIdentifier;
              evStatus.evidenceTitle = this.multiLanguagePipe.transform(evidence.evidenceTitle, this.lang) ?? "";
              evStatus.accessServiceIdentifier = provider.accessServiceIdentifier;
              evStatus.publisherIdentifier = provider.publisherIdentifier;
              evStatus.publisherName = provider.publisherName;
              evStatus.countryCode = requirement.selectedCountryCode;
              evStatus.evidenceTypeClassification = evidence.evidenceTypeClassification;
              evidenceStatus.push(evStatus);
            }
          });
      });
      if (evidenceStatus.length > 0) this.storedEvidenceStatus.push(new RequirementInfo(requirement.id, evidenceStatus));
    });
    this.documentCounterService.addDocumentsTotal(count);
    this.stateService.saveRequestStatus(this.storedEvidenceStatus);
    this.updateStep(3);
  }

  updateStep(newStep: number) {
    this.breadcrumbService.setStep(newStep)
  }

  mockSedeResponse() {
    this.router.navigate(['/sede'], { queryParams: { retrieveResponse: true, uuid: this.ootsRequest.uuid } });
  }

  checkForStoredEvidenceStatus() {
    let count = 0;
    this.storedEvidenceStatus = [];
    const requestStatus = this.stateService.retrieveRequestStatus();
    if (!Utils.isNullOrUndefined(this.stateService.retrieveRequestStatus()) && requestStatus.length > 0) {
      this.storedEvidenceStatus = requestStatus;
      
      for(let i = 0; i<this.ootsRequest.requirements.length; i++){
      const requirement = this.ootsRequest.requirements[i]
        if (requirement.evidences.length > 0) {
          requirement.evidences.forEach(evidence => {
            count++;
            const storedEvidences = this.storedEvidenceStatus[i].evidencesStatus;
            if (!Utils.isNullOrUndefined(storedEvidences)) {
              storedEvidences?.forEach(ev => {
                if (evidence.evidenceIdentifier === ev.evidenceIdentifier) {
                  if(Utils.isNullOrUndefined(evidence.evidenceTitle) || evidence.evidenceTitle.length === 0) {
                    evidence.evidenceTitle = [];
                    evidence.evidenceTitle.push({ "value": ev.evidenceTitle, "lang": "ES" }); // TODO Manage multilanguage
                  }
                  evidence.evidenceTypeClassification = ev.evidenceTypeClassification;
                  evidence.status === "RECEIVED" ? "RECEIVED" : ev.status;
                  evidence.countryCode = ev.countryCode;
                  const provider = new EvidenceProvider();
                  provider.isSelected = true;
                  provider.accessServiceIdentifier = evidence.accessServiceIdentifier ?? ev.accessServiceIdentifier;
                  provider.publisherIdentifier = evidence.publisherIdentifier ?? ev.publisherIdentifier;
                  provider.publisherName = evidence.publisherName ?? ev.publisherName;
                  evidence.providers = [];
                  evidence.providers.push(provider);
                }
              });
            }
          });
        }
        else {
          const storedEvidences = this.storedEvidenceStatus[i].evidencesStatus;
          if (!Utils.isNullOrUndefined(storedEvidences)) {
            storedEvidences?.forEach(ev => {
              count++;
              const evidence = new Evidence();
              evidence.evidenceTitle = [];
              evidence.evidenceTitle.push({ "value": ev.evidenceTitle, "lang": "ES" }); // TODO Manage multilanguage
              evidence.evidenceTypeClassification = ev.evidenceTypeClassification;
              evidence.status = ev.status;
              evidence.countryCode = ev.countryCode;
              evidence.evidenceIdentifier = ev.evidenceIdentifier;
              const provider = new EvidenceProvider();
              provider.isSelected = true;
              provider.accessServiceIdentifier = ev.accessServiceIdentifier;
              provider.publisherIdentifier = ev.publisherIdentifier;
              provider.publisherName = ev.publisherName;
              evidence.providers = [];
              evidence.providers.push(provider);
              requirement.evidences.push(evidence);
            });
          }
        }
      }
      this.documentCounterService.addDocumentsTotal(count);
      this.updateStep(3);
    }
    else {
      this.updateStep(2);
    }
  }

  validateRequirementsStatus() {
    this.isStep3Available = this.ootsRequest.requirements.every(requirement =>
      requirement.selectedCountryCode === "NONE" || requirement.evidences.some(evidence =>
        !Utils.isNullOrUndefined(evidence.providers) && evidence.providers.some(provider => provider.isSelected)
      ));
  }

  isStep4Available(): boolean {
    return this.totalDocuments === this.receivedDocuments;
  }

}