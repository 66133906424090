import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AccessPointQueryParams, SpecificNaturalPerson } from 'src/app/_model/access-point-query-params';
import { Country } from 'src/app/_model/country';
import { DataServiceDirectoryQueryParams } from 'src/app/_model/data-service-directory-query-params';
import { Evidence } from 'src/app/_model/evidence';
import { EvidenceBrokerQueryParams } from 'src/app/_model/evidence-broker-query-params';
import { EvidenceProvider } from 'src/app/_model/evidence-provider';
import { AccessPointService } from 'src/app/_service/access-point.service';
import { DataServiceDirectoryService } from 'src/app/_service/data-service-directory.service';
import { EvidenceBrokerService } from 'src/app/_service/evidence-broker.service';
import { DEFAULT_DSD_QUERY_ID } from 'src/app/data-service-directory/constants';
import { QUERY_BY_REQUIREMENTS_AND_JURISDICTION } from 'src/app/evidence-broker/constants';
import { environment } from 'src/environments/environment';
import { LoaderButtonComponent } from '../loader-button/loader-button.component';
import { getNextNutsLevel } from 'src/app/contants';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '../../_service/breadcrumb.service';
import { StateService } from 'src/app/_service/state.service';
import { JwtUtilsService } from 'src/app/_service/jwt-utils.service';
import { Requirement } from 'src/app/_model/requirement';
import { Subscription } from 'rxjs';
import { DocumentCounterService } from 'src/app/_service/document-counter.service';
import { EidasUser } from 'src/app/_model/eidasUser';
import { MultiLanguageStringPipe } from 'src/app/_model/multi-language-string';
import { EvidenceNotification } from 'src/app/_model/evidence-notification';
import { Utils } from '../utils';
import { OotsService } from 'src/app/_service/oots.service';

const EVENTS_API_URL = environment.api_url + "/event";
declare const require: any;
const i18nIsoCountries = require("i18n-iso-countries");

@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html',
  styleUrls: ['./requirement.component.css']
})

export class RequirementComponent implements OnInit {
  @Input() ootsRequestId: string;
  @Input() requirement: Requirement;
  @Input() evidence: Evidence;
  @Output() selectedEvidenceListener: EventEmitter<Evidence> = new EventEmitter();
  @Output() evidenceListener: EventEmitter<Evidence> = new EventEmitter();
  @Output() countrySelected: EventEmitter<any> = new EventEmitter();
  @ViewChild(LoaderButtonComponent) sendButton: LoaderButtonComponent;
  @ViewChild("documentPreview", { static: true }) documentPreview: ElementRef;

  private eventsSubscription = new Subscription();
  selectedCountry: string;
  countryList: Country[];
  isProviderFound = false;
  isSearchDone = false;
  evidenceProviders: EvidenceProvider[];
  selectedProvider: EvidenceProvider;
  isRequestSent = false;
  private eventSource: EventSource;
  pdfBase64: string;
  isPdfAvailable = false;
  isPreview: boolean;
  errorMessage: string;
  base64Src: string;
  showJurisdictionSelect = false;
  isUserLogged = false;
  step: number;
  evidences: Evidence[];
  lang = "";
  requiredJurisdictionLevel: string;
  requiredEvidenceTypeId: string;
  jurisdictionContextId: string;
  classificationType: string;
  classificationTypes: string[];
  finalJurisdictionAdminL2: string;
  finalJurisdictionAdminL3: string;
  jurisdictionForm: FormGroup;
  dynamicJurisdictionSelects: FormControl[] = [];
  jurisdictionLists: any[];
  currentUser: EidasUser;
  receivedEvidence: Evidence;
  isAnyEvidenceReceived = false;

  constructor(
    private ebService: EvidenceBrokerService,
    private dsdService: DataServiceDirectoryService,
    private apService: AccessPointService,
    private http: HttpClient,
    private sanitizer: DomSanitizer, private fb: FormBuilder,
    public translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private jwtUtilService: JwtUtilsService,
    private documentCounterService: DocumentCounterService,
    private multiLanguagePipe: MultiLanguageStringPipe,
    private ootsService: OotsService
  ) {

    this.eventsSubscription.add(
      this.stateService.getLanguage.subscribe(lang => {
        if (this.lang != lang) {
          this.changeCountryListLanguage(lang);
          this.lang = lang;
        }
      }));

    this.eventsSubscription.add(
      this.breadcrumbService.getStep.subscribe(step => {
        this.step = step;
        if (this.step === 2) {
          if (this.requirement) {
            this.requirement.selectedCountryCode = 'null';
            this.stateService.clearRequestStatus();
            this.stateService.requirementStatusChanged();
          }
        }
      }));
  }

  ngOnInit(): void {
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json"));
    this.jurisdictionForm = this.fb.group({
      select: ['']
    });
    this.isPreview = JSON.parse(this.stateService.isPreview());
    this.isUserLogged = this.jwtUtilService.isLogged();
    this.currentUser = this.jwtUtilService.getCurrentUser();

    this.checkForPreviewAvailable();
    
    this.initializeComponent();
    this.ebService.fetchEvidenceCountriesByRequirementId(this.requirement.id, this.lang).subscribe(
      {
        next: result => {
          this.countryList = [];
          if(result) this.countryList = result.evidenceCountries;
          this.countryList.unshift({ "code": "EU", "name": "European Union Commision" });
          const text = this.translate.instant('EV_REQUESTED.DISCARD_REQUIREMENT')
          this.countryList.unshift({ "code": "NONE", "name": text });
          //this.countryList.unshift({ "code": "ES2", "name": "SGAD PRE Environment" });
        },
        error: (e) => {
          console.log(e)
        }
      });
    this.jurisdictionLists = [];
    this.stateService.validities.push('invalid')

    // Event subscriptions
    this.breadcrumbService.getStep.subscribe(step => { this.step = step; this.restart() });

    // Sincronize documents counter
    this.sincronizeDocumentsCounter();
    this.checkForReceivedEvidences();
  }

  sincronizeDocumentsCounter() {
    this.documentCounterService.resetDocumentsTotal();
    this.documentCounterService.addDocumentsTotal(this.requirement.evidences.length);
    if (this.requirement.evidences.length > 0) 
    this.documentCounterService.addDocumentsReceived(this.requirement.evidences.filter(ev => ev.status === "RECEIVED").length);
  }

  checkForReceivedEvidences() {
    this.receivedEvidence = this.requirement.evidences.filter(ev => ev.status === "RECEIVED")[0];
    if(!Utils.isNullOrUndefined(this.receivedEvidence)) this.isAnyEvidenceReceived = true;
  }

  checkForPreviewAvailable() {
    this.requirement.evidences.forEach(evidence => {
      if (evidence.status === "PREVIEW_AVAILABLE") this.waitForResponse(evidence);
    });
  }

  searchDocument(evidence: Evidence) {
    if(evidence.status == 'ERROR'){
      this.documentCounterService.addDocumentTotal();
    }
    evidence = Object.assign(new Evidence(), evidence);
    this.isRequestSent = true;
    const params = new AccessPointQueryParams();
    params.ootsRequestId = this.ootsRequestId;
    params.evidenceIdentifier = evidence.evidenceIdentifier;
    params.evidenceTitle = this.multiLanguagePipe.transform(evidence.evidenceTitle, this.lang) ?? "";
    params.evidenceRequirementId = this.requirement.id;
    params.countryCode = this.requirement.selectedCountryCode !== "null" ? this.requirement.selectedCountryCode : evidence.countryCode;
    params.accessServiceIdentifier = evidence.selectedProvider.accessServiceIdentifier;
    params.publisherName = evidence.selectedProvider.publisherName;
    params.evidenceClassificationId = evidence.evidenceTypeClassification;

    params.skipPreview = !this.isPreview;
    params.specificNaturalPerson = new SpecificNaturalPerson();
    params.specificNaturalPerson.birthDate = this.currentUser.dateOfBirth.toString().split('T')[0];
    params.specificNaturalPerson.givenName = this.currentUser.firstName
    params.specificNaturalPerson.familyName = this.currentUser.familyName
    params.specificNaturalPerson.userIdentifier = this.currentUser.personIdentifier
    params.requirementNumber = this.requirement.number;

    params.jurisdictionContextId = evidence.jurisdictionContextId;
    params.jurisdictionAdminL2 = evidence.jurisdictionAdminL2;
    params.jurisdictionAdminL3 = evidence.jurisdictionAdminL3;
    params.evidenceTypeId = evidence.dataServiceEvidenceTypeIdentifier;
    this.apService.sendRequest(params).subscribe(
      {
        next: response => {
          console.info(response);
          const result = JSON.parse(response);
          if (result.success) {
            this.requirement.evidences.forEach(evidence => {
              if (evidence.evidenceIdentifier === result.evidenceIdentifier) {
                evidence.evidenceUUID = result.evidenceUUID;
                this.waitForResponse(evidence);
              }
            });
          }
          else {
            this.sendButton.requestFinished();
            this.errorMessage = result.message; // TODO manejar aqui el popup de error
          }
        },
        error: (e) => {
          console.error(e);
        }
      });
  }

  waitForResponse(evidence: Evidence) {
    this.eventSource = new EventSource(EVENTS_API_URL + '/evidence/' + evidence.evidenceUUID);
    this.eventSource.onmessage = (event) => {
      const evidenceNotification: EvidenceNotification = JSON.parse(event.data);
      if (this.eventSource) {
        this.eventSource.close();
      }
      if (this.sendButton != undefined) this.sendButton.requestFinished();
      evidence.isPreview = evidenceNotification.preview;
      evidence.status = evidenceNotification.evidence.status;
      evidence.evidenceIdentifier = evidenceNotification.evidence.evidenceIdentifier;
      evidence.evidenceNumber = parseInt(evidenceNotification.evidence.evidenceNumber);
      evidence.countryCode = (this.requirement.selectedCountryCode !== null && this.requirement.selectedCountryCode !== "null") ? this.requirement.selectedCountryCode : evidence.countryCode;
      evidence.requirementId = this.requirement.id;
      evidence.requirementNumber = this.requirement.number;
      if (evidence.isPreview) {
        evidence.previewUrl = evidenceNotification.previewUrl;
      } else if (evidence.status == "ERROR") {
        evidence.errorMessage = evidenceNotification.errorMessage;
        this.documentCounterService.removeDocumentTotal();
      } else if (evidence.status == "RECEIVED") {
        evidence.base64File = evidenceNotification.evidence.base64File;
        evidence.fileName = evidenceNotification.evidence.fileName;
        this.documentCounterService.addDocumentReceived();
      }
      this.evidenceListener.emit(evidence);
    };
    this.eventSource.onerror = () => {
      if (this.eventSource) {
        this.eventSource.close();
      }
    };
    this.eventSource.addEventListener('error', () => {
      if (this.eventSource.readyState === EventSource.CLOSED) {
        if (this.sendButton != undefined) this.sendButton.requestFinished();
        if(evidence.status == 'PREVIEW_AVAILABLE') evidence.status='ERROR'
        evidence.isTimeout = true;
        this.evidenceListener.emit(evidence);
      }
    });
  }

  getClassificationType() {
    // call to EB to get the classification Id
    const queryParams: EvidenceBrokerQueryParams = {
      queryId: QUERY_BY_REQUIREMENTS_AND_JURISDICTION,
      countryCode: this.requirement.selectedCountryCode,
      requirementId: this.requirement.id
    };

    // mock to test against oots_test_platform
    if (this.selectedCountry == 'EU') return this.http.get("mocks/EUclassificationType.json");
    if (this.selectedCountry == 'ES2') return this.http.get("mocks/SGADclassificationType.json");

    //////////////////////////////////////////

    return this.ebService.getEvidenceClassification(queryParams);
  }

  getEvidenceProviders(classificationTypes: string) {

    // mock to test against oots_test_platform
    if (this.selectedCountry == 'EU') return this.http.get("mocks/EUevidenceProviders.json");
    if (this.selectedCountry == 'ES2') return this.http.get("mocks/SGADevidenceProviders.json");
    //////////////////////////////////////////

    // call to DSD to get the evidence providers
    const params: DataServiceDirectoryQueryParams = {
      queryId: DEFAULT_DSD_QUERY_ID,
      evidenceTypeClassification: classificationTypes,
      countryCode: this.selectedCountry
    }
    return this.dsdService.search(params);
  }

  searchEvidence() {
    this.isSearchDone = false;
    this.requirement.evidences = [];

    this.getClassificationType().subscribe(
      {
        next: result => {
          if (result) {
            if (result.success) {
              let evidenceTypesClassification: string;
              if (result.classificationType.length > 1) {
                evidenceTypesClassification = result.classificationType.join(",");
                this.classificationTypes = result.classificationType;
              }
              else {
                evidenceTypesClassification = result.classificationType;
                this.classificationType = result.classificationType[0];
              }
              this.searchProviders(evidenceTypesClassification);
              this.sendButton.requestFinished();
            }
            else {
              this.sendButton.requestFinished();
              this.errorMessage = result.message;
              if (this.errorMessage.includes("EB:ERR:0001")) {
                this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_NOT_FOUND');
              }
              else if (this.errorMessage.includes("EB:ERR:0002")) {
                this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_ID_NONEXISTENT');
              }
              this.isSearchDone = true;
            }
          }
          else {
            this.isSearchDone = true;
            this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_NOT_FOUND');
            this.sendButton.requestFinished();
          }
        },
        error: () => {
          this.isSearchDone = true;
        }
      });
  }

  searchProviders(classificationTypes: string) {
    this.clearJuristictionForm();
    this.getEvidenceProviders(classificationTypes).subscribe(
      {
        next: result => {
          this.isProviderFound = false;
          this.showJurisdictionSelect = false;
          if (result.evidences) {
            // Create a typed instance of Evidence[]
            result.evidences.forEach((ev: Evidence) => {
              const evidence = this.instantiateEvidence(ev);
              this.requirement.evidences.push(evidence);
            });
            this.handleEvidenceProviders(this.requirement.evidences)
          }
        },
        error: (e) => console.error(e)
      });
  }

  instantiateEvidence(ev: Evidence): Evidence {
    const evidence = new Evidence();
    evidence.base64File = ev.base64File;
    evidence.countryCode = ev.countryCode;
    evidence.dataServiceEvidenceTypeIdentifier = ev.dataServiceEvidenceTypeIdentifier;
    evidence.errorMessage = ev.errorMessage;
    evidence.evidenceIdentifier = ev.evidenceIdentifier;
    evidence.evidenceNumber = ev.evidenceNumber;
    evidence.evidenceTitle = ev.evidenceTitle;
    evidence.evidenceTypeClassification = ev.evidenceTypeClassification;
    evidence.evidenceUUID = ev.evidenceUUID;
    evidence.fileName = ev.fileName;
    evidence.isPreview = true;
    evidence.isTimeout = ev.isTimeout;
    evidence.jurisdictionContextId = ev.jurisdictionContextId;
    evidence.jurisdictionAdminL2 = ev.jurisdictionAdminL2;
    evidence.jurisdictionAdminL3 = ev.jurisdictionAdminL3;
    evidence.jurisdictionLevel = ev.jurisdictionLevel;
    evidence.mandatory = ev.mandatory;
    evidence.message = ev.message;
    evidence.mimeType = ev.mimeType;
    evidence.previewUrl = ev.previewUrl;
    evidence.previousStatus = ev.previousStatus;
    evidence.providerCountryCode = ev.providerCountryCode;
    evidence.providers = ev.providers;
    evidence.requirementId = ev.requirementId;
    evidence.showJurisdictionSelect = ev.showJurisdictionSelect;
    evidence.status = ev.status;
    evidence.success = ev.success;
    evidence.uploadAllowed = ev.uploadAllowed;
    return evidence;
  }

  addDynamicJurisdictionSelect(params: { nutsCodes?: nutsCode[], lauCodes?: lauCode[], currentLevel: string, index: number, evidence: Evidence }) {
    const jurisdictionSelect = this.fb.control('');
    this.jurisdictionForm.addControl('dynamicSelect' + params.index, jurisdictionSelect);
    this.dynamicJurisdictionSelects.push(jurisdictionSelect);
    jurisdictionSelect.setValue(null);
    jurisdictionSelect.valueChanges.subscribe(value => {
      while (this.dynamicJurisdictionSelects.length > params.index + 1) {
        this.dynamicJurisdictionSelects.pop();
        this.jurisdictionLists.pop();
        this.jurisdictionForm.removeControl('dynamicSelect' + (this.dynamicJurisdictionSelects.length));
      }
      if (params.currentLevel == this.requiredJurisdictionLevel) {
        switch (this.requiredJurisdictionLevel) {
          case "1":
          case "2":
          case "3":
            this.dsdService.search({
              queryId: DEFAULT_DSD_QUERY_ID, countryCode: this.selectedCountry,
              evidenceTypeClassification: this.classificationType, jurisdictionAdminL2: value!,
              evidenceTypeId: params.evidence.dataServiceEvidenceTypeIdentifier, jurisdictionContextId: params.evidence.jurisdictionContextId
            }).subscribe(
              {
                next: result => {
                  if (result.success) {
                    result.evidences.forEach((evidence: Evidence) => {
                      console.log(evidence)
                      // Modificar el elemento con el identificador específico
                      this.evidences = this.evidences.map(ev => {
                        if (ev.evidenceIdentifier === evidence.evidenceIdentifier) {
                          ev.status = "PENDING";
                          ev.providers = evidence.providers;
                          ev.success = evidence.success;
                          ev.jurisdictionLevel = evidence.jurisdictionLevel;
                          ev.jurisdictionAdminL2 = value!;
                        }
                        if(!evidence.evidenceIdentifier){
                          ev = evidence;
                        }
                        return ev;
                      });
                    });
                    this.handleEvidenceProviders(this.evidences);
                  }
                  else {
                    this.errorMessage = result.message;
                    this.isSearchDone = true;
                    this.isProviderFound = false;
                    console.log(result.message);
                  }
                },
                error: (e) => console.error(e)
              });
            break;
          case "LAU":
            this.dsdService.search({
              queryId: DEFAULT_DSD_QUERY_ID, countryCode: this.selectedCountry,
              evidenceTypeClassification: this.classificationType, jurisdictionAdminL3: value!,
              evidenceTypeId: params.evidence.dataServiceEvidenceTypeIdentifier, jurisdictionContextId: params.evidence.jurisdictionContextId
            }).subscribe(
              {
                next: result => {
                  if (result.success) {
                    console.log(result);
                    result.evidences.forEach((evidence: Evidence) => {
                      // Modificar el elemento con el identificador específico
                      this.evidences = this.evidences.map(ev => {
                        if (ev.evidenceIdentifier === evidence.evidenceIdentifier) {
                          ev.status = "PENDING";
                          ev.providers = evidence.providers;
                          ev.success = evidence.success;
                          ev.jurisdictionLevel = evidence.jurisdictionLevel;
                          ev.jurisdictionAdminL3 = value!;
                        }
                        return ev;
                      }); 
                    });
                    this.handleEvidenceProviders(this.evidences);
                  }
                  else {
                    this.errorMessage = result.message;
                    this.isSearchDone = true;
                    this.isProviderFound = false;
                    console.log(result.message);
                  }
                },
                error: (e) => console.error(e)
              });
            break;

        }
      } else {
        const nextNutsLevel = getNextNutsLevel(params.currentLevel)?.key ?? '';
        if (value) {
          this.dsdService.fetchJurisdictionList(this.selectedCountry, nextNutsLevel, value).subscribe(
            {
              next: result => {
                this.jurisdictionLists.push(result);
                if (result instanceof Array && result.every(item => 'nutsLevel' in item)) {
                  this.addDynamicJurisdictionSelect({ nutsCodes: result, currentLevel: nextNutsLevel, index: params.index + 1, evidence: params.evidence});
                }
                if (result instanceof Array && result.every(item => 'nuts3Code' in item)) {
                  this.addDynamicJurisdictionSelect({ lauCodes: result, currentLevel: nextNutsLevel, index: params.index + 1, evidence: params.evidence});
                }
              },
              error: (e) => console.error(e)
            });
        }
      }
    });
  }

  handleEvidenceProviders(evidences: Evidence[]) {
    this.isProviderFound = true;
    this.evidenceProviders = [];
    this.evidences = evidences;
    this.evidences.forEach(evidence => {
      evidence.status = "PENDING";
      if (evidence.providers != null) {
        this.requirement.haveProviders = true;
        this.stateService.requirementStatusChanged();
      }

      if (!evidence.success && evidence.countryCode !== "EU") {
        this.errorMessage = evidence.message;
        this.classificationType = evidence.evidenceTypeClassification;
        console.log(evidence.message);
        if (this.errorMessage && this.errorMessage.includes("DSD:ERR:0005") && evidence.jurisdictionLevel !== null) {
          evidence.showJurisdictionSelect = true;
          const index = 0;
          this.showJurisdictionSelect = true;
          const jLevel: string = evidence.jurisdictionLevel != "LAU" ? (evidence.jurisdictionLevel as string).charAt(evidence.jurisdictionLevel.length - 1) : "LAU";
          this.requiredJurisdictionLevel = jLevel;
          this.jurisdictionContextId = evidence.jurisdictionContextId;
          this.requiredEvidenceTypeId = evidence.dataServiceEvidenceTypeIdentifier;
          switch (this.requiredJurisdictionLevel) {
            case "1":
              this.dsdService.fetchJurisdictionList(this.selectedCountry, jLevel).subscribe(
                {
                  next: result => {
                    const nuts1Jurisdictions = (result as nutsCode[]);
                    this.jurisdictionLists.push(nuts1Jurisdictions);
                    this.addDynamicJurisdictionSelect({ nutsCodes: nuts1Jurisdictions, currentLevel: "1", index: index, evidence: evidence });
                  },
                  error: (e) => console.error(e)
                });
              break;
            case "2":
            case "3":
              this.dsdService.fetchJurisdictionList(this.selectedCountry, "2").subscribe(
                {
                  next: result => {
                    const nuts2Jurisdictions = (result as nutsCode[]);
                    this.jurisdictionLists.push(nuts2Jurisdictions);
                    this.addDynamicJurisdictionSelect({ nutsCodes: nuts2Jurisdictions, currentLevel: "2", index: index, evidence: evidence });
                  },
                  error: (e) => console.error(e)
                });
              break;
            case "LAU":
              this.dsdService.fetchJurisdictionList(this.selectedCountry, jLevel).subscribe(
                {
                  next: result => {
                    const lauJurisdictions = (result as lauCode[]);
                    this.jurisdictionLists.push(lauJurisdictions);
                    this.addDynamicJurisdictionSelect({ lauCodes: lauJurisdictions, currentLevel: "LAU", index: index, evidence: evidence });
                  },
                  error: (e) => console.error(e)
                });
              break;
            default:
              this.isProviderFound = false;
              this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_NOT_FOUND');
          }
        }
        else {
          if (this.errorMessage.includes("EB:ERR:0001")) {
            this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_NOT_FOUND');
          }
          else if (this.errorMessage.includes("EB:ERR:0002")) {
            this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_ID_NONEXISTENT');
          }
          else if (this.errorMessage.includes("DSD:ERR:0001")) {
            this.isProviderFound = false;
            this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_NOT_FOUND');
          }
          else if (this.errorMessage.includes("DSD:ERR:0002")) {
            this.isProviderFound = false;
            this.errorMessage = this.translate.instant('LOCATE.ERROR_REQUIREMENT_ID_NONEXISTENT');
          }
          else if (this.errorMessage.includes("DSD:ERR:0005")) {
            this.isProviderFound = false;
            this.errorMessage = this.translate.instant('LOCATE.ERROR_PROVIDER_NOT_FOUND');
          }
        }
      }
    });

    this.isSearchDone = true;
  }

  providerSelected(evidence: Evidence, providerIndex: number) {
    evidence.providers.forEach((provider, index) => {
      if (index !== providerIndex) evidence.providers[index].isSelected = false;
    });
    this.stateService.requirementStatusChanged();
  }

  viewDocument() {
    this.isPdfAvailable = !this.isPdfAvailable;
  }

  deleteDocument(evidence: Evidence) {
    this.requirement = Object.assign(new Requirement(), this.requirement);
    this.ootsService.deleteEvidence(evidence.evidenceUUID).subscribe(
      {
        next: result => {
          if(result.success) {
            this.requirement.deleteEvidence(evidence.evidenceUUID);
            this.documentCounterService.removeDocumentReceived();
            this.stateService.ootsRequestModelChanged(this.ootsRequestId);
          }
          else ""; // TODO Mostrar error eliminando evidencia
        },
        error: (e) => {
          console.error(e)
        },
      });
  }

  onCountryChange(event: Event) {
    this.showJurisdictionSelect = false;
    const countryCode = (event.target as HTMLInputElement).value;
    this.selectedCountry = countryCode;
    this.isSearchDone = false;
    this.isProviderFound = false;
    this.requirement.evidences = [];
    this.requirement.selectedCountryCode = countryCode;
    this.countrySelected.emit(countryCode);
    this.stateService.requirementStatusChanged();
  }

  onProviderChange(event: Event) {
    const providerId = (event.target as HTMLInputElement).value;
    const provider: EvidenceProvider = this.evidenceProviders.find(p => p.publisherIdentifier === providerId)!;
    this.selectedProvider = provider;
    this.isProviderFound = true;
    this.isSearchDone = true;
  }

  initializeComponent() {
    this.isRequestSent = false;
    this.selectedCountry = 'null';
    this.requirement.selectedCountryCode = 'null';
  }

  translateText(key: string) {
    return this.translate.instant(key);
  }

  onJurisdictionSubmit() {
    //
  }

  clearJuristictionForm() {
    this.requiredJurisdictionLevel = "";
    this.requiredEvidenceTypeId = "";
    this.jurisdictionContextId = "";
    this.classificationType = "";
    this.jurisdictionForm.reset();
    this.jurisdictionLists = [];
    this.dynamicJurisdictionSelects = [];
  }

  updateStep(newStep: number) {
    this.breadcrumbService.setStep(newStep)
  }

  setCancel(evidence: Evidence) {
    evidence.previousStatus = evidence.status;
    evidence.status = 'CANCEL';
    this.documentCounterService.removeDocumentTotal();
  }

  restart() {
    if (this.step === 2) {
      this.selectedCountry = "null";
      this.documentCounterService.resetDocumentsTotal();
      this.isProviderFound = false;
      this.isSearchDone = false;
      this.isRequestSent = false;
      this.showJurisdictionSelect = false;
      this.clearJuristictionForm();
    }

    if (this.step === 3) {
      if (this.requirement.status === "PENDING" && (this.selectedCountry === 'null' || this.selectedCountry === 'NONE')) {
        this.requirement.status = "CANCEL"
        //TODO Manage requirements status for validity
      }
    }
  }

  revertCancel(evidence: Evidence) {
    evidence.status = evidence.previousStatus;
    this.documentCounterService.addDocumentTotal();
  }

  changeCountryListLanguage(lang: string) {
    if (this.countryList !== undefined) {
      this.countryList.splice(0, 2);
      let countryName: any = "";
      for (const country of this.countryList) {
        countryName = i18nIsoCountries.getName(country.code, lang);
        if (countryName) country.name = countryName;
      }
      this.countryList.sort((a, b) => a.name.localeCompare(b.name));
      this.countryList.unshift({ "code": "EU", "name": "European Union Commision" });
      const text = this.translate.instant('EV_REQUESTED.DISCARD_REQUIREMENT')
      this.countryList.unshift({ "code": "NONE", "name": text });
    }
  }
}

export class nutsCode {
  code: string;
  name: string;
  countryCode: string;
  nutsLevel: string;
}

export class lauCode {
  code: string;
  name: string;
  countryCode: string;
  nuts3Code: string;
}