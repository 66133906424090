import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormType, SpecificDataComponent } from '../specific-data.component';

@Component({
  selector: 'app-cpm',
  templateUrl: './cpm.component.html',
  styleUrls: ['./cpm.component.css']
})
export class CpmComponent extends SpecificDataComponent implements OnInit {

  cpmData : CpmData;
  jsonData: any;
  comunidades: Comunidad[] = [];
  provincias: Provincia[] = [];
  municipios: Municipio[] = [];
  spanishCitizen: boolean | null = null;

  selectedComunidad: Comunidad | null = null;
  selectedProvincia: Provincia | null = null;
  selectedMunicipio: Municipio | null = null;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  ngOnInit(): void {
    this.cpmData = new CpmData();
    // Cargar los datos del JSON
    this.http.get<any>('assets/cpm.json').subscribe(response => {
        this.jsonData = response;
        this.comunidades = response.comunidades;
      }
    );
  }

  onComunidadChange(comunidad: Comunidad): void {
    if (comunidad) {
      this.selectedProvincia = null;
      this.selectedMunicipio = null;
      this.selectedComunidad = comunidad;
      this.provincias = comunidad.provincias ?? [];

      if (this.provincias.length === 1) {       // Autoseleccionar la provincia si solo hay una
        this.selectedProvincia = this.provincias[0]; 
        this.municipios = this.selectedProvincia.municipios ?? [];
        this.cpmData.codeProvincia =  this.selectedProvincia.codigo;
      } else {
        this.municipios = [];
        this.selectedProvincia = null;
      }
      this.cpmData.codeComunidad = this.selectedComunidad.codigo;
      this.emitValueChange(this.cpmData);
    }
  }

  onProvinciaChange(provincia: Provincia): void {
    this.cpmData.codeProvincia = null;
    this.cpmData.codeMunicipio = null;
    if (provincia) {
      this.selectedProvincia = provincia;
      this.municipios = provincia.municipios ?? [];
      this.selectedMunicipio = null;
      this.cpmData.codeProvincia = this.selectedProvincia.codigo;
      this.emitValueChange(this.cpmData);
    }
  }

  onMunicipioChange(municipio: Municipio): void {
    this.selectedMunicipio = municipio;
    this.cpmData.codeMunicipio = this.selectedMunicipio.codigo;
    this.emitValueChange(this.cpmData);
  }

  override isValid(): boolean {
    if(this.type === FormType.ccaa) return this.selectedComunidad !== null;
    if(this.type === FormType.cpm) {
      return this.selectedComunidad !== null && this.selectedProvincia !== null && this.selectedMunicipio !== null;
    }
    return false;
  }

  override getValue(): any {
    return this.cpmData;
  }

  spanishCitizenChange() {
    this.cpmData.spanishCitizen = this.spanishCitizen;
    this.selectedMunicipio = null;
    this.selectedProvincia = null;
    this.selectedComunidad = null;
  }
  
}

interface Municipio {
  codigo: string;
  nombre: string;
}

interface Provincia {
  codigo: string;
  nombre: string;
  municipios?: Municipio[];
}

interface Comunidad {
  codigo: string;
  nombre: string;
  provincias?: Provincia[];
}

export class CpmData  {
  codeComunidad: string | null = null;
  codeProvincia: string | null = null;
  codeMunicipio: string | null = null;
  spanishCitizen: boolean | null = null;
}