import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AS4QueryParams } from '../_model/as4-query-params';
import { OotsRequest } from '../_model/oots-request';
import { EvidenceSave } from '../_model/evidence';

const OOTS_API_URL = environment.api_url + "/oots";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root'
})
export class OotsService {

  constructor(private http: HttpClient) { }

  setUserIdentifier(ootsRequest: OotsRequest): Observable<any> {
    return this.http.post<any>(OOTS_API_URL + "/user", ootsRequest, { headers })
      .pipe(
        catchError(this.handleError)
      )
  }

  sendPlainXML(xml: string): Observable<any> {
    return this.http.post<any>(OOTS_API_URL + "/request", xml, { headers, responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError)
      )
  }

  getOOTSRequestByUUID(uuid: string): Observable<any> {
    const httpParams = new HttpParams().append("uuid", uuid);
    const httpOptions = {
      headers: headers,
      params: httpParams,
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(OOTS_API_URL + "/request", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getSessionRemainingTime(uuid: string): Observable<any> {
    const httpParams = new HttpParams().append("uuid", uuid);
    const httpOptions = { headers: headers, params: httpParams };
    return this.http.get<any>(OOTS_API_URL + "/session", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  findPreviousRequestByToken(userIdentifier: string, continuationToken: string): Observable<any> {
    const httpParams = new HttpParams()
      .append("userIdentifier", userIdentifier)
      .append("continuationToken", continuationToken);

    const httpOptions = {
      headers: headers,
      params: httpParams,
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(OOTS_API_URL + "/find", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getDocuments(uuid: string, action: string, email: string, phoneNumber: string): Observable<any> {
    let httpParams = new HttpParams().append("uuid", uuid);
    if (action != null) httpParams = httpParams.append("action", action);
    if (email != null) httpParams = httpParams.append("email", email);
    if (phoneNumber != null) httpParams = httpParams.append("phoneNumber", phoneNumber);
    httpParams = httpParams.append("language", "es");
    const httpOptions = {
      headers: headers,
      params: httpParams,
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(OOTS_API_URL + "/response", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getSecurityCode(uuid: string, action: string, email: string, language: string): Observable<any> {
    console.log(language)
    let httpParams = new HttpParams().append("uuid", uuid);
    if (action != null) httpParams = httpParams.append("action", action);
    if (email != null) httpParams = httpParams.append("email", email);
    if (language != null) httpParams = httpParams.append("language", language);
    const httpOptions = {
      headers: headers,
      params: httpParams,
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(OOTS_API_URL + "/code", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  validateSecurityCode(uuid: string, code: string, language: string): Observable<any> {
    let httpParams = new HttpParams().append("uuid", uuid);
    if (code != null) httpParams = httpParams.append("code", code);
    if (language != null) httpParams = httpParams.append("language", language);
    const httpOptions = {
      headers: headers,
      params: httpParams,
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(OOTS_API_URL + "/validate", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteEvidence(evidenceUUID: string): Observable<any> {
    return this.http.post<any>(OOTS_API_URL + "/request/evidence/delete", evidenceUUID, { headers })
      .pipe(
        catchError(this.handleError)
      )
  }

  getRequirement(ootsRequestUUID: string, requirementId: string): Observable<any> {
    let httpParams = new HttpParams();
    if (ootsRequestUUID != null) httpParams = httpParams.append("uuid", ootsRequestUUID);
    if (requirementId != null) httpParams = httpParams.append("requirementId", requirementId);
    const httpOptions = { headers: headers, params: httpParams }
    return this.http.get<any>(OOTS_API_URL + "/request/requirement", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  as4Search(params: AS4QueryParams): Observable<any> {
    let httpParams = new HttpParams();
    if (params.requestId != null) httpParams = httpParams.append("requestId", params.requestId);
    if (params.conversationId != null) httpParams = httpParams.append("conversationId", params.conversationId);
    if (params.fromParty != null) httpParams = httpParams.append("fromParty", params.fromParty);
    if (params.toParty != null) httpParams = httpParams.append("toParty", params.toParty);
    if (params.dateFrom != null) httpParams = httpParams.append("dateFrom", params.dateFrom);
    if (params.dateTo != null) httpParams = httpParams.append("dateTo", params.dateTo);
    const httpOptions = { headers: headers, params: httpParams }
    return this.http.get(OOTS_API_URL + "/search", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  as4Download(requestId: string, type: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("type", type);
    const httpOptions = { headers: headers, params: httpParams }
    return this.http.get(OOTS_API_URL + "/search/" + requestId, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return error;
    });
  }
  saveEvidences(evidences: EvidenceSave[], uuid: string){
    return this.http.post<any>(OOTS_API_URL + "/saveEvidences?uuid=" + uuid, evidences)
    .pipe(
      catchError(this.handleError)
    )
  }
}
