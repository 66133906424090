export class PreviewSpaceResponse {
    session: string;
    errorType: ResponseErrorType;
    errorMessage: string;
}

export class PidResponseData{
    universidades: ResponseUniversidad[];
}

export class ResponseUniversidad{
    codigoUniversidad:string;
    nombreUniversidad:string;
}

export enum ResponseErrorType {
    AUTHENTICATION,
    USER_REJECT_OR_CANCEL,
    SESSION_TIMEOUT, // after 3 min
    INVALID_REQUEST
}