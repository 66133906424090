<div style="padding-top: 1rem;">

    <div *ngIf="isUserLogged; then userIdentified else userNotIdentified"></div>

    <ng-template #userNotIdentified class="col-md-6">
        <div style="position: relative; margin-bottom: 200px; padding-top: 1rem;">
            <div class="bluebox" style="background-color:  rgba(17, 77, 166, 1); padding: 2rem 2rem; ">
                <span class="title" style="color: white;padding-left: 50px;">{{'HOME.GATEWAY_FULL' |
                    translate}}</span>
                <p class="title" style="color: white; font-size: small; padding-left: 50px;">
                    {{'HOME.GATEWAY_FULL_DESC' |
                    translate}}</p>
            </div>
            <div style="position: absolute; margin-top: -50px;">
                <table>
                    <tr>
                        <td>
                            <div class="bluebox"
                                style="background-color:  white; padding: 5rem; padding-top: 3rem; margin-left: 5rem; padding-right: 5rem; text-align: justify; margin-top: -20px; ">
                                <p>{{'HOME.COPY_OF_DOCUMENTS' | translate}}
                                </p>
                                <p>
                                </p>
                            </div>
                        </td>
                        <td style="width: 375px;">
                            <img height="225; padding-right: 140px;" alt="" src="assets/dintel_resource.png" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row mt-3"
            style="background-color: #F7F7F8; position: relative; padding-left: 6em; padding-right: 6em; margin-top: -20px;">
            <h1>{{'DISCLAIMER.AGREEMENT' | translate}}</h1>

            <div class="col-md-11" style="margin-bottom: 5px;">{{'DISCLAIMER.AGREEMENT_DESC' | translate}}</div>
            <div class="col-md-11" style="margin-bottom: 5px;">{{'DISCLAIMER.AGREEMENT_DESC_1' | translate}}</div>
            <div class="col-md-11" style="margin-bottom: 10px;">{{'DISCLAIMER.AGREEMENT_DESC_2' | translate}}</div>
        </div>

        <div class="row" style="background-color: #F7F7F8; position: relative; padding-left: 6em; padding-right: 6em;">
            <div class="col d-flex align-items-center">
                <p><input type="checkbox" [(ngModel)]="isChecked" (change)="agreementChange($event)"></p>
                <p class="fw-bold">{{'DISCLAIMER.I_HAVE_READ' | translate}}</p>
            </div>
        </div>
        <div class="row evidence-info" *ngIf="requestInfo !== undefined">
            <p class="p-0 m-0 subtitle">{{'PREVIEW_SPACE.REQUEST_DATA' | translate}}</p>
            <ul>
                <li>{{'PREVIEW_SPACE.COMPETENT_AUTHORITY' | translate}}
                    <ul>
                        <li>{{'PREVIEW_SPACE.NAME' | translate}}: {{transformTexts(requestInfo.requesterName,
                            'EN')}}</li>
                        <li>{{'PREVIEW_SPACE.COUNTRY' | translate}}:
                            {{changeCountryLanguage(requestInfo.country.value ??
                            null ,'EN')}}</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>{{'PREVIEW_SPACE.PROCEDURE' | translate}}
                    <ul>
                        <li>{{'PREVIEW_SPACE.NAME' | translate}}: {{requestInfo.listRequirementNames[0]}}</li>
                        <li>{{'PREVIEW_SPACE.TYPE' | translate}}:
                            {{transformTexts(requestInfo.procedure, "EN")}}</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>{{'PREVIEW_SPACE.REQUESTED_PROOF' | translate}}
                    <ul>
                        <li>{{transformTexts(requestInfo.description ,"EN")}}</li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="row button-row justify-content-end">
            <section class="eidas-signin">
                <div class="subtitle">{{'PREVIEW_SPACE.SIGN_IN' | translate}}</div>
                <p>{{'PREVIEW_SPACE.SIGN_IN_DESC' | translate}}
                </p>
                <div class="eidas-login">
                    <app-eidas-component [isPreviewSpace]=true [session]="session"></app-eidas-component>
                    <app-eidas-callback [isPreviewSpace]=true [session]="session"
                        (redirect)="handleResponseEidas($event)"></app-eidas-callback>
                </div>
            </section>
            <section style="background-color: #E8EFFA; margin: 0px; padding: 0px; position: relative;">
                <div style="background-color: #E8EFFA; padding: 0px !important;">
                    <table style="margin-top:50px; width:100%; text-align: center; width: 100%; height: 5em; ">
                        <tr>
                            <td>
                                <a style=" background-color: white; padding:20px;text-decoration:underline; color:cornflowerblue;"
                                    href="#" tabindex="0">{{'HOME.LEARN_MORE_EID'
                                    | translate}} &rarr;</a>
                            </td>
                            <td>
                                <a style="background-color: white; padding:20px; text-decoration:underline; color:cornflowerblue;"
                                    href="#"
                                    (click)="goToLink('https://ec.europa.eu/digital-building-blocks/sites/display/DIGITAL/Once+Only+Technical+System')"
                                    tabindex="0">{{'HOME.LEARN_MORE_OOTS'
                                    | translate}} &#8594;</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </section>
        </div>

        <section *ngIf="isUserLogged" class="user-profile">
            <div class="user-label"><i class="fa-regular fa-user"></i></div>
            <div class="user-info">
                <h2>{{ currentUser.firstName }}</h2>
                <p>{{ currentUser.familyName }} &nbsp; - &nbsp; {{ currentUser.personIdentifier }}</p>
            </div>
        </section>
    </ng-template>

    <ng-template #userIdentified>
        <section *ngIf="sessionStatus === 'PENDING' || sessionStatus === 'OBTAINED'">
            <app-locate-evidence-preview *ngIf="requestInfo" [requestInfo]="requestInfo" [session]="session"
                [returnUrl]="returnUrl"></app-locate-evidence-preview>
        </section>

        <section *ngIf="sessionStatus === 'PROCESSED' || sessionStatus === 'REQUESTED'">
            <ng-container
                *ngTemplateOutlet="errorTemplate; context: { message: 'PREVIEW_SPACE.STATUS_PROCESSED' }"></ng-container>
        </section>

        <section *ngIf="sessionStatus === 'EXPIRED'">
            <ng-container
                *ngTemplateOutlet="errorTemplate; context: { message: 'PREVIEW_SPACE.STATUS_EXPIRED' }"></ng-container>
        </section>

        <section *ngIf="sessionStatus === 'NOT_FOUND'">
            <ng-container
                *ngTemplateOutlet="notFoundTemplate; context: { message: 'PREVIEW_SPACE.SESSION_NOT_FOUND' }">
            </ng-container>
        </section>

    </ng-template>

</div>

<ng-template #errorTemplate let-message="message">
    <div class="row wrong-status">
        <div class="col-md-12 ml-2" style="display: flex; align-items: center; gap: 15px;">
            <div style="flex-shrink: 0;">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                    class="bi bi-exclamation-diamond" viewBox="0 0 16 16">
                    <path
                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
                    <path
                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                </svg>
            </div>
            <div class="mt-3">
                <b>{{ 'PREVIEW_SPACE.WRONG_STATUS_TITLE' | translate }}</b>
                <p [innerHtml]="">{{ message | translate }}</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notFoundTemplate let-message="message">
    <div class="row wrong-status">
        <div class="col-md-12 ml-2" style="display: flex; align-items: center; gap: 15px;">
            <div style="flex-shrink: 0;">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                    class="bi bi-exclamation-diamond" viewBox="0 0 16 16">
                    <path
                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
                    <path
                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                </svg>
            </div>
            <div class="mt-3">
                <b>{{ 'PREVIEW_SPACE.INVALID_SESSION' | translate }}</b>
                <p [innerHtml]="">{{ message | translate }}</p>
            </div>
        </div>
    </div>
    <div class="col-md-12 d-flex justify-content-center mt-2">
        <button class="button" (click)="navigateBack()">{{'PREVIEW_SPACE.RETURN' | translate}}</button>
    </div>
</ng-template>