<div class="table-container">

    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Date Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                <td mat-cell *matCellDef="let row"> {{row.type}} </td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let row"> {{row.date | date: 'dd/MM/yyyy - hh:mm:ss a' }} </td>
            </ng-container>

            <!-- requestId Column -->
            <ng-container matColumnDef="requestId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Request ID </th>
                <td mat-cell *matCellDef="let row"> {{row.requestId}} </td>
            </ng-container>

            <!-- conversationId Column -->
            <ng-container matColumnDef="conversationId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Conversation ID </th>
                <td mat-cell *matCellDef="let row"> {{row.conversationId}} </td>
            </ng-container>

            <!-- partyFrom Column -->
            <ng-container matColumnDef="partyFrom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> From </th>
                <td mat-cell *matCellDef="let row"> {{row.partyFrom}} </td>
            </ng-container>

            <!-- partyTo Column -->
            <ng-container matColumnDef="partyTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> To </th>
                <td mat-cell *matCellDef="let row"> {{row.partyTo}} </td>
            </ng-container>

             <!-- downloadFile Column -->
             <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Files</th>
                <td mat-cell *matCellDef="let row"  aria-hidden="true">
                    <a href="#" (click)="downloadXML(row.requestId, 'as4'); $event.preventDefault();">AS4</a> | 
                    <a href="#" (click)="downloadXML(row.requestId, 'payload'); $event.preventDefault();">Payload</a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"></mat-paginator>

    </div>
</div>