import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StateService } from '../_service/state.service';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { JwtUtilsService } from '../_service/jwt-utils.service';

const OOTS_API_URL = environment.api_url + "/oots";

@Component({
  selector: 'app-user-sede',
  templateUrl: './user-sede.component.html',
  styleUrls: ['./user-sede.component.css']
})
export class UserSedeComponent implements OnInit{
  specificDatasets: any;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private stateService: StateService,
    private jwtUtilsService: JwtUtilsService) {
  }

  ngOnInit(){
    this.getSpecificDatasets();
  }

  getSpecificDatasets() {
    this.http.get<any>(environment.api_url + "/specificDataset/list",  { params: { selected: true} }).subscribe(
      response => {
        console.log(response)
        this.specificDatasets = response
      }
    );
  }

  redirectToIntermediaryApp(selectedSpecificDataset: any) {
    if (selectedSpecificDataset) this.stateService.setPreview(JSON.stringify(selectedSpecificDataset.isPreview));
    else this.stateService.setPreview(JSON.stringify(true));
    this.jwtUtilsService.saveRedirect('true');
    const datasetXml = this.formatSelectedOotsRequest(selectedSpecificDataset.xml)
    this.postRedirect(OOTS_API_URL + "/request", datasetXml);
  }

  postRedirect(path: string, xml: string): void {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = path;
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'data';
    hiddenField.value = xml;
    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
  }

  formatSelectedOotsRequest(datasetXml: string) {
    const request:string = datasetXml.replaceAll("XXXXXX", uuid()).replace("DDDDDD", this.getDate());
    let formatted = '', indent= '';
    const tab = '\t';
    request.split(/>\s*</).forEach((node: string) => {
        if (node.match( /^\/\w/ )) indent = indent.substring(tab.length);
        formatted += indent + '<' + node + '>\r\n';
        if (node.match( /^<?\w[^>]*[^/]$/ )) indent += tab;
    });
    const resultXml = formatted.substring(1, formatted.length-3);
    return resultXml;
  }
  getDate(): string {
    const currentDate = Date.now();
    return this.datePipe.transform(currentDate, 'yyyy-MM-ddThh:mm:ss') ?? "";
  }
  translateText(className: string ,key: string) {
    const translateKey = className + '.' + key;
    return this.translate.instant(translateKey);
  }
}
