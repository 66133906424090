<div class="container" style="margin-top: 20px !important;">

    <div *ngIf="isResponse === true; then response else request"></div>

    <ng-template class="formfield" #request>
        <label for="xmlContent" class="subtitle">{{'HOME.SELECT_REQUEST' | translate }}</label>
        <select autocomplete="off" class="specificDataset" [(ngModel)]="selectedSpecificDataset" (change)="formatSelectedOotsRequest()">
            <option *ngFor="let item of specificDatasets" [ngValue]="item">
                {{item.type}} - {{item.evidenceTitle}} - {{item.countryCode}} {{item.info}}
            </option>
        </select>

        <label for="xmlContent" class="subtitle">XML REQUEST</label>
        <textarea class="xmlContent" [(ngModel)]="ootsRequestXML" name="ootsRequestXML"></textarea>
        <div><app-loader-button (buttonClicked)="redirectToIntermediaryApp()"
                text="Retrieve from OOTS"></app-loader-button></div>
    </ng-template>

    <ng-template class="formfield" #response>
        <label for="xmlContent">XML RESPONSE</label>
        <textarea class="xmlContent" [(ngModel)]="ootsResponseXML" name="ootsResponseXML"
            style="height: 600px;"></textarea>
        <section>
            <div class="row">
                <div class="col-md-9">
                </div>
                <div class="col-md-3">
                    <div style="display: flex; justify-content: flex-end;">
                        <button class="mt-2" (click)="shareWithSede()">{{'HOME.SHARE_WITH_SEDE' | translate
                            }}</button>
                    </div>
                </div>
            </div>
        </section>
    </ng-template>

</div>