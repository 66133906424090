<div class="container" style="margin-top: 20px !important;">

    <div class="bluebox">
        <span class="title">{{'PROJECTATHON.HEADER' | translate}}</span>
    </div>

    <label for="requirements" class="subtitle">1. {{ 'PROJECTATHON.REQUIREMENT_SELECT' | translate }}</label>
    <select autocomplete="off" class="requirementsDataset" [(ngModel)]="selectedRequirement">
        <option *ngFor="let requirement of requirementsDataset" [ngValue]="requirement">
            {{requirement.description}} [ {{requirement.requirement}} ]
        </option>
    </select>

    <div *ngIf="selectedRequirement">
        <div *ngIf="selectedRequirement.description === 'Custom requirement' ">
            <input type="text" [(ngModel)]="selectedRequirement.requirement">
        </div>
        <label for="user" class="subtitle">2. {{ 'PROJECTATHON.USER_DATA.HEADER' | translate }}</label><br>

        <div class="row mt-3">
            <div class="col-md-6">
                <label for="user" class="subtitle">{{ 'PROJECTATHON.USER_DATA.NAME' | translate }}</label>
                <input type="text" placeholder="{{ 'PROJECTATHON.USER_DATA.NAME' | translate }}" style="height: 30px;" [(ngModel)]="name">
            </div>

            <div class="col-md-6">
                <label for="user" class="subtitle">{{ 'PROJECTATHON.USER_DATA.FAMILY_NAME' | translate }}</label>
                <input type="text" placeholder="{{ 'PROJECTATHON.USER_DATA.FAMILY_NAME' | translate }}" style="height: 30px;" [(ngModel)]="familyName">
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-6">
                <label for="user" class="subtitle">{{ 'PROJECTATHON.USER_DATA.EIDAS_ID' | translate }}</label>
                <input type="text" placeholder="{{ 'PROJECTATHON.USER_DATA.EIDAS_ID' | translate }}" style="height: 30px;" [(ngModel)]="eidasID" (keyup)="clearMessage()">
            </div>

            <div class="col-md-6">
                <label for="user" class="subtitle">{{ 'PROJECTATHON.USER_DATA.DOB' | translate }}</label>
                <input type="text" placeholder="{{ 'PROJECTATHON.USER_DATA.DOB' | translate }}" style="height: 30px;" [(ngModel)]="dob" (keyup)="clearMessage()">
            </div>
        </div>

    </div>

    <div *ngIf="isValidUserData()">
        <div class="row mt-3">
            <div class="col-md-12">
                <label for="isPreview">3. {{ 'PROJECTATHON.PREVIEW' | translate }}</label>
                <input type="checkbox" [(ngModel)]="isPreview" style="vertical-align: text-bottom; margin-left: 10px;">
            </div>
        </div>
    </div>

    <div class="row form-invalid mt-3" *ngIf="validationMessage !== ''">
        <div class="col-md-12 mt-2 pt-2">
            <table>
                <tr>
                    <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C44727"
                            class="bi bi-exclamation-octagon-fill fa-lg right-margin" viewBox="0 0 16 16">
                            <path
                                d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg></td>
                    <td class="pb-3"><span class="error-msg">{{ validationMessage }}</span></td>
                </tr>
            </table>
        </div>
    </div>

    <div *ngIf="isValidForm()">
        <div class="row mt-5 mb-4">
            <div class="col-md-12">
                <button class="continue-button" (click)="continue()">{{ 'PROJECTATHON.CONTINUE' | translate }}</button>
            </div>
        </div>
    </div>

    <app-loading-screen [isLoading]="redirecting" [message]="'Loading...'"></app-loading-screen>

</div>