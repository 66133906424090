export enum OOTSRequirements {
  PROOF_OF_BIRTH = "PROOF_OF_BIRTH",
  PROOF_OF_BIRTH_OF_CHILD = "PROOF_OF_BIRTH_OF_CHILD",
  PROOF_OF_MARRIAGE = "PROOF_OF_MARRIAGE",
  PROOF_OF_DISSOLUTION_OF_MARRIAGE = "PROOF_OF_DISSOLUTION_OF_MARRIAGE",
  PROOF_OF_NATIONALITY = "PROOF_OF_NATIONALITY",
  PROOF_OF_RESIDENCE_DOMICILE = "PROOF_OF_RESIDENCE_DOMICILE",
  PROOF_OF_RESIDENCE_ALL_ADDRESSES = "PROOF_OF_RESIDENCE_ALL_ADDRESSES",
  PROOF_OF_DISABILITY = "PROOF_OF_DISABILITY",
  PROOF_OF_DISABILITY_DEGREE_50 = "PROOF_OF_DISABILITY_DEGREE_50",
  PROOF_OF_DISABILITY_PARENT = "PROOF_OF_DISABILITY_PARENT",
  PROOF_OF_MARITAL_STATUS = "PROOF_OF_MARITAL_STATUS",
  PROOF_OF_ADMISSION_TERTIARY_EDUCATION = "PROOF_OF_ADMISSION_TERTIARY_EDUCATION",
  PROOF_OF_LEGAL_PERSON = "PROOF_OF_LEGAL_PERSON",
  PROOF_OF_TERTIARY_EDUCATION_DIPLOMA = "PROOF_OF_TERTIARY_EDUCATION_DIPLOMA",
  PROOF_OF_TERTIARY_EDUCATION_DIPLOMA_SUPPLEMENT = "PROOF_OF_TERTIARY_EDUCATION_DIPLOMA_SUPPLEMENT",
  PROOF_ENROLMENT_TERTIARY_EDUCATION_INSTUTION = "PROOF_ENROLMENT_TERTIARY_EDUCATION_INSTUTION",
  PROOF_OF_QUALIFICATION_LEVEL_OF_TERTIARY_EDUCATION = "PROOF_OF_QUALIFICATION_LEVEL_OF_TERTIARY_EDUCATION",
  PROOF_OF_CHANGE_FAMILY_NAME = "PROOF_OF_CHANGE_FAMILY_NAME",
  PROOF_OF_ROADWORTHINESS = "PROOF_OF_ROADWORTHINESS",
  PROOF_OF_MILITARY_SERVICE = "PROOF_OF_MILITARY_SERVICE",
  PROOF_OF_HIGH_PERFORMANCE_ATHLETE = "PROOF_OF_HIGH_PERFORMANCE_ATHLETE",
  PROOF_OF_CUSTODY_OF_CHILD = "PROOF_OF_CUSTODY_OF_CHILD",
  PROOF_OF_MOBILITY_PERIOD = "PROOF_OF_MOBILITY_PERIOD",
  PROOF_OF_SECONDARY_EDUCATION_DIPLOMA = "PROOF_OF_SECONDARY_EDUCATION_DIPLOMA",
  PROOF_OF_CITIZENSHIP = "PROOF_OF_CITIZENSHIP",
  PROOF_OF_HOUSEHOLD_COMPOSITION = "PROOF_OF_HOUSEHOLD_COMPOSITION",
  PROOF_OF_DEATH_PARENT = "PROOF_OF_DEATH_PARENT",
  PROOF_OF_DEATH_CHILD = "PROOF_OF_DEATH_CHILD",
  PROOF_OF_DEATH_SIBLING = "PROOF_OF_DEATH_SIBLING",
  TEST_REQUIREMENT = "TEST_REQUIREMENT"
}

  