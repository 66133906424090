import { Component} from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-politica-privacidad',
  templateUrl: './politica-privacidad.component.html',
  styleUrls: ['./politica-privacidad.component.css']
})
export class PoliticaPrivacidadComponent {

  constructor(private location: Location) {}
  
  return(){
    this.location.back();
  }

}
