import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { SpecificDataComponent } from '../specific-data.component';
import { Select2Data, Select2Module } from 'ng-select2-component';
import { FormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-cpm',
  templateUrl: './cpm.component.html',
  styleUrls: ['./cpm.component.css'],
  imports: [CommonModule, FormsModule, Select2Module, TranslateModule],
})
export class CpmComponent extends SpecificDataComponent implements OnInit {

  @Input() comunidad: boolean = false;
  @Input() provincia: boolean = false;
  @Input() municipio: boolean = false;

  cpmData : CpmData;
  jsonData: any;
  comunidades: Comunidad[] = [];
  provincias: Provincia[] = [];
  municipios: Municipio[] = [];
  comunidadesData: Select2Data = [];
  provinciasData: Select2Data = [];
  municipiosData: Select2Data = [];
  spanishCitizen: boolean | null = null;

  selectedComunidad: Comunidad | null = null;
  selectedProvincia: Provincia | null = null;
  selectedMunicipio: Municipio | null = null;

  regex = /^[A-Za-z]{2}\/[A-Za-z]{2}\//;

  cpmForm: UntypedFormGroup = new UntypedFormGroup({
    comunidad: new UntypedFormControl,
    provincia: new UntypedFormControl,
    municipio: new UntypedFormControl
  })

  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit(): void {
    this.cpmData = new CpmData();
    // Cargar los datos del JSON
    this.http.get<any>('assets/cpm.json').subscribe(response => {
        this.jsonData = response;
        if(response.comunidades) this.comunidadesData = response.comunidades.map((comunidad:Comunidad) => ({
          value: comunidad,
          label: comunidad.nombre,
        }));
        //this.comunidades = response.comunidades;
      }
    );
  }

  onComunidadChange(): void {
    const comunidad = this.cpmForm.controls['comunidad'].getRawValue() as Comunidad;
    if (comunidad) {
      this.selectedProvincia = null;
      this.selectedMunicipio = null;
      this.selectedComunidad = comunidad;
      this.listProvincias(comunidad);

      // TODO Autoseleccionar la provincia si solo hay una ()
       
      this.municipiosData = [];
      this.selectedProvincia = null;
      this.selectedMunicipio = null;

      this.cpmData.codeComunidad = this.selectedComunidad.codigo;
      this.emitValueChange(this.cpmData);
    }
  }

  onProvinciaChange(): void {
    const provincia = this.cpmForm.controls['provincia'].getRawValue() as Provincia;
    this.cpmData.codeProvincia = null;
    this.cpmData.codeMunicipio = null;
    if (provincia) {
      this.selectedProvincia = provincia;
      this.listMunicipios(this.selectedProvincia)
      this.selectedMunicipio = null;
      this.cpmData.codeProvincia = this.selectedProvincia.codigo;
      this.emitValueChange(this.cpmData);
    }
  }

  onMunicipioChange(): void {
    const municipio = this.cpmForm.controls['municipio'].getRawValue() as Municipio;
    this.selectedMunicipio = municipio;
    this.cpmData.codeMunicipio = this.selectedMunicipio.codigo;
    this.emitValueChange(this.cpmData);
  }

  listProvincias(comunidad: Comunidad){
    if(comunidad.provincias) this.provinciasData = comunidad.provincias.map((provincia:Provincia) => ({
      value: provincia,
      label: provincia.nombre,
    }));
  }

  listMunicipios(provincia: Provincia){
    if(provincia.municipios)  this.municipiosData = provincia.municipios.map((municipio:Municipio) => ({
      value: municipio,
      label: municipio.nombre,
    }));
  }

  override isValid(): boolean {
    let isValid: boolean = true;
      if(this.comunidad && this.selectedComunidad === null) isValid = false;
      if(this.provincia && this.selectedProvincia === null) isValid = false;
      if(this.municipio && this.selectedMunicipio === null) isValid = false;
      return isValid;
  }

  override getValue(): any {
        return this.cpmData;
  }

  reset() {
    this.cpmForm.reset();
    this.municipiosData = [];
    this.provinciasData = [];
    this.selectedMunicipio = null;
    this.selectedProvincia = null;
    this.selectedComunidad = null;
    this.cpmData = new CpmData();
    this.emitValueChange(this.cpmData);
  }
  
}

interface Municipio {
  codigo: string;
  nombre: string;
}

interface Provincia {
  codigo: string;
  nombre: string;
  municipios?: Municipio[];
}

interface Comunidad {
  codigo: string;
  nombre: string;
  provincias?: Provincia[];
}

export class CpmData  {
  codeComunidad: string | null = null;
  codeProvincia: string | null = null;
  codeMunicipio: string | null = null;
}