<div class="table-container" *ngIf="loggedUser">
    <div class="bluebox">
        <span class="title">{{capitalize(section) + ' management'}} </span>
    </div>

    <div class="hamburger-menu">
        <button [ngClass]="menuShow ? 'menu__show': 'menu__btn'" (click)="menuShow = !menuShow">
            <span></span>
        </button>


        <ul class="menu__box" [ngClass]="{'open__box': menuShow}">
            <li *ngIf="loggedUser !== undefined">
                <button class="menu__item" (click)="initWhitelist()">Whitelist</button>
            </li>
            <li *ngIf="loggedUser !== undefined && loggedUser.role === 'ROLE_ADMIN'">
                <button class="menu__item" (click)="initProperties()">Properties</button>
            </li>
            <!--             <li *ngIf="loggedUser !== undefined">
                <button class="menu__item" (click)="initProperties()">Properties</button>
            </li> -->
            <li *ngIf="loggedUser !== undefined">
                <button class="menu__item" (click)="initServices(); menuShow=false; loadedData=false">Services</button>
            </li>
            <li *ngIf="loggedUser !== undefined">
                <button class="menu__item" (click)="initTemplate()">Templates</button>
            </li>
        </ul>
    </div>

    <div class="but-holder">
        <button (click)="logout()">Logout</button>
    </div>
    <div *ngIf="section === 'whitelist' && loadedData" class="mat-elevation-z8 section">
        <mat-form-field class="filter">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row">
                    <div>{{row.id}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Url </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">{{row.url}}</div>
                    <div *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')"><input type="text"
                            [(ngModel)]="urlInput"></div>
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Is active </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">{{row.active}}
                    </div>
                    <div *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')">
                        <select [(ngModel)]="isActive">
                            <option value=true>True</option>
                            <option value=false>False</option>
                        </select>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))"><button
                            [disabled]="visibleInputs"
                            (click)="row.actions = 'edit'; visibleInputs = true; urlInput=row.url; isActive=row.active;">Edit</button>
                        <button [disabled]="visibleInputs" (click)="delete(row.id)">Delete</button>
                    </div>
                    <div *ngIf="row.actions && row.actions === 'edit'"><button (click)="saveEdit(row.id)">Save</button>
                        <button (click)="row.actions = undefined; visibleInputs = false;">Cancel</button>
                    </div>
                    <div *ngIf="row.actions && row.actions === 'new'"><button (click)="save()">Save</button> <button
                            (click)="cancelSave()">Cancel</button></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [disabled]="visibleInputs" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"></mat-paginator>
        <button (click)="setSaving()">Add</button>
    </div>
    <!-- <div *ngIf="section === 'properties'" class="mat-elevation-z8"> -->
    <div *ngIf="section === 'properties' && loggedUser.role === 'ROLE_ADMIN'" class="mat-elevation-z8 section">
        <div class="pt-4 pb-2 admin-tools">
            <table>
                <tr>
                    <th>
                        <p class="mb-2">Scheduler (cron or ms)</p>
                    </th>
                    <th>

                    </th>
                    <th>
                        <p class="mb-2">Scheduler Purge (cron or ms)</p>
                    </th>
                </tr>
                <tr>
                    <td><input type="text" [(ngModel)]="scheduler"></td>
                    <td><button (click)="changeScheduler('MessageRetrieverService')">Change</button></td>
                    <td><input type="text" [(ngModel)]="purgeScheduler"></td>
                    <td><button (click)="changeScheduler('DBCleanerService')">Change purge</button></td>
                </tr>
            </table>
        </div>
        <div class="pt-4 pb-2 admin-tools" *ngIf="levelsForm">

            <form [formGroup]="levelsForm" class="m-0 p-0">
                <table>
                    <tr>
                        <th>
                            <p class="mb-2">Logging level</p>
                        </th>
                    </tr>
                    <tr>
                        <select *ngIf="levelsForm.controls['levelControl']" formControlName="levelControl"
                            (change)="logLevelMethods(levelsForm.controls['levelControl'].value)">
                            <option [value]="level" *ngFor="let level of levels">{{level}}</option>
                        </select>
                    </tr>
                </table>

            </form>
        </div>

        <mat-form-field class="filter">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilterProperties($event)" placeholder="" #input>
        </mat-form-field>

        <div class="tabs">
            <button class="tab" *ngFor="let type of types; let i = index;" [ngClass]="{'selected-tab': type.selected}"
                (click)="changeType(i)">
                <span>{{capitalize(type.name)}}</span>
            </button>
        </div>
        <table mat-table [dataSource]="dataSourceProperties" matSort class="mt-3">

            <ng-container matColumnDef="propertyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Property name </th>
                <td mat-cell *matCellDef="let row">
                    <div>{{row.propertyName}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Property value </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">{{row.value}}</div>
                    <div *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')"><input type="text"
                            [(ngModel)]="propertyValue"></div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                <td mat-cell *matCellDef="let row ; let i = index">
                    <div *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))"><button
                            [disabled]="visibleInputsProperties"
                            (click)="row.actions = 'edit'; visibleInputsProperties = true;">Edit</button></div>
                    <div *ngIf="row.actions && row.actions === 'edit'"><button
                            (click)="saveEditedProperty(i)">Save</button> <button
                            (click)="row.actions = undefined; visibleInputsProperties = false;">Cancel</button></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsProperties"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsProperties;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
        <mat-paginator [disabled]="visibleInputsProperties" [pageSizeOptions]="[5, 10, 25, 100]"
            [pageSize]="5"></mat-paginator>
    </div>
    <div *ngIf="section==='services'" class="section">
        <div class="side-by-side">
            <div class="main-section">
                <div *ngFor="let serviceType of serviceTypesLeft">
                    <div class="data-table">
                        <div class="mini-bluebox">
                            <div>
                                <button
                                    [ngClass]="servicesStates.get(serviceType).state === 'open' ? 'non-visible' : 'action-button-white'"
                                    (click)="openListByType(serviceType)"><i class="bi bi-plus"></i></button>
                                <button
                                    [ngClass]="servicesStates.get(serviceType).state === 'open' ? 'action-button-white': 'non-visible'"
                                    (click)="closeTable(serviceType)"><i class="bi bi-dash"></i></button>
                                <span class="mini-title">{{'MANAGEMENT.SERVICE_TYPE.' + translateAttrNames(serviceType)
                                    | translate}}</span>
                            </div>
                            <input placeholder="{{'MANAGEMENT.FILTER' | translate}}" class="filter-input"
                                *ngIf="servicesStates.get(serviceType).state === 'open'"
                                (keyup)="applyFilterService($event, serviceType)" placeholder="" #input>
                        </div>
                        <div
                            [ngClass]="servicesStates.get(serviceType).state === 'open' ? 'table-holder': 'non-visible'">
                            <table mat-table [dataSource]="dataSourceServices.get(serviceType) ?? dataSourceProperties"
                                matSort class="mt-3">
                                <ng-container [matColumnDef]="col" *ngFor="let col of servicesDataMap.get(serviceType)">
                                    <div *ngIf="col==='id'">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Id
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row[col]}}
                                        </td>
                                    </div>
                                    <div *ngIf="col!=='id' && col!=='actions'">
                                        <th mat-header-cell *matHeaderCellDef>
                                            {{'MANAGEMENT.' + translateAttrNames(col) | translate}}
                                        </th>

                                        <td mat-cell *matCellDef="let row">
                                            <div
                                                *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">
                                                {{row[col]}}</div>
                                            <div
                                                *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')">
                                                <input type="text" [(ngModel)]="editedEntry[col]"></div>
                                        </td>
                                    </div>
                                    <div *ngIf="col==='actions'">
                                        <th mat-header-cell *matHeaderCellDef>
                                            {{'MANAGEMENT.' + translateAttrNames(col) | translate}}
                                        </th>
                                        <td mat-cell *matCellDef="let row ;">
                                            <div
                                                *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">
                                                <button class="action-button" [disabled]="visibleInputsProperties"
                                                    (click)="row.actions = 'edit'; setServiceEditedEntry(row)"><i
                                                        class="bi bi-pencil-fill"></i></button>
                                            </div>
                                            <div *ngIf="row.actions && row.actions === 'edit'"><button
                                                    class="action-button"
                                                    (click)="saveServiceProperty(serviceType, editedEntry)"><i
                                                        class="bi bi-check2"></i></button> <button class="action-button"
                                                    (click)="row.actions = undefined; visibleInputsProperties = false;"><i
                                                        class="bi bi-x"></i></button></div>
                                        </td>
                                    </div>


                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="servicesDataMap.get(serviceType)"></tr>
                                <tr mat-row *matRowDef="let row; columns: servicesDataMap.get(serviceType);"></tr>
                            </table>
                            <mat-paginator [disabled]="visibleInputsProperties" [pageSizeOptions]="[5, 10, 25, 100]"
                                [pageSize]="5"></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
            <div class="side-section">
                <div *ngFor="let serviceType of serviceTypesRight">
                    <div class="data-table">
                        <div class="mini-bluebox">
                            <div>
                                <button
                                    [ngClass]="servicesStates.get(serviceType).state === 'open' ? 'non-visible' : 'action-button-white'"
                                    (click)="openListByType(serviceType)"><i class="bi bi-plus"></i></button>
                                <button
                                    [ngClass]="servicesStates.get(serviceType).state === 'open' ? 'action-button-white': 'non-visible'"
                                    (click)="closeTable(serviceType)"><i class="bi bi-dash"></i></button>
                                <span class="mini-title">{{'MANAGEMENT.SERVICE_TYPE.' + translateAttrNames(serviceType)
                                    | translate}}</span>
                            </div>
                            <input placeholder="{{'MANAGEMENT.FILTER' | translate}}" class="filter-input"
                                *ngIf="servicesStates.get(serviceType).state === 'open'"
                                (keyup)="applyFilterService($event, serviceType)" placeholder="" #input>
                        </div>
                        <div
                            [ngClass]="servicesStates.get(serviceType).state === 'open' ? 'table-holder': 'non-visible'">
                            <table mat-table [dataSource]="dataSourceServices.get(serviceType) ?? dataSourceProperties"
                                matSort class="mt-3">
                                <ng-container [matColumnDef]="col" *ngFor="let col of servicesDataMap.get(serviceType)">
                                    <div *ngIf="col==='id'">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Id
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row[col]}}
                                        </td>
                                    </div>
                                    <div *ngIf="col!=='id' && col!=='actions'">
                                        <th mat-header-cell *matHeaderCellDef>
                                            {{'MANAGEMENT.' + translateAttrNames(col) | translate}}
                                        </th>

                                        <td mat-cell *matCellDef="let row">
                                            <div
                                                *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit')) && row[col].description">
                                                {{row[col].id}}: {{row[col].description}}</div>
                                            <div
                                                *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit')) && row[col].serviceName">
                                                {{row[col].id}}: {{row[col].serviceName}}</div>
                                            <div
                                                *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit')) && !row[col].serviceName && !row[col].description">
                                                {{row[col]}}</div>
                                            <div
                                                *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')">
                                                <div *ngIf="checkType(row[col], 'boolean'); else other">
                                                    <select [(ngModel)]="editedEntry[col]">
                                                        <option value=true>
                                                            true
                                                        </option>
                                                        <option value=false>
                                                            false
                                                        </option>
                                                    </select>

                                                </div>
                                                <ng-template #other>
                                                    <select2
                                                        [data]="dataSourceSelectData.get(getServiceTypeByAttr(col)) ?? []"
                                                        class="select2" (update)="setSelectedOptionData($event, col)">
                                                    </select2>
                                                </ng-template>
                                            </div>
                                        </td>
                                    </div>
                                    <div *ngIf="col==='actions'">
                                        <th mat-header-cell *matHeaderCellDef>
                                            {{'MANAGEMENT.' + translateAttrNames(col) | translate}}
                                        </th>

                                        <td mat-cell *matCellDef="let row ;">
                                            <div
                                                *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">
                                                <button class="action-button" [disabled]="visibleInputsProperties"
                                                    (click)="row.actions = 'edit'; setServiceEditedEntry(row, 'select', serviceType)"><i
                                                        class="bi bi-pencil-fill"></i></button>
                                                <button class="action-button" [disabled]="visibleInputsProperties"
                                                    (click)="deleteServiceEntry(serviceType, row.id)"><i
                                                        class="bi bi-trash-fill"></i></button>
                                            </div>
                                            <div *ngIf="row.actions"><button class="action-button"
                                                    (click)="saveServiceProperty(serviceType, editedEntry)"><i
                                                        class="bi bi-check2"></i></button> <button class="action-button"
                                                    (click)="row.actions = undefined; visibleInputsProperties = false;"><i
                                                        class="bi bi-x"></i></button></div>
                                            <div *ngIf="row.actions && row.actions === 'new'"><button
                                                    class="action-button"
                                                    (click)="saveServiceProperty(serviceType, editedEntry)"><i
                                                        class="bi bi-check2"></i></button> <button class="action-button"
                                                    (click)="cancelSaveService(serviceType)"><i
                                                        class="bi bi-x"></i></button></div>
                                        </td>
                                    </div>


                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="servicesDataMap.get(serviceType)"></tr>
                                <tr mat-row *matRowDef="let row; columns: servicesDataMap.get(serviceType);"></tr>
                            </table>
                            <mat-paginator [disabled]="visibleInputsProperties" [pageSizeOptions]="[5, 10, 25, 100]"
                                [pageSize]="5"></mat-paginator>
                            <div class="but-holder">
                                <button (click)="setServiceSavingEntry(serviceType)">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="section==='template'" class="section">
        <mat-form-field class="filter">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row">
                    <div>{{row.id}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="serviceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'MANAGEMENT.SERVICE_NAME' | translate}} </th>
                <td mat-cell *matCellDef="let row">
                    <div>{{row.serviceName}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                    {{'MANAGEMENT.ACTIONS' | translate}}
                </th>
                <td mat-cell *matCellDef="let row ; let i = index;">
                    <div *ngIf="row.actions && (row.actions === 'new' || row.actions === 'edit')">
                        <input type="file" (change)="changeTemplate($event)">
                    </div>
                    <div
                        *ngIf="!(row.actions && (row.actions === 'new' || row.actions === 'edit'))">
                        <button class="action-button" [disabled]="visibleInputsProperties"
                            (click)="row.actions = 'edit'; setTemplateData(i)"><i
                                class="bi bi-pencil-fill"></i></button>
                    </div>
                    <div *ngIf="row.actions && row.actions === 'edit'"><button
                            class="action-button"
                            (click)="updateTemplate()"><i
                                class="bi bi-check2"></i></button> <button class="action-button"
                            (click)="row.actions = undefined; visibleInputsProperties = false;"><i
                                class="bi bi-x"></i></button></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsTemplates"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsTemplates;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [disabled]="visibleInputs" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"></mat-paginator>
    </div>
</div>
<div class="custom-alert" *ngIf="popUp.show">
    <p class="title-alert">{{popUp.title}}</p>
    <p class="message-alert">{{popUp.message}}</p>
</div>