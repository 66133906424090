<div class="step radio-container">
    <label for="identity">Es usted ciudadano español?</label>
    <div class="radio-group">
        <input type="radio" id="spanishYes" name="spanishCitizen" [(ngModel)]="specificData.spanishCitizen" [value]="true" (change)="onChange()">
        <label for="spanishYes">Si</label>
    </div>
    <div class="radio-group">
        <input type="radio" id="spanishNo" name="spanishCitizen" [(ngModel)]="specificData.spanishCitizen" [value]="false" (change)="onChange()">
        <label for="spanishNo">No</label>
    </div>
</div>

<div class="municipality-container">
    <div><label *ngIf="specificData.spanishCitizen === true" for="ambito">Seleccione su municipio de nacimiento</label></div>
    <div><label *ngIf="specificData.spanishCitizen === false" for="ambito">Seleccione su municipio de residencia</label></div>
    <div><app-cpm [comunidad]=true [provincia]=true [municipio]=true></app-cpm></div>
</div>