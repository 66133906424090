import { Component, OnInit, ViewChild } from '@angular/core';
import { CpmComponent } from '../../cpm/cpm.component';
import { SpecificDataComponent } from '../../specific-data.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-convivencia-form',
  standalone: true,
  imports: [TranslateModule, CpmComponent],
  templateUrl: './convivencia-form.component.html',
  styleUrl: './convivencia-form.component.css'
})
export class ConvivenciaFormComponent extends SpecificDataComponent implements OnInit{
  @ViewChild(CpmComponent) cpmComponent: CpmComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.specificData.fechaHechoRegistral = new Date().toISOString().split('T')[0];
  }

  override isValid(): boolean {
    if (this.specificData.fechaHechoRegistral === null) return false;
    if(this.cpmComponent && !this.cpmComponent.isValid()) return false;
    return true;
  }

  override getValue() {
    this.specificData.codeComunidad = this.cpmComponent.selectedComunidad?.codigo;
    this.specificData.codeProvincia = this.cpmComponent.selectedProvincia?.codigo;
    this.specificData.codeMunicipio = this.cpmComponent.selectedMunicipio?.codigo;
    return this.specificData;
  }
}
