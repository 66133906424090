import { Component, Input, OnInit } from '@angular/core';
import { OotsService } from 'src/app/_service/oots.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {
  
  @Input() tdd = false;
  @Input() app = false;

  appVersion: string;
  tddVersion: string;

  constructor(
    private ootsService: OotsService
  ) {}
  
  ngOnInit(): void {
    this.ootsService.getVersion().subscribe(
      {
        next: response => {
          this.appVersion = response.app.replace("-SNAPSHOT", "");
          this.tddVersion = response.tdd;
        },
        error: (e) => {
          console.error(e);
        }
      });
  }

}
