import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, throwError, timer, interval, Subscription } from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const OOTS_API_URL = environment.api_url + "/oots";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root',
})
export class SessionTimerService implements OnDestroy {

  private isInitialized = false;
  private remainingTimeSubject = new BehaviorSubject<number>(0);
  remainingTime$: Observable<number> = this.remainingTimeSubject.asObservable();

  private uuid = new BehaviorSubject<string>('');
  uuid$: Observable<string> = this.uuid.asObservable();

  private sessionDataSubject = new BehaviorSubject<SessionData>(new SessionData());
  sessionData$: Observable<SessionData> = this.sessionDataSubject.asObservable();

  private timerExpired = new BehaviorSubject<SessionData>(new SessionData());
  isTimerExpired$: Observable<SessionData> = this.timerExpired.asObservable();

  private timerSubscription: Subscription;

  private simpleSessionRemainingTimeSubject = new BehaviorSubject<number>(0);
  simpleSessionRemainingTime$: Observable<number> = this.simpleSessionRemainingTimeSubject.asObservable();

  constructor(private http: HttpClient) {}

  notifyTimerExpired(data: SessionData) {
    this.timerExpired.next(data);
  }

  set requestUUID(uuid: string) {
    this.uuid.next(uuid);
  }

  get requestUUID(): string {
    return this.uuid.getValue();
  }

  get sessionData(): SessionData {
    return this.sessionDataSubject.getValue();
  }

  initializeTimer(sessionData: SessionData) {
    if (!this.isInitialized) {
      this.remainingTimeSubject.next(sessionData.time);
      this.sessionDataSubject.next(sessionData);

      const interval = setInterval(() => {
        const remainingTime = this.remainingTimeSubject.value;
        if (remainingTime > 15) {
          this.remainingTimeSubject.next(remainingTime - 1);
        } else {
          clearInterval(interval);
          this.sessionData.expired = true;
            this.sessionData.timerType = "server";
            this.notifyTimerExpired(this.sessionData);
        }
      }, 1000);

      this.isInitialized = true;
    }
  }

  getSessionData(uuid: string): Observable<any> {
    const httpParams = new HttpParams().append("uuid", uuid);
    const httpOptions = {
      headers: headers,
      params: httpParams
    };

    return this.http.get<any>(OOTS_API_URL + "/session", httpOptions)
      .pipe(catchError(this.handleError));
  }

  initializeSimpleTimer(sessionTimeSeconds: number) {
    const storedTime = localStorage.getItem('session-remaining-time');
    const initialTime = storedTime ? parseInt(storedTime, 10) : sessionTimeSeconds;
    this.sessionDataSubject.next(this.sessionData);
    this.simpleSessionRemainingTimeSubject.next(initialTime);

    timer(0, 1000)
      .pipe(
        takeWhile(() => this.simpleSessionRemainingTimeSubject.value > 0),
        map(() => this.simpleSessionRemainingTimeSubject.value - 1)
      )
      .subscribe((remainingTime) => {
        this.simpleSessionRemainingTimeSubject.next(remainingTime);
        localStorage.setItem('session-remaining-time', remainingTime.toString());
        if (remainingTime == 0) {
          this.endSimpleSession();
        }
      });
  }

  endSimpleSession() {
    localStorage.removeItem('session-remaining-time');
    this.sessionData.expired = true;
    this.sessionData.timerType = "client";
    this.notifyTimerExpired(this.sessionData);
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    return throwError(() => error);
  }
}

export class SessionData {
  uuid: string;
  time: number;
  returnUrl: string;
  expired: boolean;
  timerType: string;
}
