<div>
    <div *ngIf="isUserLogged; then userIdentified else userNotIdentified"></div>

    <ng-template #userNotIdentified>
        <div style="position: relative; padding-top: 1rem;">
            <div class="bluebox" style="background-color:  rgba(17, 77, 166, 1); ">
                <span class="title" style="color: white; margin-bottom:auto;">{{'HOME.GATEWAY_FULL' |
                    translate}}</span>
                <p class="title" style="color: white; font-size: small;">
                    {{'HOME.GATEWAY_FULL_DESC' |
                    translate}}</p>
            </div>
           <!--  <div style="position: absolute; margin-top: -50px;">
                <table>
                    <tr>
                        <td>
                            <div class="text-content">
                                <p>{{'HOME.COPY_OF_DOCUMENTS' | translate}}
                                </p>

                            </div>
                        </td>
                        <td class="image-content">
                            <img height="225; padding-right: 140px;" alt="" src="assets/dintel_resource.png" />
                        </td>
                    </tr>
                </table>
            </div> -->
            <div class="copy-of-documents-container">
                <div class="content-wrapper">
                    <div class="text-content">
                        <p>{{'HOME.COPY_OF_DOCUMENTS' | translate}}</p>
                    </div>
                    <div class="image-content">
                        <img class="responsive-img" alt="" src="assets/dintel_resource.png" />
                    </div>
                </div>
            </div> 
        </div>
        <div class="agreement-container">
            <h1 class="title">{{'DISCLAIMER.AGREEMENT' | translate}}</h1>

            <div class="agreement-content" style="margin-bottom: 5px;">{{'DISCLAIMER.AGREEMENT_DESC' | translate}}</div>
            <div class="agreement-content" style="margin-bottom: 5px;">{{'DISCLAIMER.AGREEMENT_DESC_1' | translate}}</div>
            <div class="agreement-content" style="margin-bottom: 10px;">{{'DISCLAIMER.AGREEMENT_DESC_2' | translate}}</div>
            <div class="d-flex">
                <input type="checkbox" [(ngModel)]="isChecked" (change)="agreementChange($event)">
                <p class="fw-bold mb-0 mt-1">{{'DISCLAIMER.I_HAVE_READ' | translate}}</p>
                
            </div>
        </div>

       

        <div class="eidas-signin">

            <section class="borrador-container">

                <div class="subtitle">{{'HOME.SIGN_IN' | translate}}</div>
                <p>{{'HOME.SIGN_IN_DESC' | translate}}
                </p>
                <div class="row mt-1 mb-2">
                    <div class="col-md-11">{{'HOME.CONTINUATION_TOKEN' | translate }}
                    </div>
                    <div class="col-md-6">
                        <input type="text" style="height: 30px;" [(ngModel)]="continuationToken" placeholder="{{'HOME.ENTER_TOKEN' |
                        translate}}"
                            (input)="continuationTokenChange($event)" (focus)="continuationTokenChange($event)">
                    </div>
                    <div class="col-md-6"></div>
                </div>
                <div class="col-md-11 row" style="margin-bottom: 10px; margin-top: 10px;">{{'HOME.LOGIN_AGREEMENT_POLICY' |
                    translate}}</div>

                <div class="eidas-button">
                    <app-eidas-callback [isPreviewSpace]=false [uuid]="uuid"></app-eidas-callback>
                    <app-eidas-component [isPreviewSpace]=false [uuid]="uuid"></app-eidas-component>
                </div>

            </section>

            <section style="background-color: #E8EFFA; margin: 0px; padding: 0px; position: relative;">
                <div style="background-color: #E8EFFA; padding: 0px !important;">
                        <div class="learn-more-container">
                            
                                <button class="learn-more-button" href="#"
                                    tabindex="0">{{'HOME.LEARN_MORE_EID'
                                    | translate}} &rarr;</button>
                            
                            
                                <button class="learn-more-button"  href="#"
                                    (click)="goToLink('https://ec.europa.eu/digital-building-blocks/sites/display/DIGITAL/Once+Only+Technical+System')" 
                                    tabindex="0">{{'HOME.LEARN_MORE_OOTS'
                                    | translate}} &#8594;</button>
                            
                        </div>
                </div>
            </section>
        </div>
    </ng-template>

    <ng-template #userIdentified>

        <div>
            <div *ngIf="ootsRequest; then requestFound else requestNotFound"></div>

            <div class="overlay" *ngIf="loading">
                <div class="spinner"></div>
            </div>

            <ng-template #requestFound>
                <section *ngIf="!loading && ootsRequest" class="documents-required">
                    <p style="font-size: x-large; font-weight: bold;">{{ 'HOME.NEED_TO_APPLY' | translate }}</p>

                    <div class="expandable-container">
                        <div class="mt-12" style="width: 100%;">
                            <div>
                                <table style="float: left; width: 100%;">
                                    <tr>
                                        <th style="text-align: left; width:30%; font-weight: bold;">
                                            {{'EV_REQUESTED.NAME' |
                                            translate}}</th>
                                        <th [ngStyle]="{'width' : step===2 ? '70%' : '40%'}"
                                            style="text-align: left; font-weight: bold;">
                                            {{'EV_REQUESTED.FAMILY_NAME' |
                                            translate}}</th>
                                        <th *ngIf="step>2" style="text-align: left; width:30%; font-weight: bold;">
                                            {{'EV_REQUESTED.RECIEVED_DOCUMENTATION' | translate}}</th>
                                    </tr>
                                    <tr>
                                        <td>{{ currentUser.firstName }}</td>
                                        <td>{{ currentUser.familyName }}</td>
                                        <td *ngIf="step>2">{{ recievedDocuments }} / {{ totalDocuments }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="mt-12">
                            <div>
                                <table style="float: left; width: 100%;">
                                    <tr>
                                        <th style="text-align: left; width:30%; font-weight: bold;">
                                            {{'EV_REQUESTED.EURO_USER_ID' | translate}}</th>
                                        <th style="text-align: left; width:40%; font-weight: bold;">
                                            {{'EV_REQUESTED.USER_ID' |
                                            translate}}</th>
                                        <th style="text-align: left; width:30%; font-weight: bold;">
                                            <span *ngIf="currentUser.dateOfBirth">{{'EV_REQUESTED.BIRTH_DATE'| translate}}</span></th>
                                    </tr>
                                    <tr>
                                        <td>{{ currentUser.personIdentifier }}</td>
                                        <td>{{ currentUser.personIdentifier.split(regex).pop() }}</td>
                                        <td><span *ngIf="currentUser.dateOfBirth">{{ currentUser.dateOfBirth.toString().split('T')[0] }}</span></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <p> </p>
                        </div>
                        <div class="mt-12">
                            <div>
                                <table style="float: left; width: 100%;">
                                    <tr>
                                        <td style="text-align: left; width:30%;"><b>{{ 'LOCATE.PROCEDURE' |
                                                translate
                                                }}</b>
                                        </td>
                                        <td style="text-align: left; width:70%">{{ procedureDescription }}</td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: left; width:30%"><b>{{ 'LOCATE.DOCUMENTS' | translate
                                                }}</b></td>
                                        <td style="text-align: left; width:70%">{{ requirementsList.join(', ') }}</td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: left; width:30%"><b>{{ 'LOCATE.REQUESTED' | translate
                                                }}</b></td>
                                        <td style="text-align: left; width:70%">Secretaría General de Formación
                                            Profesional</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="toggle-button">
                        <button class="toggle-lnk" (click)="toggle()">▲ Contraer</button>
                    </div>

                </section>
                <section class="locate-component">
                    
                    <div>
                        <app-locate-and-request-evidence></app-locate-and-request-evidence>
                    </div>
                </section>
            </ng-template>

            <ng-template #requestNotFound>
                <section *ngIf="!loading && !ootsRequest" class="documents-required">
                    <div class="subtitle">{{'HOME.NO_ACTIVE_PROCESS' | translate}}</div>
                    <p>{{'HOME.NO_ACTIVE_PROCESS_DESC' | translate}}<br>{{'HOME.CONTINUATION_TOKEN' | translate}}</p>
                    <div class="row mt-4 mb-2">
                        <div class="col-md-5">
                            <input type="text" style="height: 30px;" [(ngModel)]="continuationToken" placeholder="Enter here your token..." 
                            (input)="onInputChange($event)" (focus)="onInputChange($event)"></div>
                        <div class="col-md-7" style="padding-left: 0px;">
                            <button style="height: 30px; margin: 0 !important;"(click)="checkPreviousProcessWithToken()">{{'HOME.CONTINUE' | translate}}</button>
                        </div>
                    </div>
                    <p class="mt-1 ml-1" *ngIf="!tokenFound" style="position: absolute; color:brown">
                        {{'HOME.NO_PREVIOUS_PROCESS' | translate}}</p>
                        
                    <p class="mt-1 ml-1" *ngIf="!isRequestDraftStatus" style="position: absolute; color:brown">
                        {{'HOME.NO_PREVIOUS_PROCESS_IN_DRAFT_STATUS' | translate}}</p>
                </section>
            </ng-template>

        </div>

    </ng-template>

</div>