
    <div class="date-container">
        <div><label for="fechaHechoRegistral">1. {{ 'PREVIEW_SPACE.REGISTRY_DATE_SELECT' | translate }}</label></div>
        <div><input id="fechaHechoRegistral" type="date" data-date-format="YYYY-MM-DD" [(ngModel)]="specificData.fechaHechoRegistral" (change)="emitValueChange(specificData)"></div>
    </div>

    <div class="municipality-container">
        <div><label for="municipality">2. {{ 'PREVIEW_SPACE.PROVINCE_SELECT' | translate }}</label></div>
        <div><app-cpm [comunidad]=true [provincia]=true></app-cpm></div>
    </div>

