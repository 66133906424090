import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.css']
})
export class AvisoLegalComponent {
  constructor(private location: Location) {}
  return(){
    this.location.back();
  }
}
