export class PidRequest {
    session: string;
    specificData: SpecificData;
}

export class SpecificData {
    service: string;
    fechaHechoRegistral: string | undefined;
    codeComunidad: string | undefined;
    codeProvincia: string | undefined;
    codeMunicipio: string | undefined;
    identityType: string | undefined;
    identifier: string | undefined;
    birthDate: string | undefined;
    nombre: string | undefined ;
    apellido1: string | undefined;
    apellido2: string | undefined;
    spanishCitizen: boolean;
    universidades: string[] | undefined;
    anioAcademico: string | undefined;
    fechaDesde: string | undefined;
    fechaHasta: string | undefined;
}