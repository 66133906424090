import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModalResult } from '../_common/modal-dialog/modal-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {

  private dialogRef: MatDialogRef<any>;
  private dialogClosedSubject = new Subject<any>();

  private dialogSubject: BehaviorSubject<ModalResult> = new BehaviorSubject<ModalResult>(new ModalResult());
  public dialogObserver = this.dialogSubject.asObservable();

  constructor(private dialog: MatDialog) { }

  openDialog(component: any, data: any): Observable<any> {
    this.closeDialog();
    data.isLoading = false;
    this.dialogRef = this.dialog.open(component, {
      disableClose: true,
      //panelClass: 'modal-dialog',
      data: data
    });

    return this.dialogRef.afterClosed();
  }

  openErrorDialog(component: any, data: any): Observable<any>{
    this.closeDialog();

    this.dialogRef = this.dialog.open(component, {
      disableClose: false,
      panelClass: 'modal-dialog',
      data: data
    });

    return this.dialogRef.afterClosed();
  }
  
  updateDialog(data: any): void {
    this.dialogRef.componentInstance.data = data;
  }

  loading(isLoading: boolean) {
    this.dialogRef.componentInstance.data.isLoading = isLoading;
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  notifyDialogClosed(result: any): void {
    this.dialogClosedSubject.next(result);
  }

  getObservableDialogClosed(): Observable<any> {
    return this.dialogClosedSubject.asObservable();
  }
  
  set nextStep(action: ModalResult) {
    this.dialogSubject.next(action);
  }
}
