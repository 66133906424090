<div class="container">
    <div *ngIf="oostRequestActive()" class="row">
        <div class="col-md-9">
            <div *ngIf="showBreadcrum()" class="breadcrumb">
                <ul>
                    <li class="breadcrumb"><span
                            [ngClass]="step === 1 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">1.
                            &nbsp; {{ 'HEADER.SESSION_INIT' | translate }} &nbsp;</span></li>
                    <li class="breadcrumb">
                            <span 
                            [ngClass]="step === 2 ? 'breadcrumb breadcrumb_selected ' : 'breadcrumb hidden'">2.
                                {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</span>
                            <span 
                                [ngClass]="step === 1 ? 'breadcrumb ' : 'breadcrumb hidden'">2.
                                    {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</span>    
                            <span 
                                [ngClass]="step === 4 ? 'breadcrumb ' : 'breadcrumb hidden'">2.
                                    {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</span> 

                        
                            <span
                                [ngClass]="step === 3 ? 'breadcrumb breadcrumb breadcrumb_return' : 'breadcrumb hidden'">
                                <u><a (click)="initializeComponent(2)" href="/home" tabindex="0">2.
                                    {{ 'HEADER.SELECT_PROVIDERS' | translate }} &nbsp;</a></u></span>
                        
                        
                            </li>
                        <li class="breadcrumb"><span
                            [ngClass]="step === 3 ? 'breadcrumb breadcrumb_selected' : (step === 4 && !ootsRequest.inSession) ? 'breadcrumb hidden' : 'breadcrumb'">3.
                            {{ 'HEADER.LOCATE_DOCUMENTS' | translate }} &nbsp;</span>
                            <span
                                [ngClass]="(step === 4 && !ootsRequest.inSession) ? 'breadcrumb breadcrumb breadcrumb_return' : 'breadcrumb hidden'">
                                <u><a (click)="initializeComponent(3)" href="/home" tabindex="0">3.
                                    {{ 'HEADER.LOCATE_DOCUMENTS' | translate }} &nbsp;</a></u></span>
                        </li>
                    <li class="breadcrumb"><span
                            [ngClass]="step === 4 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">4.
                            {{ 'HEADER.FINISH_PROCESS' | translate }} &nbsp;</span></li>
                </ul>
            </div>
        </div>
        <div class="col-md-3">
            <div *ngIf="showTimerAndButtons()" class="timer-and-actions">
                <div *ngIf="ootsRequest.inSession === true; then inSession else outOfSession"></div>
                <ng-template #inSession>
                    <div *ngIf="showTimer()" class="timer">
                        <app-session-timer [timerType]="'server'"></app-session-timer>
                    </div>
                </ng-template>

                <ng-template #outOfSession>
                    <button class="action" (click)="save()">{{ 'HEADER.SAVE' | translate }}</button>
                </ng-template>

                <button class="action" (click)="cancel()">{{ 'HEADER.CANCEL' | translate }}</button>
            </div>
        </div>

    </div>
    <div *ngIf="oostPreview()" class="row">
        <div class="col-md-9">
            <div *ngIf="showBreadcrum()" class="breadcrumb">
                <ul>
                    <li class="breadcrumb"><span
                            [ngClass]="step === 1 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">1.
                            &nbsp; {{ 'HEADER.LOCATE_PROOF' | translate }} &nbsp;</span></li>
                    <li class="breadcrumb">
                            <span 
                            [ngClass]="step === 2 ? 'breadcrumb breadcrumb_selected ' : 'breadcrumb hidden'">2.
                                {{ 'HEADER.DISPLAY_PROOF' | translate }} &nbsp;</span>
                            <span 
                                [ngClass]="step === 1 ? 'breadcrumb ' : 'breadcrumb hidden'">2.
                                    {{ 'HEADER.DISPLAY_PROOF' | translate }} &nbsp;</span>    
                            <span 
                                [ngClass]="step === 4 ? 'breadcrumb ' : 'breadcrumb hidden'">2.
                                    {{ 'HEADER.DISPLAY_PROOF' | translate }} &nbsp;</span> 

                        
                            <span
                                [ngClass]="step === 3 ? 'breadcrumb breadcrumb breadcrumb_return' : 'breadcrumb hidden'">
                                <u><a (click)="initializeComponent(2)" href="/preview-space" tabindex="0">2.
                                    {{ 'HEADER.DISPLAY_PROOF' | translate }} &nbsp;</a></u></span>
                        
                        
                            </li>
                        <li class="breadcrumb"><span
                            [ngClass]="step === 3 ? 'breadcrumb breadcrumb_selected' : (step === 4 && !ootsRequest.inSession) ? 'breadcrumb hidden' : 'breadcrumb'">3.
                            {{ 'HEADER.CONFIRM_DELIVERY' | translate }} &nbsp;</span>
                            <span
                                [ngClass]="(step === 4 && !ootsRequest.inSession) ? 'breadcrumb breadcrumb breadcrumb_return' : 'breadcrumb hidden'">
                                <u><a (click)="initializeComponent(3)" href="/preview-space" tabindex="0">3.
                                    {{ 'HEADER.CONFIRM_DELIVERY' | translate }} &nbsp;</a></u></span>
                        </li>
                    <li class="breadcrumb"><span
                            [ngClass]="step === 4 ? 'breadcrumb breadcrumb_selected' : 'breadcrumb'">4.
                            {{ 'HEADER.END_AND_RETURN' | translate }} &nbsp;</span></li>
                </ul>
            </div>
        </div>
        <div class="col-md-3">
            <div *ngIf="showTimer()" class="timer">
                <app-session-timer [timerType]="'client'"></app-session-timer>
            </div>
        </div>

    </div>
</div>