<div class="d-flex justify-content-end">
    <button (click)="return()"><i class="bi bi-x-lg"></i></button>
</div>
<div class="">
    <h1 class="lin-tit">{{'FOOTER.COOKIES_POLICY' | translate}}</h1>
    <div class="">
        <h3 class="lin-sub">{{'COOKIE_POLICY.TIT1' | translate}}</h3>
        <p>{{'COOKIE_POLICY.TEXT1' | translate}}</p>
    </div>
    <div class="">
        <h3 class="lin-sub">{{'COOKIE_POLICY.TIT2' | translate}}</h3>
        <p>{{'COOKIE_POLICY.TEXT2' | translate}}</p>
        <ul>
            <li>
                <p>{{'COOKIE_POLICY.TEXT3' | translate}}</p>
            </li>
            <li>
                <p>{{'COOKIE_POLICY.TEXT4' | translate}}</p>
            </li>
        </ul>
        <p>{{'COOKIE_POLICY.TEXT5' | translate}}</p>
        <table class="cc-beneficiary__table">
            <thead class="cc-beneficiary__thead tab-border">
                <tr class="cc-beneficiary__thead-tr">
                    <th class="cc-beneficiary__th ">{{'COOKIE_POLICY.TAB_TITLE1' | translate}}</th>
                    <th class="cc-beneficiary__th ">{{'COOKIE_POLICY.TAB_TITLE2' | translate}}</th>
                    <th class="cc-beneficiary__th ">{{'COOKIE_POLICY.TAB_TITLE3' | translate}}</th>
                    <th class="cc-beneficiary__th ">{{'COOKIE_POLICY.TAB_TITLE4' | translate}}</th>
                </tr>
            </thead>
            <tbody class="cc-beneficiary__tbody tab-border">
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">token</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_TECHNICAL' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_TOKEN' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_KEPT_IN_SESSION' | translate}}</td>
                </tr>
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">redirect</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_TECHNICAL' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_REDIRECT' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_KEPT_IN_SESSION' | translate}}</td>
                </tr>
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">oots-request-status</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_TECHNICAL' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_STATUS' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_KEPT_IN_SESSION' | translate}}</td>
                </tr>
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">currentUser</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_TECHNICAL' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_USER' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_KEPT_IN_SESSION' | translate}}</td>
                </tr>
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">errorType</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_TECHNICAL' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_ERRORTYPE' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_KEPT_IN_SESSION' | translate}}</td>
                </tr>
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">isPreview</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_TECHNICAL' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_PREVIEW' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_KEPT_IN_SESSION' | translate}}</td>
                </tr>
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">lang</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_CUSTOMIZATION' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_LANGUAGE' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_LANG' | translate}}</td>
                </tr>
                <tr class="cc-beneficiary__tbody-tr">
                    <td class="cc-beneficiary__td">continuationToken</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.TYPE_TECHNICAL' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.PURPOSE_REC_TOKEN' | translate}}</td>
                    <td class="cc-beneficiary__td">{{'COOKIE_POLICY.DURATION_KEPT_IN_SESSION' | translate}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="">
        <h3 class="lin-sub">{{'COOKIE_POLICY.TIT3' | translate}}</h3>
        <p>{{'COOKIE_POLICY.TEXT6' | translate}}</p>
        <p>{{'COOKIE_POLICY.TEXT7' | translate}}</p>
    </div>
    <div class="">
        <h3 class="lin-sub">{{'COOKIE_POLICY.TIT4' | translate}}</h3>
        <p>{{'COOKIE_POLICY.TEXT8' | translate}}</p>
        <table class="">
            <thead class="cc-browser__thead">
                <tr class="cc-browser__thead-tr tab-border">
                    <th scope="col" class="cc-browser__th">{{'COOKIE_POLICY.TAB_TITLE5' | translate}}</th>
                    <th scope="col" class="cc-browser__th">{{'COOKIE_POLICY.TAB_TITLE6' | translate}}</th>
                </tr>
            </thead>
            <tbody class="cc-browser__tbody">
                <tr class="cc-browser__tbody-tr tab-border">
                    <td class="cc-browser__td">{{'COOKIE_POLICY.BROWSER1' | translate}}</td>
                    <td class="cc-browser__td">
                        <a target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=es-419">{{'COOKIE_POLICY.DESCRIPTION1' | translate}} <span role="img" aria-label="Enlace externo, se abre en una nueva pestaña" class="fas fa-external-link-alt ico-informacion"></span></a>
                    </td>
                </tr>
                <tr class="cc-browser__tbody-tr tab-border">
                    <td class="cc-browser__td">{{'COOKIE_POLICY.BROWSER2' | translate}}</td>
                    <td class="cc-browser__td">
                        <a target="_blank" href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">{{'COOKIE_POLICY.DESCRIPTION2' | translate}} <span role="img" aria-label="Enlace externo, se abre en una nueva pestaña" class="fas fa-external-link-alt ico-informacion"></span></a>
                    </td>
                </tr>
                <tr class="cc-browser__tbody-tr tab-border">
                    <td class="cc-browser__td">{{'COOKIE_POLICY.BROWSER3' | translate}}</td>
                    <td class="cc-browser__td">
                        <a target="_blank" href="https://support.apple.com/es-mx/HT201265">{{'COOKIE_POLICY.DESCRIPTION3' | translate}} <span role="img" aria-label="Enlace externo, se abre en una nueva pestaña" class="fas fa-external-link-alt ico-informacion"></span></a>
                    </td>
                </tr>
                <tr class="cc-browser__tbody-tr tab-border">
                    <td class="cc-browser__td">{{'COOKIE_POLICY.BROWSER4' | translate}}</td>
                    <td class="cc-browser__td">
                        <a target="_blank" href="https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">{{'COOKIE_POLICY.DESCRIPTION4' | translate}} <span role="img" aria-label="Enlace externo, se abre en una nueva pestaña" class="fas fa-external-link-alt ico-informacion"></span></a>
                    </td>
                </tr>
                <tr class="cc-browser__tbody-tr tab-border">
                    <td class="cc-browser__td">{{'COOKIE_POLICY.BROWSER5' | translate}}</td>
                    <td class="cc-browser__td">
                        <a target="_blank" href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd#:~:text=C%C3%B3mo%20administrar%20la%20configuraci%C3%B3n%20de,%3E%20Privacidad%2C%20b%C3%BAsqueda%20y%20servicios.">{{'COOKIE_POLICY.DESCRIPTION5' | translate}} <span role="img" aria-label="Enlace externo, se abre en una nueva pestaña" class="fas fa-external-link-alt ico-informacion"></span></a>
                    </td>
                </tr>
                <tr class="cc-browser__tbody-tr tab-border">
                    <td class="cc-browser__td">{{'COOKIE_POLICY.BROWSER6' | translate}}</td>
                    <td class="cc-browser__td">
                        <a target="_blank" href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-#:~:text=Una%20cookie%20es%20un%20archivo,preferencias%20cuando%20visitas%20ese%20sitio.&amp;text=En%20algunos%20navegadores%2C%20cada%20cookie,ubicado%20en%20la%20carpeta%20perfil.">{{'COOKIE_POLICY.DESCRIPTION6' | translate}} <span role="img" aria-label="Enlace externo, se abre en una nueva pestaña" class="fas fa-external-link-alt ico-informacion"></span></a>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>&nbsp;</p>
    </div>
    <div class="">
        <h3 class="lin-sub">{{'COOKIE_POLICY.TIT5' | translate}}</h3>
        <p>{{'COOKIE_POLICY.TEXT10' | translate}}</p>
        <ul>
            <li>
                <p>{{'COOKIE_POLICY.TEXT11' | translate}}</p>
            </li>
            <li>
                <p>{{'COOKIE_POLICY.TEXT12' | translate}}</p>
            </li>
        </ul>
        <p>{{'COOKIE_POLICY.TEXT13' | translate}}</p>
    </div>
</div>
