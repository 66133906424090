import { Utils } from "../_common/utils";
import { EvidenceProvider } from "./evidence-provider";
import { MultiLanguageString } from "./multi-language-string";

export class Evidence {
    success: boolean;
    message: string;
    jurisdictionLevel: string;
    jurisdictionContextId: string;
    dataServiceEvidenceTypeIdentifier: string;
    showJurisdictionSelect: boolean;
    evidenceIdentifier: string;
    evidenceUUID: string;
    requirementId: string;
	evidenceTypeClassification: string;
	evidenceTitle: MultiLanguageString[];
    evidenceNumber: number;
    countryCode: string;
	providers: EvidenceProvider[];
    previewUrl: string;
    isPreview: boolean;
    fileName: string;
    base64File: string;
    mimeType: string;
    status: string;
    previousStatus: string;
    errorMessage:string;
    mandatory: boolean;
    uploadAllowed: boolean;
    providerCountryCode: string;
    isTimeout: boolean;
    accessServiceIdentifier: string;
    publisherIdentifier: string;
    publisherName: string;
    requirementNumber: number;
    jurisdictionAdminL2: string;
    jurisdictionAdminL3: string;

    get selectedProvider() : any {
        if(Utils.isNullOrUndefined(this.providers)) return null;
        return this.providers.find(provider => provider.isSelected) ?? null;
    }
 
  /*   transformToSave(): EvidenceSave{

    } */

    
}
export interface EvidenceSave{
    evidenceUUID: string;
    accessServiceIdentifier: string;
    base64File: string;
    evidenceIdentifier: string;
    evidenceNumber: number;
    evidenceTitle: string;
    fileName: string;
    mimeType: string;
    previewUrl: string;
    publisherName: string;
    requirementId: string;
    status: string;
}