import { EventEmitter } from "@angular/core";

export abstract class SpecificDataComponent {

  service: string;
  type: FormType;
  askForNationality = false;
  
  abstract isValid(): boolean;
  
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  protected emitValueChange(value: any): void {
    this.valueChanged.emit(value);
  }
  
  abstract getValue(): any;
}

export enum FormType {
  ccaa = 'ccaa',
  cpm = 'cpm',
}