<div class="form-gr">
    <label for="universidad">{{ 'PREVIEW_SPACE.UNIVERSITY' | translate }}</label>
    <select2 id="universidad" [data]="universidadesData" class="select2" multiple="true"
        listPosition="above" [(ngModel)]="specificData.universidades">
    </select2>
    <label for="anioAcademico">{{ 'PREVIEW_SPACE.ACADEMIC_YEAR' | translate }}</label>
    <select2 id="anioAcademico" [data]="aniosAcademicosData" class="select2"
        listPosition="above" [(ngModel)]="specificData.anioAcademico">
    </select2>
</div>
