import { Component } from '@angular/core';
import { SpecificDataComponent } from '../specific-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-deportista-alto-nivel-form',
  standalone: true,
  imports: [ TranslateModule, FormsModule ],
  templateUrl: './deportista-alto-nivel-form.component.html',
  styleUrl: './deportista-alto-nivel-form.component.css'
})
export class DeportistaAltoNivelFormComponent  extends SpecificDataComponent {

  constructor() {
    super();
  }


  override isValid(): boolean {
    if (this.specificData.fechaDesde === null || this.specificData.fechaHasta === null) return false;
    return true;
  }

  override getValue() {
    return this.specificData;
  }

}