export class PreviewSpaceResponseError {
    session: string;
    errorType: ResponseErrorType;
    errorMessage: string;
}

export enum ResponseErrorType {
    AUTHENTICATION,
    USER_REJECT_OR_CANCEL,
    SESSION_TIMEOUT, // after 3 min
    INVALID_REQUEST
}