import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalDialogComponent } from 'src/app/_common/modal-dialog/modal-dialog.component';
import { EidasUser } from 'src/app/_model/eidasUser';
import { EidasService } from 'src/app/_service/eidas-service.service';
import { JwtUtilsService } from 'src/app/_service/jwt-utils.service';
import { ModalDialogService } from 'src/app/_service/modal-dialog.service';

@Component({
  selector: 'app-eidas-callback',
  templateUrl: './eidas-callback.component.html',
  styleUrls: ['./eidas-callback.component.css']
})
export class EidasCallbackComponent implements OnInit {

  @Input() isPreviewSpace: boolean;
  @Input() uuid: string;
  @Input() session: string;
  @Output() redirect = new EventEmitter<any>();

  jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]*$/;

  constructor(
    private route: ActivatedRoute,
    private jwtUtilService: JwtUtilsService,
    private router: Router,
    private location: Location,
    private dialogService: ModalDialogService,
    private eidasService: EidasService,
    private cookieService: CookieService,
    public translate: TranslateService) { }

  currentUser: EidasUser;

  continue(isNew: boolean) {
    if (this.isPreviewSpace) {
      if(isNew){
        this.router.navigate(['/new-preview-space']);
      }
      else{
        this.router.navigate(['/preview-space']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    console.log("EidasCallbackComponent.ngOnInit()");
    if (window.location.href.indexOf("error") > -1) {
      const urlParams = new URLSearchParams(window.location.search);
      const errorType = urlParams.get("errorType");
      let data;
      if (errorType == 'eidas') {
        this.cookieService.set("errorType", JSON.stringify("eidas"), { path: '/' });
        data = {
          title: 'EIDAS.LOGIN_ERROR_TITLE',
          message: 'EIDAS.LOGIN_ERROR_TEXT',
          type: 'error-translate'
        };
      }
      if (errorType == 'petition') {
        this.cookieService.set("errorType", JSON.stringify("petition"), { path: '/' });
        data = {
          title: 'HOME.INCORRECT_PETITION_TITLE',
          message: 'HOME.INCORRECT_PETITION_TEXT',
          type: 'error-translate'
        };
      }
      if(errorType == 'low_loa'){
        data = {
          title: 'EIDAS.LOGIN_ASSURANCE_TITLE',
          message: 'EIDAS.LOGIN_ASSURANCE_TEXT',
          type: 'error-translate'
        };
      }
      this.dialogService.openErrorDialog(ModalDialogComponent, data);
    }
    const token = this.route.firstChild?.snapshot.params['id'];
    let isNew = false;
    try {
      if (token !== null && token !== 'undefined') {
        this.currentUser = this.decodeTokenForClaims(token);
        this.eidasService.checkUser(this.currentUser, this.uuid, this.session, this.isPreviewSpace).subscribe({
          next: (data) => {
            console.log(data)
            if (data.status == "ok") {
              this.jwtUtilService.saveTokenInSessionStorage(token);
              this.jwtUtilService.saveCurrentUser(JSON.stringify(this.currentUser));
              this.jwtUtilService.saveRedirect('true');
              console.log(window.location.href.indexOf("new"));
              if(window.location.href.indexOf("new") > -1){
                if (this.isPreviewSpace) {
                  isNew = true;
                }
              }
              this.replaceCurrentState(isNew);
            }
            else{
              if(data.status == "no_match"){
                
                if(this.isPreviewSpace){
                  const dialog = {
                    title: this.translate.instant('EIDAS.LOGIN_CREDENTIAL_MISMATCH_TITLE'),
                    message: this.translate.instant('EIDAS.LOGIN_CREDENTIAL_MISMATCH_TEXT'),
                    type: 'error-preview-region'
                  };
                  this.dialogService.openDialog(ModalDialogComponent, dialog)
                  .subscribe(result => {
                    if (result?.action === 'accept') {
                      this.redirect.emit(true);
                    } else if (result?.action === 'cancel') {
                      this.redirect.emit(false);
                    }
                  });
                }
                else{
                  const dialog = {
                    title: 'EIDAS.LOGIN_CREDENTIAL_MISMATCH_TITLE',
                    message: 'EIDAS.LOGIN_CREDENTIAL_MISMATCH_TEXT',
                    type: 'error-translate'
                  };
                  this.dialogService.openErrorDialog(ModalDialogComponent, dialog);
                }
                } 
            }
          }
        })
      }
    } catch (error) {
      this.continue(isNew);
    }

  }

  private decodeTokenForClaims(token: string): EidasUser {
    const claims = JSON.parse(this.base64TokenDecode(token.split('.')[1]));
    return {
      firstName: claims.firN,
      familyName: claims.famN,
      personIdentifier: claims.sub,
      dateOfBirth: new Date(claims.fNac),
      schemeId: claims.issuer,
      loa: claims.loa
    }
  }

  private base64TokenDecode(str: string) {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    const padding = str.length % 4;
    if (padding) {
      str += '='.repeat(4 - padding);
    }
    const binaryData = window.atob(str);
    const utf8Decoder = new TextDecoder('utf-8');
    const bytes = Uint8Array.from(binaryData, c => c.charCodeAt(0));
    return utf8Decoder.decode(bytes);
  }

  replaceCurrentState(isNew: boolean) {
    if (this.isPreviewSpace) {
      if(isNew){
        this.router.navigate(['/login']).then(() => {
          this.router.navigate(['/new-preview-space']);
        });
      }
      else{
        this.router.navigate(['/login']).then(() => {
          this.router.navigate(['/preview-space']);
        });
      }
    } else {
      this.router.navigate(['/login']).then(() => {
        this.router.navigate(['/']);
      });
    }
  }
}
