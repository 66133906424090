import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EvidenceBrokerComponent } from './evidence-broker/evidence-broker.component'
import { AccessPointComponent } from './access-point/access-point.component';
import { DataServiceDirectoryComponent } from './data-service-directory/data-service-directory.component';
import { EidasComponent } from './eidas/eidas-component/eidas-component.component';
import { EidasCallbackComponent } from './eidas/eidas-callback/eidas-callback.component';
import { AuthGuardService } from './_service/auth-guard.service';
import { SedeComponent } from './sede/sede.component';
import { LocateAndRequestEvidenceComponent } from './locate-and-request-evidence/locate-and-request-evidence.component';
import { PreviewSpaceComponent } from './preview-space/preview-space.component';
import { RegistryComponent } from './registry/registry.component';
import { UserSedeComponent } from './user-sede/user-sede.component';
import { LoginMaintenanceComponent } from './login-maintenance/login-maintenance.component';
import { ManagementComponent } from './management/management.component';
import { PoliticaPrivacidadComponent } from './_common/legal_info/politica-privacidad/politica-privacidad.component';
import { PoliticaCookiesComponent } from './_common/legal_info/politica-cookies/politica-cookies.component';
import { AvisoLegalComponent } from './_common/legal_info/aviso-legal/aviso-legal.component';
import { ProjectathonRequesterComponent } from './projectathon-requester/projectathon-requester.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent, children: [
      { path: 'login/callback/:id', component: EidasCallbackComponent },
      { path: 'login/callback', component: EidasCallbackComponent },
      { path: 'login/callback/error', component: EidasCallbackComponent },]
  },
  { path: 'ap', component: AccessPointComponent, canActivate: [AuthGuardService] },
  { path: 'eb', component: EvidenceBrokerComponent, canActivate: [AuthGuardService] },
  { path: 'dsd', component: DataServiceDirectoryComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: EidasComponent },
  { path: 'sede', component: SedeComponent },
  { path: 'entry', component: UserSedeComponent },
  { path: 'requester', component: ProjectathonRequesterComponent },
  { path: 'locate-and-request-evidence', component: LocateAndRequestEvidenceComponent, canActivate: [AuthGuardService] },
  {
    path: 'preview-space', component: PreviewSpaceComponent,
    children: [
      { path: 'login/callback/:id', component: EidasCallbackComponent },
      { path: 'login/callback/new/:id', component: EidasCallbackComponent },
      { path: 'login/callback/', component: EidasCallbackComponent },
    ]
  },
  { path: 'new-preview-space', component: PreviewSpaceComponent,
    children: [
      { path: 'login/callback/:id', component: EidasCallbackComponent },
      { path: 'login/callback/new/:id', component: EidasCallbackComponent },
      { path: 'login/callback/', component: EidasCallbackComponent },
    ] 
  },
  { path: 'registry', component: RegistryComponent, canActivate: [AuthGuardService] },
  { path: 'politica_privacidad', component: PoliticaPrivacidadComponent},
  { path: 'aviso_legal', component: AvisoLegalComponent},
  { path: 'politica_cookies', component: PoliticaCookiesComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login-maintenance', component: LoginMaintenanceComponent},
  { path: 'management', component: ManagementComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }