import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from './_service/state.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionTimerComponent } from './_common/session-timer/session-timer.component';
import { Subscription } from 'rxjs';
import { SessionTimerService } from './_service/session-timer.service';
import { BreadcrumbService } from './_service/breadcrumb.service';
import { ModalDialogService } from './_service/modal-dialog.service';
import { ModalDialogComponent } from './_common/modal-dialog/modal-dialog.component';
import { JwtUtilsService } from './_service/jwt-utils.service';

export interface Language {
  value: string;
  img: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [HttpClient, SessionTimerComponent]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IntermediaryApp';
  message = '';
  currentUserName= '';
  selectedLanguage: string;
  innerHtml = '';
  languages: Language[] = [
    { value: 'en', img: 'assets/country-flags/gb.webp' },
    { value: 'es', img: 'assets/country-flags/es.webp' }
  ];
  defaultLang = this.languages[1].value;
  step = 1;
  isPublic = false;
  
  private timerSubscription: Subscription;
  
  constructor(
    private router: Router, 
    private stateService: StateService, 
    public translate: TranslateService, 
    private sessionTimerService: SessionTimerService, 
    private breadcrumbService : BreadcrumbService,
    private dialogService: ModalDialogService,
    private http: HttpClient,
    private jwtUtilsService: JwtUtilsService
    ) {
      this.selectedLanguage = window.localStorage.getItem('lang') ?? this.defaultLang ;
      this.setAppLanguage(translate);
      this.breadcrumbService.getStep.subscribe(step => this.step = step);

      // listener for session timeout
      this.timerSubscription = this.sessionTimerService.isTimerExpired$.subscribe((sessionData) => {
        if(sessionData.expired) {
          if(sessionData.timerType === "server") document.getElementById("open-server-session-timeout-dialog")?.click();
          if(sessionData.timerType === "client") document.getElementById("open-client-session-timeout-dialog")?.click();
        }
      });
   }
   
   @HostListener('window:beforeunload')
    onBeforeUnload() {
      if(this.stateService.respondSede){
        this.stateService.respondSede;
        return false;
      }
      return true;
    }
    
   ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

   setAppLanguage(translate: TranslateService) {
    // Register translation languages
    translate.addLangs([this.languages[0].value, this.languages[1].value]);
    const lang = window.localStorage.getItem('lang') ?? this.defaultLang ;
    // Set default language
    translate.setDefaultLang(lang);
    this.translateLanguageTo(lang);
  }

  //Switch language
  translateLanguageTo(lang: any) {
    document.documentElement.setAttribute('lang', lang);
    window.localStorage.setItem('lang', lang);
    this.translate.use(lang).subscribe((data) => {
      this.stateService.setLanguage(lang);
    });
  }

  ngOnInit(): void {
    this.stateService.currentUserName.subscribe((userName: string) => this.currentUserName = userName);
    this.isPublic = this.isPublicLocation();
  }

  goToLink(url: string){
    this.http.get(url,{ responseType: 'text' }).subscribe(data => {
            this.innerHtml = data;
        }
    );
  }

  openComponent(route: string): void{
    this.router.navigate([route]);
  }

  /*
  showTimer() { return this.isTestingLocation(); }
  showBreadcrum() { return this.isTestingLocation(); }
  */

  isPublicLocation() {
    if(window.location.href.includes('/home') || window.location.href.includes('/preview-space')) return true;
    return false;
  }

  openServerSessionTimeoutDialog() {
    const data = {
      title: this.translate.instant('LOCATE.SESSION_TIMEOUT_MODAL'),
      message: this.translate.instant('LOCATE.SESSION_TIMEOUT_MODAL_DESC'),
      type: 'server-session-timeout'
    };
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.doRedirect(this.sessionTimerService.sessionData.returnUrl);
        }
      });
  }

  doRedirect(returnUrl: string) {
    window.location.href = returnUrl;
  }

  openClientSessionTimeoutDialog() {
    const data = {
      title: this.translate.instant('PREVIEW_SPACE.SESSION_TIMEOUT_MODAL'),
      message: this.translate.instant('PREVIEW_SPACE.SESSION_TIMEOUT_MODAL_DESC'),
      type: 'client-session-timeout'
    };
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.doRedirect(this.sessionTimerService.sessionData.returnUrl);
        }
      });
  }

}
